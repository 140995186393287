import { Subject } from "../model/Tutor";

export function getMateriasFromSubjects(subjects: Subject[]) {
  return subjects.map(subject => {
    const i = subject.name.search(" para");
    const i2 = (i === -1 ? subject.name.length : i);
    return subject.name.substr(0, i2).trim();
  })
    .filter((item, i, ar) => ar.indexOf(item) === i) // uniques
    .filter(v => v !== "");
}

/**
 * 
 * @param subjects 
 * @param materia If null, then no filer applied, otherwise we only return niveles that match the given materia
 * @returns 
 */
export function getNivelesFromSubjects(subjects: Subject[], materia?: string | null) {
  return subjects.map(subject => {
    const sep = " para";
    const i = subject.name.search(sep);
    const i2 = (i === -1 ? subject.name.length : i);
    const materiaName = subject.name.substr(0, i2).trim();
    const nivelName = subject.name.substr(i2 + sep.length).trim();
    return (materia == null || materia === materiaName) ? nivelName : "";
  })
    .filter((item, i, ar) => ar.indexOf(item) === i) // uniques
    .filter(v => v !== ""); // remove empty
}