import React from 'react'
import AgostoLogin from '../components/AgostoLogin'

const PageLogin = () => {
  return (
    <div className="col-4 offset-4 text-center">
      <div style={{
        width: 400
      }}>
        <AgostoLogin
          defaultToLogin={true}
          onSuccess={() => { }}></AgostoLogin>
      </div>

    </div>
  )
}

export default PageLogin
