import React from 'react'

const simpleSpinner = () => {
  return (
    <div className="text-center">
      <div className="spinner-grow" role="status">
        {/* El sgte texto solo sale cuando la animación no sucede */}
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

const AgostoLoader = () => {
  return (
    <div className="text-center">
      <div className="preloader-icon-no-abs">
        <span className="visually-hidden">Loading...</span>
        <span></span>
      </div>
    </div>
  )
}

export default AgostoLoader
