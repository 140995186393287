import React, { useEffect, useState } from 'react'
import { ERROR_CONST, fetchReservations } from '../api/EliteFetch';
import AgostoHeader from '../components/AgostoHeader'
import AgostoLoader from '../components/AgostoLoader';
import Footer from '../components/Footer';
import { ReservationsResponse } from '../model/Reservations'
import { CONNECTION_ERROR_MESSAGE } from '../util/Messages';

interface State {
  response: ReservationsResponse | null | "ERROR";
}

const PageMyReservations = () => {

  const initialState: State = {
    response: null,
  }
  const [state, setState] = useState(initialState);

  async function init() {
    document.title = "Historial de clases | Elite Classroom"; // para que cambie el nombre de la pestaña que se muestra en el navegador
    fetchReservations().then(dat => {
      setState(ps => ({
        ...ps,
        response: dat,
      }));
    });
  }
  useEffect(() => {
    init();
  }, []);

  let content = null;
  if (state.response === ERROR_CONST) {
    content = <div>{CONNECTION_ERROR_MESSAGE}</div>
  } else if (state.response == null) {
    content = (<div>
      <AgostoLoader></AgostoLoader>
    </div>);
  } else {
    const datares = Object.entries(state.response.bookings);
    content =
      <div>
        {datares.length === null ?
          <div>No tiene clases reservadas</div>
          :
          datares.map(
            ([moth, bookI]) => {
              const books = bookI;
              const accordionId = "flush-heading" + moth;
              const accordionCollapseId = "flush-collapse" + moth;
              return (
                <div className="accordion-item">
                  <h2 className="accordion-header" id={accordionId}>
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#" + accordionCollapseId} aria-expanded="false" aria-controls={accordionCollapseId}>
                      {moth}
                    </button>
                  </h2>
                  <div id={accordionCollapseId} className="accordion-collapse collapse" aria-labelledby={accordionId} data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body">
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Tutor</th>
                            <th scope="col">Horario</th>
                            <th scope="col">Duración</th>
                          </tr>
                        </thead>
                        <tbody>
                          {books.map((res) => {
                            return (
                              <tr>
                                <th scope="row">{res.date}</th>
                                <td>{res.tutor_name}</td>
                                <td>{res.schedules}</td>
                                <td>{res.horario} minutos</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )
            }
          )

        }
      </div>

  }
  return (
    <div>
      <AgostoHeader title="Mis Reservas"></AgostoHeader>
      <section className="courses section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <div className="section-icon wow zoomIn" data-wow-delay=".4s">
                  <i className="lni lni-pencil-alt" />
                </div>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">Historial de clases llevadas</h2>
              </div>
            </div>
          </div>
          <div className="single-head">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  {content}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  )

}

export default PageMyReservations