import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { misEstudiantesPathRoute } from '../App';
import AgostoHeader from '../components/AgostoHeader';
import AgostoSimpleRegistration from '../components/AgostoSimpleRegistration';
import Footer from '../components/Footer';
import RowSpacer from '../components/util/RowSpacer';
import { useProfile } from '../data/ProfileProvider';
import { getPerfilProfesorUrl } from '../util/LinkConstants'

const PageTutorSignup = () => {
  
  const ph = useProfile();
  const profile = ph.value;
  const history = useHistory();
  
  useEffect(() => {
    document.title = "Enseñar | Elite Classroom";
    if (profile.user_id !== 0) { // user is logged in
      history.push(misEstudiantesPathRoute + "#proximasclases");
    }
  }, []);

  return (
    <div>
      <AgostoHeader title="Registrarse"></AgostoHeader>
      <div className="container">
        <RowSpacer height={20}></RowSpacer>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-5'>
            <AgostoSimpleRegistration
              onSuccess={() => {
                window.location.href = getPerfilProfesorUrl();
              }} ></AgostoSimpleRegistration>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default PageTutorSignup
