import { useEffect, useState } from 'react'
import { trackClick } from '../util/AnalyticsUtil';
import { eliteRegistration, eliteUpdateUserInfo, ERROR_CONST } from '../api/EliteFetch';
import { EliteError } from '../model/EliteError';
import { FullUserDataResponse } from '../model/FullUserProfile';
import { RegistrationPayload } from '../model/RegistrationPayload';
import TextField from '@mui/material/TextField';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, DialogTitle, Button, Divider, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { themeOptions } from '../style/MuiThemes';
import AgostoAcceptConditions from './AgostoAcceptConditions';
import { useProfile } from '../data/ProfileProvider';
import { isEmpty } from '../util/StringUtil';
import { REFERRAL_CODE_LOCAL_STORAGE_KEY } from '../App';

interface State {
  isLoading: boolean;
  registrationResponse?: EliteError | FullUserDataResponse | "ERROR";
  password: string;
  showPassword: boolean;
}

let signupTitle = getSignupTitle();

const AgostoRegistrationOrInfoUpdate = (props: {
  onSuccess: (arg0: FullUserDataResponse) => void,
  onLoginClick: (() => void) | null, // null en caso lo estemos usando para actualizar datos

  children?: JSX.Element,
}) => {

  const inputsClassname = "mb-2-5";
  const ph = useProfile();
  const profile = ph.value;

  const initialState: State = {
    showPassword: false,
    password: '',
    isLoading: false,
  }
  const [state, setState] = useState(initialState);


  useEffect(() => {
    // randomly choosing each time the registration form shows up
    signupTitle = getSignupTitle();
  }, []);

  async function tryToRegister(payload: RegistrationPayload) {
    setState(ps => ({
      ...ps,
      isLoading: true,
    }));
    const r = (props.onLoginClick !== null ?
      (await eliteRegistration(payload)) :
      (await eliteUpdateUserInfo(payload))
    );

    setState(ps => ({
      ...ps,
      isLoading: false,
      registrationResponse: r,
    }));

    if (r !== ERROR_CONST
      && ('data' in r)) { // success (the 'data' check is only for types not to complain)
      props.onSuccess(r);
    }
  }


  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setState(ps => ({
      ...ps,
      showPassword: !state.showPassword,
    }));
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onLoginClick = props.onLoginClick;

  const referralCodeFromLocalStorage = localStorage.getItem(REFERRAL_CODE_LOCAL_STORAGE_KEY) ?? '';

  const formContent = (
    <>
      <TextField
        // autoFocus
        fullWidth
        variant="outlined"
        className={inputsClassname}
        label='Nombre y apellido del apoderado'
        type='text'
        name="parentNames"
        required
        size="small"

        defaultValue={profile?.data?.names}
      />

      <TextField
        fullWidth
        variant="outlined"
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        className={inputsClassname}
        label='Número de teléfono del apoderado'
        type='text'
        name="phoneNumber"
        required
        size="small"

        defaultValue={profile?.data?.phone}
      />

      <TextField
        fullWidth
        variant="outlined"
        className={inputsClassname}
        label='Correo electrónico del apoderado'
        type='email'
        name="parentEmail"
        required
        size="small"

        defaultValue={profile?.data?.user_email}
      />

      <TextField
        fullWidth
        variant="outlined"
        className={inputsClassname} label='Nombre del alumno(a)' type='text'
        name="studentNames"
        required
        size="small"

        defaultValue={profile?.data?.child?.name_son}
      />

      <TextField
        className={inputsClassname}
        fullWidth
        label="Fecha de nacimiento del alumno(a)"
        type="date"
        // defaultValue="2017-05-24"
        // sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true,
        }}
        name="studentBirthdate"
        required
        size="small"

        defaultValue={profile?.data?.child?.date_birth}
      />


      <TextField
        fullWidth
        variant="outlined" className={inputsClassname} label='Correo electrónico del alumno(a)' type='email'
        name="studentEmail"
        size="small"
        // helperText="Opcional"

        defaultValue={profile?.data?.child?.email_son}
      />

      {onLoginClick !== null &&
        (<FormControl
          fullWidth
          size="small"
          className={inputsClassname}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            name="password"
            required
            type={state.showPassword ? 'text' : 'password'}
            value={state.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Contraseña"
          />
        </FormControl>)
      }


      <TextField
        fullWidth
        variant="outlined"
        className={inputsClassname} label='Código de referido'
        name="referralCode"
        size="small"
        defaultValue={isEmpty(profile?.data?.cod_referido) ?
          referralCodeFromLocalStorage : (profile?.data?.cod_referido ?? '')}

        disabled={!isEmpty(profile.data?.cod_referido) 
          || !isEmpty(referralCodeFromLocalStorage)}
      />
    </>
  );

  let registrationBottomPart = <></>;
  if (onLoginClick !== null) {
    registrationBottomPart = (
      <>
        <br />

        <Divider>Ó continuar con:</Divider>

        <div className="row text-center d-flex align-items-center justify-content-center">
          <div className="col">
            <br />
            <Button
              fullWidth
              variant="contained"
              disabled={state.isLoading}
              onClick={() => {
                trackClick('btn_login_fb_registration');
                window.open(window.location.origin + "/wp-login.php?loginSocial=facebook&redirect=https://eliteclassroom.com/", '_blank')?.focus();
              }}
            >
              <i className="fab fa-facebook-f py-1" />
            </Button>
          </div>
        </div>

        {/* Login buttons */}
        <div className="text-center mt-3 mb-3">
          <p style={{
            fontSize: '1rem',
            fontWeight: 'bold',
          }}>¿Ya tienes cuenta? <a href='.' onClick={(e) => {
            e.preventDefault();
            onLoginClick();
            return false;
          }}>Iniciar sesión</a></p>
        </div>

        <div className="text-center mt-3 mb-3" style={{
          fontSize: 12,
        }}>
          <AgostoAcceptConditions analyticsName={"signup"}></AgostoAcceptConditions>
        </div>
      </>
    );
  }

  const myForm = (
    <form onSubmit={(event) => {
      event.preventDefault();
      const m_password = (event.target as any).password?.value; // doesnt exist in case we are updating data
      // const rememberme = (event.target as any).rememberme.checked;
      const payload = {
        "pass_pa": m_password,
        // "remember": rememberme,
        "name_pa": (event.target as any).parentNames.value,
        "email_pa": (event.target as any).parentEmail.value,
        "phone_pa": (event.target as any).phoneNumber.value,
        "name_son": (event.target as any).studentNames.value,
        "date_birth": (event.target as any).studentBirthdate.value,
        "email_son": (event.target as any).studentEmail.value,
        "invite_code": (event.target as any).referralCode.value,
      };
      // alert(JSON.stringify(payload));
      tryToRegister(payload);
    }}>

      {/* Form content: */}
      <ThemeProvider theme={createTheme({
        ...themeOptions,
        typography: {
          // In Chinese and Japanese the characters are usually larger,
          // so a smaller fontsize may be appropriate.
          fontSize: 11.5,
        },
      })}>
        {formContent}
      </ThemeProvider>


      {/* Submit button: */}
      {/* Al parecer btn-block sirve para que el botón tome todo el ancho disponible (ancho del padre) */}
      <Button
        fullWidth
        className="mb-3"
        type='submit'
        variant="contained"
        disabled={state.isLoading}>{state.isLoading ? "Preparándonos para iniciar... ✌️" :
          (onLoginClick === null ? "Enviar" : "Registrarse")}</Button>

      <br />

      {registrationBottomPart}

    </form>
  );
  return (
    <div>
      {
        state.registrationResponse != null
        && state.registrationResponse !== ERROR_CONST
        && ('error' in state.registrationResponse)
        && (
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <span>
              {(state.registrationResponse.error === "existing_user_email" ? "Este correo electrónico ya se usó" :
                state.registrationResponse.error)}
            </span>
            {/* Se removió porque generar luego de que el usuario lo cierra y una nueva renderización */}
            {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
          </div>)
      }
      <DialogTitle className="text-center mb-1 pt-0 fs-4">
        {onLoginClick === null ? "Completa tus datos ✌️" : signupTitle}
      </DialogTitle>
      <div className="row">
        {myForm}
      </div>
    </div>
  )
}

export default AgostoRegistrationOrInfoUpdate

function getSignupTitle() {
  const texts = [
    "Inicia tu experiencia Elite",
    "¡Regístrate y empieza a aprender!",
  ]
  return texts[Math.floor(Math.random() * texts.length)];
}

