import React, { useEffect, useState } from 'react'
import AgostoHeader from '../components/AgostoHeader'
import { fetchWithWordpressAjax } from '../util/FetchUtil';
import { Tutor } from '../model/Tutor';
import { trackClick } from '../util/AnalyticsUtil';
import { getInputDataInSelector, removeClasses } from '../util/DomUtils';
import AgostoFilterTutors, { PRICE_RANGE_MAXIMUM_PEN } from '../components/AgostoFilterTutors';
import AgostoChooseSchedules from '../components/AgostoChooseSchedules';
import { scrollToSelector } from '../util/ScrollUtil';
import Footer from '../components/Footer';
import { IntervalBooked } from '../model/IntervalBooked';
import { TutorAvailability } from '../model/TutorAvailability';
import { ERROR_CONST, fetchFullUserData, fetchSaveBooking, fetchTutorAvailability, fetchTutorIntervalsBooked, fetchTutorPublicProfile, SuccessfulSaveBookingResponse } from '../api/EliteFetch';
import { useDummyData } from '../config/WpBackend';
import { loadTutorResponseExample, loadTutorResponseExample_empty } from '../util/DummyData';
import { sleep } from '../utils';
import AgostoPayment, { flujo_amount_to_pay, getSchedulesIntervalDurationSum, mostrarerrorcupon } from '../components/AgostoPayment';
import { convert_date_standard_to_Peruvian, convert_minutes_to_time, plus_days } from '../util/ConversionUtil';
import { FullUserDataResponse } from '../model/FullUserProfile';
import { ScheduleSelection } from '../model/ScheduleSelection';
import { fbqRecordEvent } from '../util/FbPixel';
import { CONNECTION_ERROR_MESSAGE } from '../util/Messages';
import AgostoBanner from '../components/AgostoBanner';
import { useProfile } from '../data/ProfileProvider';
import ModalLogin from './ModalLogin';
import { isEmpty } from '../util/StringUtil';
import ModalUpdateUserInfo from './ModalUpdateUserInfo';
import { Link as a, useHistory, useParams } from 'react-router-dom';
import { TutorPublicProfileResponse } from '../api/TutorPublicProfileResponse';
import TutorInfoProfile from '../components/tutor/TutorInfoProfile';
import { hideBootstrapModal } from '../util/Bootstrap';
import { fa } from '../util/FirebaseInit';
import { openWhatsapp } from '../util/OpenWhatsapp';
import { confirmacionDeReservaPathRoute, misEstudiantesPathRoute } from '../App';
import { getStartDateForScheduleInterval } from '../util/schedule/ScheduleSelection';
import ModalPastExpirationDate from './ModalPastExpirationDate';

// This is for loading the Comments(reviews), Tutor's views, ... when
// the user clicks to see the full tutor profile, instead of loading
// them immediately when the filter results are returned.
const waitForModalOpeningToLoadTutorProfile = true;

type TutorsResponse = Tutor[];

async function updateFromFilters(state: State) {
  try {
    const paramsToSend = {
      action: "load_tutors_json",
      subject: "",
      materia: state.materiaValue,
      nivel: state.nivelValue,
      disponibilidad: state.disponibilidadValue,
      minPrice: state.minPrice,
      maxPrice: state.maxPrice,
    };
    if (useDummyData) {
      console.log(paramsToSend);
      await sleep(1500);
      if (state.materiaValue === "chemistry") {
        return loadTutorResponseExample_empty;
      }
      // await sleep(30 * 1500);
      return loadTutorResponseExample;
    }
    return await fetchWithWordpressAjax(paramsToSend) as Tutor[];
    // return {
    //   status: "OK",
    // }
  } catch (e) {
    return "ERROR";
  }
}

interface State {
  stepNumber: number,

  // filters
  materiaValue: string,
  nivelValue: string,
  disponibilidadValue: string,
  minPrice: number, // price per academic hour in PEN
  maxPrice: number, // price per academic hour in PEN, also there is a maximum for the slider

  loadTutorsResponse?: null | TutorsResponse | "ERROR", // null means loading

  tutorThatTriedToChoose?: Tutor,

  chosenTutor?: Tutor | null,

  tutorBookingsResponse?: null | IntervalBooked[] | "ERROR", // null means loading
  tutorAvailabilityResponse?: null | TutorAvailability | "ERROR", // null means loading

  scheduleSelection?: ScheduleSelection[];
  scheduleComponentLastLoadDate?: Date;
  weekStartForAvailability?: Date;

  fullUserDataResponse?: FullUserDataResponse | null | "ERROR"; // to get an updated balance

  // coupon:
  moneyToDiscountForAppliedCoupon: number;
  appliedCoupon?: null | string;

  // save booking
  isSaveBookingLoading: boolean,

  continueBooking: boolean,
}

export type BookingProcessState = State;

const PageBooking = (props: {
}) => {

  // Note: DO NOT use 'any' in all situations, just when you are sure about something 
  // actually exists
  const routeParams = (useParams() as any);
  const urlTutorId = routeParams?.tutorId as (string | null);

  const ph = useProfile();
  const profile = ph.value;

  const history = useHistory();

  const initialState: State = {
    stepNumber: 0,

    materiaValue: "all",
    nivelValue: "all",
    disponibilidadValue: "all_schedules",
    minPrice: 0,
    maxPrice: PRICE_RANGE_MAXIMUM_PEN,

    moneyToDiscountForAppliedCoupon: 0,
    isSaveBookingLoading: false,

    continueBooking: false,
  }

  const [state, setState] = useState(initialState);
  const [loginModalState, setLoginModalState] = useState({
    isLoginModalOpen: false,
    defaultToLogin: true,
  });
  const [isUpdateUserInfoModalOpen, setisUpdateUserInfoModalOpen] = useState(false);
  const [tutorModalOpen, settutorModalOpen] = useState(null as (null | number));
  const [expirationModalOpen, setexpirationModalOpen] = useState(null as (null | Tutor));

  async function init() {
    let tutorInfo: TutorPublicProfileResponse | null | "ERROR" = null;
    console.log("urlTutorId ", urlTutorId);
    changeStepInStepsBarAndPanel(0); // this is needed to set up stuff
    if (urlTutorId == null) {
      ;
    } else {
      tutorInfo = await fetchTutorPublicProfile(parseInt(urlTutorId));
    }
    // para el caso en que haya tutorId en la URL 
    // lo sgte tmb es 
    // necesario porque puede que el usuario quiera retroceder y
    // ver los profesores 
    // pero tal vez si sea bueno no esperar a que
    // esto termine antes de iniciar handleElegirTutorNoAnalytics
    const response = await updateFromFilters(state);
    setState(ps => ({
      ...ps,
      loadTutorsResponse: response,
    }));

    if (tutorInfo != null && tutorInfo !== ERROR_CONST) {
      handleElegirTutorNoAnalytics(tutorInfo.data);
    }
  }

  // load when this page loads:
  useEffect(() => {
    // console.log("restared page booking");
    document.title = "Encuentra un profesor | Elite Classroom";
    init();
  }, []);

  // multistep form functions:
  const getActiveStep = (elem: Element) => {
    const stepsBtns = document.querySelectorAll(`.multisteps-form__progress-btn`);
    return Array.from(stepsBtns).indexOf(elem);
  };

  function changeStepInStepsBarAndPanel(newActiveStep: number) {
    // const setFormHeight = () => {
    //   const activePanel = getActivePanel();

    //   formHeight(activePanel);
    // };

    const setActiveStep = (activeStepNum: number) => {
      const stepsBtns = document.querySelectorAll(`.multisteps-form__progress-btn`);

      //remove active state from all the state
      removeClasses(stepsBtns, 'js-active'); // marks progress
      removeClasses(stepsBtns, 'js-active-current'); // marks current step

      //set picked items to active
      stepsBtns.forEach((elem, index) => {

        if (index <= activeStepNum) {
          elem.classList.add('js-active');
          if (index === activeStepNum) {
            elem.classList.add('js-active-current');
          }
        }

      });
    };

    //open active panel (and close unactive panels)
    const setActivePanel = (activePanelNum: number) => {
      const stepFormPanels = document.querySelectorAll('.multisteps-form__panel');

      //remove active class from all the panels
      removeClasses(stepFormPanels, 'js-active-current');

      //show active panel
      stepFormPanels.forEach((elem, index) => {
        if (index === activePanelNum) {

          elem.classList.add('js-active-current');

          // setFormHeight(elem);
        }
      });

    };

    //set all steps before clicked (and clicked too) to active
    setActiveStep(newActiveStep);

    //open active panel
    setActivePanel(newActiveStep);
    // resize_container_height();

    if (newActiveStep !== 0) { // not scrolling to top when going to the first step
      // this way the header can be shown on page load
      scrollAfterChangingStep();
    }
  }

  /**
   * NOTA: notar que al final de la función se realiza un setState de baseState
   * @param newActiveStep 
   * @param _newPartialState 
   * @returns 
   */
  function validateAndChangeStep(newActiveStep: number, _newPartialState?: Partial<State>,
    fullUserDataResponse?: FullUserDataResponse,
    ) {
    const newPartialState = (_newPartialState ?? {});
    newPartialState.stepNumber = newActiveStep;

    const wouldBeState = {
      ...state,
      ...newPartialState,
    };

    // TODO: probably some validations before next line
    if (newActiveStep === 1) { // going to schedules step
      if (wouldBeState.chosenTutor == null) {
        alert("Debes elegir un(a) profesor(a) 👩‍🏫");
        return;
      }

      //// logic:
      if (state.chosenTutor?.id !== wouldBeState.chosenTutor?.id) {
        // limpiando la variable que guarda las reservas realizadas al profesor escogido
        newPartialState.tutorBookingsResponse = null;
        // // limpiando la variable que guarda la disponibilidad del profesor
        newPartialState.tutorAvailabilityResponse = null;
        // // limpiando lo anteriormente seleccionado ya que no necesariamente obedece a la disponibilidad de este nuevo profesor
        newPartialState.scheduleSelection = [];
        newPartialState.scheduleComponentLastLoadDate = new Date((new Date()).setHours(0, 0, 0, 0));
        newPartialState.weekStartForAvailability = newPartialState.scheduleComponentLastLoadDate;

        // loading tutor availability for schedule
        fetchAvData(wouldBeState.chosenTutor.id, newPartialState);
      }
    } else if (newActiveStep === 2) {
      if (wouldBeState.scheduleSelection == null || wouldBeState.scheduleSelection?.length === 0) {
        alert("Debes escoger al menos un rango de horas");
        return;
      }

      const profileToUse = (fullUserDataResponse ?? profile);

      if (profileToUse.user_id === 0) { // the user is not logged in
        trackClick("btn_age_no_login");
        setState(ps => ({
          ...ps,
          continueBooking: true,
        }));
        setIsLoginModalOpen(true, false);
        return;
      } else { // the user is logged in
        // revisar si el usuario ha completado la información obligatoria
        const ha_completado_su_informacion = (
          !isEmpty(profileToUse?.data?.names)
          && !isEmpty(profileToUse?.data?.phone)
          && !isEmpty(profileToUse?.data?.user_email)
          && !isEmpty(profileToUse?.data?.child?.name_son)
          && !isEmpty(profileToUse?.data?.child?.date_birth)
        );
        if (ha_completado_su_informacion) {
          ; // let it pass
        } else {
          // mostrar el popup donde complete su información
          setState(ps => ({
            ...ps,
            continueBooking: true,
          }));
          setisUpdateUserInfoModalOpen(true);
          return; // complete user info first
        }
      }

      /// logic
      newPartialState.fullUserDataResponse = null;
      console.log("changed to last step");
      fetchFullUserData().then((v) => {
        setState(ps => ({
          ...ps,
          ...newPartialState,
          fullUserDataResponse: v,
        }))
      });
    }

    changeStepInStepsBarAndPanel(newActiveStep);
    // to be able to retrieve the step number to use it in the steps bar click listener
    setState(ps => ({
      ...ps,
      ...newPartialState,
    }))
  }

  function btnNextHandler(step: number) {
    if (step === 1) { // click on Next in the schedules step
      trackClick("btn_next4");
    }
    validateAndChangeStep(step + 1);
  }

  function btnPrevHandler(step: number) {
    validateAndChangeStep(step - 1);
  }

  function stepBarClickHandler(e: React.MouseEvent) {
    //check if click target is a step button
    const eventTarget = e.target as Element;

    //get active button step number
    const activeStep = getActiveStep(eventTarget);

    const stepsBtnClass = 'multisteps-form__progress-btn';


    if (!eventTarget.classList.contains(`${stepsBtnClass}`)) {
      return;
    }

    if (activeStep == 2) { // click to go the payment step
      trackClick("btn_next_step_4");
    }
    validateAndChangeStep(activeStep);
  }
  // end of multistep form functions

  function onFilterChange(newSt: State) {
    setState({
      ...newSt,
      loadTutorsResponse: null, // loading
    });

    updateFromFilters(newSt).then((response) => {
      setState(ps => ({
        ...ps,
        loadTutorsResponse: response,
      }));
    })
  }

  const handlePriceRangeChange = (newValue: number[]) => {
    setState(ps => ({
      ...ps,
      minPrice: newValue[0],
      maxPrice: newValue[1],
    }));
  };

  const handlePriceRangeChangeCommitted = (newValue: number[]) => {
    onFilterChange({
      ...state,
      minPrice: newValue[0],
      maxPrice: newValue[1],
    });
  };

  const handleSelectChangeMateria = (e: any) => {
    const v = e.target.value;
    onFilterChange({
      ...state,
      materiaValue: v,
    });
  };
  const handleSelectChangeNivel = (e: any) => {
    const v = e.target.value;
    onFilterChange({
      ...state,
      nivelValue: v,
    });
  };

  const handleSelectChangeDisp = (e: any) => {
    const v = e.target.value;
    onFilterChange({
      ...state,
      disponibilidadValue: v,
    });
  };

  const handleElegirTutorWithoutLoginValidations = (tutor: Tutor) => {
    console.log("chosenTutor: ", tutor.name);
    const newState = {
      chosenTutor: tutor,
    };
    validateAndChangeStep(1, newState);
  }

  const setIsLoginModalOpen = (open: boolean, defaultToLogin: boolean = true) => {
    setLoginModalState({
      isLoginModalOpen: open,
      defaultToLogin: defaultToLogin,
    })
  }

  const handleElegirTutorNoAnalytics = (tutor: Tutor) => {

    // if (props.userProfile === ERROR_CONST) {
    //   alert(CONNECTION_ERROR_MESSAGE + " o actualiza la página");
    //   return;
    // }

    // if (props.userProfile == null) {
    //   alert("Oops, espera un segundo o actualiza la página por favor");
    //   return;
    // }

    if (profile.user_id === 0) { // the user is not logged in, makes no difference in this step
      handleElegirTutorWithoutLoginValidations(tutor);
    } else {
      handleElegirTutorWithoutLoginValidations(tutor);
    }
  }

  const handleClickElegirTutor = (tutor: Tutor) => {
    trackClick("btn_tutor_" + tutor.id);
    handleElegirTutorNoAnalytics(tutor);
  }

  // we pass the state because we don't want to use the global state,
  // which may be not updated
  async function fetchAvData(tutorId: number, newPartialState: Partial<State>) {
    const r = await Promise.all([fetchTutorAvailability(tutorId), fetchTutorIntervalsBooked(tutorId)]);
    setState(ps => ({
      ...ps,
      ...newPartialState,
      tutorAvailabilityResponse: r[0],
      tutorBookingsResponse: r[1],
    }));
  }

  function save_booking() {
    const scheduleSelection = state.scheduleSelection as ScheduleSelection[];
    const fullUserDataResponse = (state.fullUserDataResponse as FullUserDataResponse);
    const N = fullUserDataResponse.data?.globalBalance ?? 0;
    const cb = fullUserDataResponse.data?.centsBalance ?? 0;
    const S = getSchedulesIntervalDurationSum(scheduleSelection);

    const montos = flujo_amount_to_pay(N, cb, S, state.chosenTutor, scheduleSelection);
    const cupon = state.moneyToDiscountForAppliedCoupon; // en PEN

    // la sgte variable sirve para enviar al backend el monto a cobrar (por cuestiones de validación)
    // y para decidir si hay un form de tarjeta que validar o no
    const toChargeInCents = montos.total - cupon * 100; // el nuevo total a cobrarse

    if (toChargeInCents > 0) {
      trackClick("btn_payment");
    } else {
      trackClick("btn_coverage_nf2");
    }
    // console.log("withPayment: " + withPayment);

    // solo validamos el form en caso haya pago que realizar
    // toChargeInCents == 0 || jQuery('.multisteps-form__form').parsley().validate()
    let isValid = true;
    const cardInfo = getInputDataInSelector("#elite_card_form"); // empty object {} in case the element doesnt exist
    if (toChargeInCents > 0 && fullUserDataResponse.data?.cardLast4Digits == null) {
      if ((cardInfo.cardno ?? "").length == 0) {
        alert("Debes llenar el número de tu tarjeta");
        return;
      }
      if ((cardInfo.cvcpwd ?? "").length == 0) {
        alert("Debes colocar el código de 3 dígitos de tu tarjeta");
        return;
      }
      if ((cardInfo.holdername ?? "").length == 0) {
        alert("Debes colocar el nombre del dueño de la tarjeta");
        return;
      }
      if ((cardInfo.expyear ?? "").length == 0) {
        alert("Debes colocar el año de expiración de la tarjeta");
        return;
      }
      if ((cardInfo.expmonth ?? "").length == 0) {
        alert("Debes colocar el mes de expiración de la tarjeta");
        return;
      }
    }

    if (isValid) {
      setState({
        ...state,
        isSaveBookingLoading: true,
      });

      const paymentData = {
        payment_method: null,
        price: 0,
        tax: 0,
        total: 0,
        course: "", // plan id
        applied_coupon: state.appliedCoupon,
      };

      const bookings = scheduleSelection.map((a, i) => {
        const duration = a['end_time'] - a['start_time'];
        const pick_date = a['pick_date']; // yyyy-mm-dd
        const start_time = a['start_time'];
        const whole_date = pick_date + " " + convert_minutes_to_time(start_time);
        const dateObject = getStartDateForScheduleInterval(a);
        let b = {
          // backend overwrites these ones:
          student_name: "",
          student_phone: "",
          student_email: "",

          student_skype_id: "",
          subject: null,
          note: "",
          tutor: (state.chosenTutor?.id ?? 0) + "",
          course: "-100", // symbol of no payment

          // change these acccording to selection
          duration: duration, // in minutes
          start_time: start_time,
          end_time: a['end_time'],
          booking_date: whole_date,
          booking_timestamp: Math.round(dateObject.getTime() / 1000),

          // new ones:
          materiaValue: state.materiaValue,
          nivelValue: state.nivelValue,
          disponibilidadValue: state.disponibilidadValue,

        };
        if (i === 0) {
          b = Object.assign(b, paymentData);
        }

        return b;
      });

      const global_balance = fullUserDataResponse.data?.globalBalance;
      const payment_payload = Object.assign(cardInfo, {
        'to_charge_in_cents': toChargeInCents,
        'cobertura_minutos': global_balance + "",
      });

      const bookings_payload = {
        bookings,
        payment_payload,
        "backendId": "agosto", // sirve para manejar el comportamiento de las verificaciones
      }

      // console.log(bookings_payload);
      console.log(bookings);

      fetchSaveBooking(bookings_payload).then((myResponse) => {
        if (myResponse === ERROR_CONST) {
          setState({
            ...state,
            isSaveBookingLoading: false,
          });
          alert(CONNECTION_ERROR_MESSAGE);
          return;
        }

        if (myResponse.status !== "OK") {
          setState({
            ...state,
            isSaveBookingLoading: false,
          });

          if (myResponse.status == "INTERSECTION") {
            const bookingsList = myResponse.intersections.map((v: any) => (new Date((v).booking_timestamp * 1000)).toLocaleString()).join("\n");
            alert("Lo siento😢, te ganaron, las siguientes reservas no pueden ser realizadas ya que acaban de reservarse:\n" + bookingsList);

          } else if (myResponse.status == "NO_HAY_SUFICIENTE_ANTICIPACION") {
            alert("Lo siento 😢, no hay suficiente anticipación");

          } else if (myResponse.status == "NO_TIENES_SUFICIENTE_COBERTURA") {
            alert("Lo siento 😢, no tienes suficiente cobertura");

          } else if (myResponse.status == "CARD_ERROR") {
            alert("Error de la tarjeta 😢: " + myResponse.card_error_message);

          } else if (myResponse.status == "STRIPE_ERROR") {
            alert("Error al intentar realizar el cargo a la tarjeta 😢: " + myResponse.stripe_payment_error);

          } else if (myResponse.status == "COBERTURA_DISTINTA") {
            alert("Tu saldo de clases gratis ha cambiado mientras escogías horario 🤔. Por favor recarga la página e intenta reservar otra vez.");

          } else if (myResponse.status == "ERROR_DE_CUPON") {
            setState({
              ...state,
              appliedCoupon: null,
              moneyToDiscountForAppliedCoupon: 0,
            });

            mostrarerrorcupon(myResponse.coupon_error ?? "");
          } else {
            alert(myResponse.status);
          }

        } else if (myResponse.charge_later == "YES" || myResponse.payment_already_handled == "YES") {

          const booking_group_id = myResponse.booking_group_id;

          if (myResponse.charged_in_cents > 0) {
            const valueInPen = myResponse.charged_in_cents / 100;

            // facebook pixel purchase event:
            try { // with try so it can work in the testing server
              // TODO: test facebook pixel
              fbqRecordEvent('track', 'Purchase', { currency: "PEN", value: valueInPen });
            } catch (e) {
              console.error(e);
            }
            try {
              // firebase analytics purchase event
              fa.logEvent('purchase', {
                currency: "PEN",
                value: valueInPen,
                transaction_id: "" + booking_group_id,
              });
            } catch (e) {
              console.error(e);
            }
          }

          history.push(confirmacionDeReservaPathRoute,
            {
              tutor: state.chosenTutor!,
              bookings,
              materiaId: state.materiaValue,
              nivelId: state.nivelValue,
              booking_group_id,
            }
          );


        } else if (myResponse.charge == "NO") { // flujo antiguo, pago con visanet
          const booking_group_id = myResponse.booking_group_id;
          let url = new URL(window.location.href);
          url.searchParams.set('step', 'confirm');
          url.searchParams.set('booking-id', booking_group_id);
          url.searchParams.set('charge', 'NO');
          window.location.replace(url.href);

          // OTHER PAYMENT SYSTEMS WE DO NOT USE ANYMORE

          // } else if (payment == 'PayPal') { // works as visanet for now
          //     const purchase_number = document.getElementById("purchase_number").value;
          //     const importe = jQuery("#total").val();
          //     let url = new URL(window.location.href);
          //     url.searchParams.set('step', 'confirm');
          //     url.searchParams.set('booking-id', booking_group_id);
          //     url.searchParams.set('amount', importe);
          //     url.searchParams.set('purchaseNumber', purchase_number);
          //     url.searchParams.set('payment_booking_id', myResponse.payment_booking_id);
          //     url.searchParams.set('charge', 'YES'); // para prevenir que no se intente verificar pago cuando viene de reservar previamente (charge=no esta puesto en la url)
          //     const redirUrl = url.href;


          //     // setting up visanet button
          //     generarSesion(jQuery("#visanet_token").val(), importe, redirUrl, function() {
          //         jQuery(".fa-3x").hide();
          //     });

          //     // jQuery('#paypal_amount').val(jQuery("#total").val());
          //     // jQuery('#paypal_return').val(window.location.href + '?step=confirm&booking-id=' + response['data']);
          //     // jQuery('#frm_paypal_checkout').submit();
          //     // jQuery('#boton_pago_visanet').attr('action', redirUrl);


          // } else if (payment == 'Stripe') {
          //     let amount = parseFloat(jQuery("#total").val() * 100);
          //     StripeCheckout.open({
          //         key: jQuery('#key_stripe').val(),
          //         billingAddress: false,
          //         amount: amount,
          //         currency: 'USD',
          //         name: 'TutorBooking',
          //         description: 'TutorBooking',
          //         email: jQuery('#student_email').val(),
          //         panelLabel: 'Checkout',
          //         token: function (res) {
          //             let input1 = jQuery('<input type="hidden" name="total" />').val(amount);
          //             let input2 = jQuery('<input type="hidden" name="booking_id" />').val(response['data']);
          //             let input3 = jQuery('<input type="hidden" name="post_id" />').val(response['post_id']);
          //             let input4 = jQuery('<input type="hidden" name="stripeToken" /></form>').val(res.id);
          //             jQuery('<form action="" method="post" novalidate="novalidate">').append(input1).append(input2).append(input3).append(input4).appendTo('body').submit();
          //         },
          //         locale: 'auto'
          //     });
        }
      });
    }
  }


  // const comp = (
  //   <p>
  //     oli
  //   </p>
  // );
  // usarlo con {comp}


  return (
    <div>
      <AgostoHeader title="Encuentra tu profesor ideal"
        onLoginClick={(defaultToLogin: boolean) => {
          // console.log("onloginclick");
          setIsLoginModalOpen(true, defaultToLogin);
        }}
      ></AgostoHeader>
      <div className="container-sm"><br />
        <AgostoBanner userProfile={profile}></AgostoBanner>
        <br />
        {/*multisteps-form*/}
        <div className="multisteps-form">
          {/*progress bar*/}
          <div className="row mt-4 multi-step">
            {/* ml-auto, me-auto (usados para centrar en bootstraps anteriores al 5) en bootstrap 5 cambian a ms-auto, me-auto (de start y end) (tested) */}
            <div className="col-12 col-lg-8 ms-auto me-auto mb-4">
              <div className="multisteps-form__progress" onClick={(e) => stepBarClickHandler(e)}>
                <button className="multisteps-form__progress-btn js-active step-2" type="button" title="Paso 1">
                  <div className="handCircle"></div>
                  <span className="desktop-text">Paso 1</span><span className="mobile-text">1</span>
                </button>
                <button className="multisteps-form__progress-btn step-3" type="button" title="Paso 2">
                  <div className="handCircle"></div>
                  <span className="desktop-text">Paso 2</span><span className="mobile-text">2</span></button>
                <button className="multisteps-form__progress-btn step-4" type="button" title="Paso 3">
                  <div className="handCircle"></div>
                  <span className="desktop-text">Paso 3</span><span className="mobile-text">3</span></button>
              </div>
            </div>
          </div>
          {/*form panels*/}
          <div className="row">
            <div className="col-12 m-auto">
              <div className="row multisteps-form__form">
                {/*single form panel step tutor filters*/}
                {/* No colocar más classes de bootstrap (u otras) al siguiente div, si quieres modificar el padding o estilos
                modifícalos en el div hijo
                 */}
                <div className="multisteps-form__panel js-active step-2" data-animation="scaleIn">
                  <div className="shadow rounded bg-white p-4 mb-4">
                    <h6 className="multisteps-form__title" >Encuentra tu profesor(a) ideal 😊👨‍🏫</h6>
                    <div className="multisteps-form__content">
                      <div className="mt-4">
                        <AgostoFilterTutors
                          state={state}
                          handleSelectChangeDisp={handleSelectChangeDisp}
                          handleSelectChangeMateria={handleSelectChangeMateria}
                          handleSelectChangeNivel={handleSelectChangeNivel}
                          handleElegirTutor={handleClickElegirTutor}
                          handlePriceRangeChange={handlePriceRangeChange}
                          handlePriceRangeChangeCommitted={handlePriceRangeChangeCommitted}
                          handleOpenTutorModal={(tutorId: number) => {
                            settutorModalOpen(tutorId);
                          }}
                        ></AgostoFilterTutors>
                        <div className="row">
                          <div className="button-row d-flex mt-4 col-12">
                            {/*<button class="btn btn-outline-secondary js-btn-prev" type="button" onclick="scrollToTop();" title="Anterior"><?php echo esc_html__('Anterior', 'gu-tutor') ?></button>*/}
                            {/* <button disabled={false} className="btn btn-primary ms-auto js-btn-next" type="button" title="Siguiente" onClick={() => btnNextHandler(0)}>Siguiente</button> */}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/*single form panel step schedules */}
                <div className="multisteps-form__panel step-4" data-animation="scaleIn">
                  <div className="shadow rounded bg-white p-4 mb-4">
                    <p>
                      <span className="h5 multisteps-form__title">Escoge tus horarios</span>
                      <span className="ms-1">(profesor(a): {state.chosenTutor?.name})</span>
                    </p>
                    <div className="multisteps-form__content">
                      <AgostoChooseSchedules state={state}

                        handleScheduleSelectionChange={(newS: any) => {
                          let sorted = newS;
                          if (newS != null) {
                            sorted = [...(newS as ScheduleSelection[])].sort((a, b) => {
                              const aDate = getStartDateForScheduleInterval(a);
                              const bDate = getStartDateForScheduleInterval(b);
                              return aDate.getTime() - bDate.getTime();
                            });
                          }
                          setState(ps => ({
                            ...ps,
                            scheduleSelection: sorted,
                          }));
                        }}

                        handlerClickPreviousWeek={() => {
                          trackClick("btn_sem_ant");
                          const newDate = plus_days(state.weekStartForAvailability as Date, -7);
                          if (newDate < (state.scheduleComponentLastLoadDate as Date)) { // not allowing going to the past week
                            return;
                          }
                          setState({
                            ...state,
                            weekStartForAvailability: newDate,
                          })
                        }}

                        handlerClickNextWeek={() => {
                          trackClick("btn_sem_sig");

                          const newWeekStart = plus_days(state.weekStartForAvailability as Date, 7);
                          // si la fecha de inicio de la siguiente semana es mayor a la fecha de caducidad entonces no permitir avanzar
                          const fecha_inicio_sgte_semana = newWeekStart.toISOString().split('T')[0];
                          const fecha_caducidad = (state.tutorAvailabilityResponse as TutorAvailability)?.fecha_caducidad ?? "";
                          if (fecha_inicio_sgte_semana <= fecha_caducidad) {
                            setState({
                              ...state,
                              weekStartForAvailability: newWeekStart,
                            })
                          } else {
                            setexpirationModalOpen(state.chosenTutor!);
                          }
                        }}

                      ></AgostoChooseSchedules>
                      <div className="button-row d-flex mt-4">
                        <button className="btn btn-outline-secondary js-btn-prev" type="button" title="Anterior" onClick={() => btnPrevHandler(1)}>Anterior</button>
                        <button disabled={false} className="btn btn-primary ms-auto js-btn-next" type="button" title="Siguiente" onClick={() => btnNextHandler(1)}>Siguiente</button>
                      </div>
                    </div>
                  </div>
                </div>
                {/*single form panel step payment */}
                <div className="multisteps-form__panel step-4" data-animation="scaleIn">
                  <div className="shadow rounded bg-white p-4 mb-4">
                    <h6 className="multisteps-form__title elite-title-01">Confirma tu reserva</h6>
                    <div className="multisteps-form__content">
                      <AgostoPayment state={state}
                        handleCouponApplication={
                          (coupon, money) => {
                            setState({
                              ...state,
                              moneyToDiscountForAppliedCoupon: money,
                              appliedCoupon: coupon,
                            });
                          }
                        }
                      ></AgostoPayment>
                      <div className="button-row d-flex mt-4">
                        <button className="btn btn-outline-secondary js-btn-prev" type="button" title="Anterior" onClick={() => btnPrevHandler(2)}>Anterior</button>
                        {/* <button disabled={false} className="btn btn-primary ms-auto js-btn-next" type="button" title="Siguiente" onClick={() => btnNextHandler(2)}>Siguiente</button> */}
                        {
                          <button disabled={state.isSaveBookingLoading} className="btn btn-success  ms-auto js-btn-next"
                            onClick={save_booking} type="button" title="Reservar" id="finish_booking">{(state.isSaveBookingLoading ? "Cargando..." : "Reservar")}</button>
                        }

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* Tutor details modals */}
        {
          (state.loadTutorsResponse !== "ERROR" && state.loadTutorsResponse != null ? state.loadTutorsResponse : []).map((tutor) => {
            return (
              <div key={tutor.id}>
                {/* Vertically centered scrollable modal */}
                <div className="modal fade" id={"exampleModal" + tutor.id} data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">{tutor.name}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                      </div>
                      <div className="modal-body">
                        {isEmpty(tutor.URL_video) ? "" :
                          <div className="eliteshowprofile">
                            <div className="row px-4 rounded shadow-sm bg-light embed-container">
                              <div className="col-sm-6 col-md-8 py-4">
                                <h5 className="mb-0">Mi presentación</h5><br />
                                <p><iframe src={isEmpty(tutor.URL_video) ? " " : "https://www.youtube.com/embed/" + tutor.URL_video} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen /></p>
                              </div>
                            </div>
                          </div>
                        }
                        {
                        !waitForModalOpeningToLoadTutorProfile || tutorModalOpen === tutor.id ? 
                        <TutorInfoProfile
                          key={"TutorInfoProfile_" + tutor.id}
                          onChooseTutor={() => {
                            trackClick("btn_et_ver_mas_" + tutor.id);
                            handleElegirTutorNoAnalytics(tutor);
                            const id = "exampleModal" + tutor.id;
                            hideBootstrapModal(id);
                          }}                          
                          butonAgendar={false}
                          tutor={tutor}
                        ></TutorInfoProfile> 
                        : <></>
                        }
                      </div>
                      <div className="modal-footer">

                        <div className="row rounded" style={{
                          width: "100%",
                        }}>
                          <div className="p-0 d-grid gap-2">
                            <a href="." className="btn btn-primary btn-lg btn-flotante" tabIndex={1} role="button"
                              onClick={(e) => {
                                e.preventDefault();
                                trackClick("btn_et_ver_mas_" + tutor.id);
                                handleElegirTutorNoAnalytics(tutor);
                                const id = "exampleModal" + tutor.id;
                                hideBootstrapModal(id);
                                return false;
                              }}
                            > <i className="fas fa-calendar-alt"></i> Elegir</a>
                          </div>
                        </div>
                        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
      <Footer></Footer>



      {/* Modal for login/registration */}
      <ModalLogin

        isOpen={loginModalState.isLoginModalOpen}

        defaultToLogin={loginModalState.defaultToLogin}

        onClose={() => {
          setIsLoginModalOpen(false);
        }}

        onSuccess={(f) => {
          setTimeout(() => {
            scrollAfterChangingStep();
          }, 500);
          setIsLoginModalOpen(false);

          if (state.continueBooking) {
            setState(ps => ({
              ...ps,
              continueBooking: false,
            }));
            validateAndChangeStep(2, undefined, f);
          } else if (state.tutorThatTriedToChoose != null) {
            handleElegirTutorWithoutLoginValidations(state.tutorThatTriedToChoose);
          } else {
            if (f.data?.tutorId != null) { // teacher
              history.push(misEstudiantesPathRoute + "#proximasclases");
            }
          }
        }}
      ></ModalLogin>


      {/* Modal for updating user info */}
      <ModalUpdateUserInfo

        isOpen={isUpdateUserInfoModalOpen}

        onClose={() => {
          setisUpdateUserInfoModalOpen(false);
        }}

        onSuccess={(f) => {
          setTimeout(() => {
            scrollAfterChangingStep();
          }, 500);

          setisUpdateUserInfoModalOpen(false);

          if (state.continueBooking) {
            validateAndChangeStep(2, undefined, f);
          }
        }}
      ></ModalUpdateUserInfo>

      {/* Modal for tutor expiration date */}
      <ModalPastExpirationDate
        isOpen={expirationModalOpen != null}
        tutor={expirationModalOpen}
        onClose={() => {
          setexpirationModalOpen(null);
        }}
        tutorAvailabilityResponse={state.tutorAvailabilityResponse ?? null}
      ></ModalPastExpirationDate>

    </div>
  )
}

export default PageBooking

function scrollAfterChangingStep() {
  scrollToSelector("#elite_saldo_banner");
}

