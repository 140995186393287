import { useEffect, useState } from 'react'
import '../style/ReactDayPickerCustom.scss';
import { Link as a, Link, useHistory, useLocation } from 'react-router-dom';
import { confirmMNPathRoute, ensenarPathRoute, misClasesPathRoute } from '../App';
import AgostoHeader from '../components/AgostoHeader';
import RowSpacer from '../components/util/RowSpacer';
import { useProfile } from '../data/ProfileProvider';
import Footer from '../components/Footer';
import { Tutor } from '../model/Tutor';
import { Paper } from '@mui/material';
import '../style/SmallGuTutorUtils.scss';
import { allKeyword } from '../util/CoursesConstants';
import ModalConfirmMateriaYNivel from './ModalConfirmMateriaYNivel';


interface State {
}

const PageBookingsConfirmation = () => {

  const ph = useProfile();
  const profile = ph.value;

  const history = useHistory();
  const location = useLocation<{
    tutor: Tutor | null,
    bookings: unknown,
    materiaId: string | null,
    nivelId: string | null,
    booking_group_id: string | null,
  }>();
  const tutor = location.state?.tutor;
  const bookings = location.state?.bookings as any;
  const materiaId = location.state?.materiaId;
  const nivelId = location.state?.nivelId;
  const booking_group_id = location.state?.booking_group_id;

  const initialState: State = {
  }
  const [state, setState] = useState(initialState);
  const [isMNConfirmOpen, setisMNConfirmOpen] = useState(materiaId === allKeyword || nivelId === allKeyword);

  useEffect(() => {
    document.title = "Confirmación de reservas | Elite Classroom";
    if (profile.user_id === 0) { // user is not logged in
      history.push(ensenarPathRoute);
    } else {
      ;
    }

  }, []);

  return (
    <div>
      <AgostoHeader title="Confirmación de reservas"></AgostoHeader>
      <div className="container">
        <RowSpacer height={20}></RowSpacer>
        <div className='row justify-content-center'>
          <div className='col-11 col-md-5'>
            <div className='row justify-content-center'>


              <Paper elevation={7} sx={{
                padding: '30px',
                border: 'red 5px solid'
              }}>
                <h6 className="text-center">
                  <span style={{
                    marginBottom: '10px',
                  }}>¡¡IMPORTANTE!! ⚠️</span>
                </h6>
                <p className='mb-3'>
                  Enviar el material de la clase es indispensable para que tu profesor(a) {tutor?.name} pueda organizar la clase.
                </p>
                <div style={{ textAlign: 'center' }}>
                  <a href={window.location.origin + "/mensajes" + "?fepaction=newmessage&fep_to=" + tutor?.user_id} className="btn btn-primary btn-lg white-text fs-6" role="button" aria-disabled="false">
                    Enviar material</a>
                </div>
              </Paper>


              <RowSpacer height={10}></RowSpacer>

              <Paper elevation={7} sx={{
                padding: '30px',
              }}>
                <div className="d-flex align-items-center">
                  <div className="">
                    <i className="fa fa-hand-peace booking-confirm-icon" aria-hidden="true"></i>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="text-center">
                      <span style={{
                        margin: '10px',
                      }}>¡RESERVA CONFIRMADA!</span>
                    </h6>
                  </div>
                  <div className="">
                    <i className="fa fa-hand-peace booking-confirm-icon" aria-hidden="true"></i>
                  </div>
                </div>

                <div className="row justify-content-center mt-4 mb-1">
                  <div className="col-12 col-md-8">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe
                        title="Elite Class tutorial"
                        className="embed-responsive-item" src="https://www.youtube.com/embed/8BMBWFweakM"
                        allowFullScreen={true}></iframe>
                    </div>
                  </div>
                </div>
                <br />

                <strong><p
                  style={{
                    marginBottom: '1.4rem',
                  }} className="">{"Tu profesor(a) " + tutor?.name + ' y tú, recibirán la confirmación vía email con los detalles de la reserva.'} </p></strong>
                <p className="elite-type">En la sección "Mis clases" encontraras los detalles de tu reserva, incluyendo el link a la videoconferencia y otras funciones.</p>

                <div className='text-center mt-4'>
                  <Link to={misClasesPathRoute} className="btn btn-primary btn-lg white-text fs-6" role="button" aria-disabled="false">
                    Mis reservas</Link>
                </div>
                {/* {JSON.stringify(bookings)} */}
              </Paper>
              <RowSpacer height={30}></RowSpacer>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmMateriaYNivel
        isOpen={isMNConfirmOpen}
        onClose={() => setisMNConfirmOpen(false)}
        tutor={tutor}
        materiaId={materiaId}
        nivelId={nivelId}
        booking_group_id={booking_group_id}
      ></ModalConfirmMateriaYNivel>
      <Footer></Footer>
    </div>
  )
}

export default PageBookingsConfirmation
