import { Button } from '@mui/material';
import { useEffect, useState } from 'react'
import { ERROR_CONST, fetchClaimReferralRewards, fetchFullUserData, fetchReferralData, LOGIN_ERROR_CONST } from '../api/EliteFetch';
import AgostoHeader from '../components/AgostoHeader'
import AgostoLoader from '../components/AgostoLoader';
import Footer from '../components/Footer'
import { ReferralResponse } from '../model/Referral';
import { trackClick } from '../util/AnalyticsUtil';
import { useMuiEasySnackbar } from "mui-easy-snackbar";
import "../util/CopiarLink";
import { whitespaceChar } from '../util/CopiarLink';
import { CONNECTION_ERROR_MESSAGE, LOGIN_ERROR_MESSAGE } from '../util/Messages';
import { formatCents } from '../util/CurrencyUtil';
import RowSpacer from '../components/util/RowSpacer';
import AgostoBanner from '../components/AgostoBanner';
import { useProfile } from '../data/ProfileProvider';
import ModalLogin from './ModalLogin';
import { copyTextToClipboard } from '../util/ClipboardUtil';

const MAX_BEFORE_SEE_MORE = 4; // maximo a mostrar antes de que aparezca el boton de ver mas

export const referralQueryParamKey = 'ref';

interface State {
  response: ReferralResponse | null | "ERROR" | "LOGIN_ERROR";
  seeMoreButtonClicked: boolean; // pero igual solo muestra el botón en caso hayan más de MAX_BEFORE_SEE_MORE
  isClaimReferralLoading: boolean;
}

function PageSeeMore() {
  const initialState: State = {
    response: null,
    seeMoreButtonClicked: false,
    isClaimReferralLoading: false,
  }

  const [state, setState] = useState(initialState);
  const [isLoginModalOpen, setisLoginModalOpen] = useState(false);
  const [defaultToLogin, setdefaultToLogin] = useState(false);

  async function init() {

    fetchFullUserData().then(fullUserData => {
      // nothing for now, just for update purposes
    });

    setState(ps => ({
      ...ps,
      response: null,
    }));
    fetchReferralData().then(dat => {
      setState(ps => ({
        ...ps,
        response: dat,
      }));
    });
  }

  const ph = useProfile();
  const profile = ph.value;

  // runs when this component loads:
  useEffect(() => {
    if (profile.user_id === 0) {
      setisLoginModalOpen(true);
    }
    // initializations
    init();
  }, []);

  document.title = "Compartir | Elite Classroom"; // para que cambie el nombre de la pestaña que se muestra en el navegador

  const snackbar = useMuiEasySnackbar();

  let content = null;
  if (state.response === ERROR_CONST) {
    content = <div>{CONNECTION_ERROR_MESSAGE}</div>
  } else if (state.response === LOGIN_ERROR_CONST) {
    content = <div>{LOGIN_ERROR_MESSAGE}</div>
  } else if (state.response == null) {
    content = (<div>
      <AgostoLoader></AgostoLoader>
    </div>);
  } else {

    const refer = state.response.data;
    const unclaimedReferredUsers = refer.referred_users.filter(u => u.claim_time_goal_01 === 0);
    const referredUsersToHelpForGoal01 = refer.referred_users.filter(u => !u.reached_referral_goal_01);
    const showBtnSeeMore = !state.seeMoreButtonClicked && refer.referred_users.length > MAX_BEFORE_SEE_MORE;

    const referralLink = "https://eliteclassroom.com/v2" + "?" + referralQueryParamKey + "=" + encodeURIComponent(refer.refCode);

    const mensajeCompartirLink = "Obtén 20 soles gratis 🎁 en EliteClassroom usando mi link de referidos: "
      + referralLink;
    content = <div className="container">
      <div className="px-1 px-md-4 text-center mt-4 justify-content-center">
        <div className="row gx-5 p-2 justify-content-center">
          <div className="row d-flex align-items-center justify-content-center">
            <div className="
              col-12
              col-sm-12 
              col-md-9 
              col-lg-7
              ">
              <div>
                <img src="https://storage.googleapis.com/elite-classroom/REFERIDOSWEB-2.png" width={"100%"} alt="" title="Am" />
              </div>
            </div>
            <div className="
              col-12
              col-sm-12 pt-sm-4
              col-md-12 offset-md-0
              col-lg-5 offset-lg-0
              ">
              <h4 style={{ color: 'blue' }}>Háblale a un Amigo de Elite Classroom</h4><br />
              <p>Cada vez que un amigo que refieras se registre en Elite Classroom este obtendrá
                <strong> S/20 de descuento</strong> y tu <strong>S/20 de saldo disponible </strong>
                para la próxima clase.
              </p><br />
              <div className="col-sm-12 bg-light border text-center p-2 mx-1">
                <div className="gap-1 col-12 
                    mx-auto 
                    pt-3 
                    px-5 px-md-5 px-lg-5">
                  <Button variant="contained" href={"https://facebook.com/sharer/sharer.php?u=" + encodeURIComponent(referralLink)}>
                    <i className="fab fa-facebook-f p-1"></i> Compartir en Facebook
                  </Button>
                  <hr />
                  <Button variant="contained" color="success" style={{
                    color: 'white',
                  }} href={"https://wa.me/?text=" + encodeURIComponent(mensajeCompartirLink)}>
                    <i className="fab fa-whatsapp-square p-1"> </i> Compartir en Whatsapp
                  </Button>
                  <hr />
                  <span className="m-3">Comparte tu link de referidos</span>
                  <div id="main" className="container-fluid ">
                    <pre className="shadow p-3 bg-white rounded"><code>{referralLink}</code></pre>
                    <button id="btnCopiar" onClick={async () => {
                      trackClick("btn_copy_reflink");
                      try {
                        await copyTextToClipboard(referralLink);
                        snackbar.showSuccess("Link copiado 😊");
                      } catch (e) {
                        snackbar.showError(e);
                      }
                    }} className="btn btn-primary">COPIAR</button>
                    <div id="alerta" className="mt-3 mb-1 p-1 alert invisible">
                      {whitespaceChar}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RowSpacer height={20}></RowSpacer>
          <hr />
          <br /><br />
          <div className="text-center">
            <h3 style={{ color: 'blue' }}>Cómo funciona</h3>
          </div>
          <div className="row p-5">
            <div className="col-md-3 mt-2">
              <div className="row">
                <div className="col text-center">
                  <h1>1</h1>
                </div>
              </div>
              <div className="row text-center">
                <p>Comparte tu link de referidos con tus amig@s</p>
              </div>
            </div>
            <div className="col-md-4 offset-md-1 
              mt-4 mt-md-2

              ">
              <div className="row">
                <div className="col text-center">
                  <h1>2</h1>
                </div>
              </div>
              <div className="row text-center">
                <span>Tus amig@s se registran y automáticamente obtienen S/20 de saldo disponible</span>
              </div>
            </div>
            <div className="col-md-3 offset-md-1 mt-2">
              <div className="row">
                <div className="col text-center">
                  <h1>3</h1>
                </div>
              </div>
              <div className="row text-center">
                <span>Obtendrás <b>S/20</b> de saldo disponible por cada amigo que lleve su primera clase pagada</span>
              </div>
            </div>
          </div>
          <br /><br /><hr />
          <br />
          <br />
          <div className="text-center">
            <h3 style={{ color: 'blue' }}>Mis amigos referidos</h3>
            <br />
            <div className="col">
              <div className="p-8 text-center">
                <span>{refer.referred_users.length === 0 ? <p>Tu link no ha sido usado aún, qué esperas para compartirlo!! 😉</p> : <h5 style={{ color: 'blue' }}>Tienes {refer.referred_users.length} amigo{refer.referred_users.length === 1 ? "" : "s"} referido{refer.referred_users.length === 1 ? "" : "s"}</h5>}</span>
                <br /><br />
                {refer.referred_users.length > 0 ?
                  <div className="row">
                    <div className="col-md-6 offset-md-3">
                      {refer.referred_users.slice(0, (showBtnSeeMore ? MAX_BEFORE_SEE_MORE : refer.referred_users.length))
                        .map((usersRef) => {
                          return (
                            <p><span><b>{usersRef.names}</b> : {usersRef.reached_referral_goal_01 ?
                              "Ya llevó su 1ra clase pagada " + (usersRef.claim_time_goal_01 !== 0 ? "(ya reclamaste esta recompensa)" : "🤩")
                              :
                              'Aún no lleva una clase pagada'
                            } </span></p>
                          )
                        })}
                      {showBtnSeeMore && <Button variant="text" onClick={() => {
                        setState(ps => ({
                          ...ps,
                          seeMoreButtonClicked: true,
                        }));
                      }}>Ver más</Button>}
                      {state.seeMoreButtonClicked && <Button variant="text" onClick={() => {
                        setState(ps => ({
                          ...ps,
                          seeMoreButtonClicked: false,
                        }));
                      }}>Ver menos</Button>}
                    </div>
                  </div> : <h5></h5>
                }
                <br /><br />
                <div className="col-md-6 offset-md-3 p-5 bg-light border">
                  {
                    (refer.rewards_to_claim > 0 ?
                      <>
                        <p>Equivalente a {formatCents(refer.rewards_to_claim)} de saldo disponible 🎁</p><br />
                        <button disabled={state.isClaimReferralLoading} className="btn btn-primary" onClick={() => {
                          trackClick('btn_reclamar_recompensa_ref');

                          // calling the ajax method
                          setState((ps) => ({
                            ...ps, // spread operator, en este caso es equivalente a:
                            // isClaimReferralLoading: ps.isClaimReferralLoading,
                            // response: ps.response,
                            // seeMoreButtonClicked: ps.seeMoreButtonClicked,
                            isClaimReferralLoading: true,
                          }));

                          fetchClaimReferralRewards().then((r) => {
                            setState((ps) => ({
                              ...ps,
                              isClaimReferralLoading: false,
                            }));
                            if (r === ERROR_CONST) { // error de internet
                              snackbar.showError(CONNECTION_ERROR_MESSAGE);
                            } else {
                              // snackbar.showSuccess("");
                              alert("Se añadió " + formatCents(r.reward_in_cents) + " a tu cobertura 🤩. Muchas gracias por recomendarnos 😊!!!");
                              init();
                            }
                          });
                        }}>Agregarme saldo disponible</button>
                      </>
                      :
                      (referredUsersToHelpForGoal01.length > 0 ?
                        <p>🙋 Ayuda a tus referidos a llevar clases pagadas para que ganes tu recompensa</p>
                        :
                        <p>Invita a tus amig@s para ganar tu recompensa 🎁</p>
                      )
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  return (
    <div>
      <AgostoHeader title="Gana S/20" onLoginClick={(defaultToLogin) => {
        trackClick('btn_login_referidos');
        setdefaultToLogin(defaultToLogin);
        setisLoginModalOpen(true);
      }}></AgostoHeader>
      <br />
      <div className="container">
        <AgostoBanner userProfile={profile}></AgostoBanner>
      </div>

      {
        content
      }
      <br />
      <Footer></Footer>

      {/* Modal for login/registration */}
      <ModalLogin
        isOpen={isLoginModalOpen}
        defaultToLogin={defaultToLogin}
        onClose={() => {
          setisLoginModalOpen(false);
        }}
        onSuccess={(f) => {
          setisLoginModalOpen(false);
          init();
        }}
      ></ModalLogin>
    </div>
  )
}

export default PageSeeMore
