import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import PageMyPayments from './pages/PageMyPayments';
import PageTestingHome from './pages/PageTestingHome';
import PageMonedero from './pages/PageMonedero';
import PageBooking from './pages/PageBooking';
import PageMultistepsTest from "./pages/PageMultistepsTest";
import PageUpdateStateTest from "./pages/PageUpdateStateTest";
import PageLogin from "./pages/PageLogin";
import PageMDBTest from "./pages/PageMDBTest";
import { useEffect, useState } from "react";
import { fetchFullUserData } from "./api/EliteFetch";
import PageMyReservations from "./pages/PageMyReservations";
import PageTutorRouteHandler from "./pages/PageTutorRouteHandler";
import { useProfile } from "./data/ProfileProvider";
import smartlookClient from 'smartlook-client'
import { isProductionWebsite } from "./util/DevEnvUtil";
import PageTestingMui from "./pages/PageTestingMui";
import PageSeeMore, { referralQueryParamKey } from "./pages/PageSeeMore";
import PageBookingRouteHandler from "./pages/PageBookingRouteHandler";
import PageTutorSignup from "./pages/PageTutorSignup";
import PageTutorDateExceptions from "./pages/PageTutorDateExceptions";
import PageBookingsConfirmation from "./pages/PageBookingsConfirmation";
import ModalConfirmMateriaYNivel from "./pages/ModalConfirmMateriaYNivel";
import PageMyClasses from "./pages/PageMyClasses";
import PageMyStudents from "./pages/PageMyStudents";
import PageThanksForCalification from "./pages/PageThanksForCalification";
import PageTutorNewEditProfile from "./pages/PageTutorNewEditProfile";
import PageVideoconferenceRouteHandler from "./pages/PageVideoconferenceRouteHandler";
import PageMyDataPersonal from "./pages/PageMyDataPersonal";

interface State {
}

export const homePathRoute = "/";
export const compartirPathRoute = "/compartir";
export const reservarPathRoute = "/reservar";
export const ensenarPathRoute = "/enseñar";
export const confirmacionDeReservaPathRoute = '/confirmacion';
export const confirmMNPathRoute = '/confirmar-materia-y-nivel';
export const misPagosPathRoute = "/mis-pagos";
export const misClasesPathRoute = "/mis-clases";
export const misReservasPathRoute = "/mis-reservas";
export const misEstudiantesPathRoute = "/mis-estudiantes";
export const myTutorPublicProfile = "/tutor";
export const oldIndexHtmlLink = homePathRoute;
export const misClientesPathRoute = "/proveedor";
export const myTutorPublicProfileTemp = "/tutor-temp";
export const myThanksForCalif = "/calificacion-realizada";
export const tutorNewEditProfilePathRoute = "/editar-mi-perfil";
export const videoconferencePathRoute = "/videoconferencia";
export const misDatosPathRoute = "/mis-datos";


export const REFERRAL_CODE_LOCAL_STORAGE_KEY = 'referralCode';

function App() {

  const initialState: State = {
  }

  const [state, setState] = useState(initialState);

  // runs when this component loads:
  useEffect(() => {
    // initializations
    fetchFullUserData().then(fullUserData => {
      // nothing for now
    });

    // smartlook
    if (isProductionWebsite) {
      smartlookClient.init('2d6a672f10e1c5c47d0d1fda6fa9feb3b997ee92');
    }

    // storing referral code in case there is one
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get(referralQueryParamKey);
    if (referralCode != null) {
      localStorage.setItem(REFERRAL_CODE_LOCAL_STORAGE_KEY, referralCode);
    }

  }, []);

  
  const ph = useProfile();
  const profile = ph.value;
  const names = (profile?.data?.names ?? "");
  const firstName = names.split(" ")[0];

  const r = Math.random(); // real en [0, 1)

  let welcomePhrase = "";

  if (r < 0.33) {
    welcomePhrase = "Nos alegra verte de nuevo, " + firstName;
  } else if (r < 0.66) {
    welcomePhrase = "Bienvenido de vuelta, " + firstName;
  } else {
    welcomePhrase = "Que gusto verte de nuevo, " + firstName;
  }


  return (
    <Router
      // basename="/tunnelJosue" // con esto los assets aún se cargan en el path '/'
      // basename={process.env.PUBLIC_URL} // esto funciona durante development (testing)
      basename={"/v2"} // produccion
    >
      <div>
        {/* <Header username={username}></Header>
        <MyPayments paymentsRes={paymentsRes}></MyPayments>
        <Footer></Footer> */}

        {/* <ProvisionalNavigator></ProvisionalNavigator> */}

        <Switch>
          <Route path={misPagosPathRoute}>
            <PageMyPayments></PageMyPayments>
          </Route>
          <Route path="/monedero">
            <PageMonedero></PageMonedero>
          </Route>
          <Route path="/PageMDBTest">
            <PageMDBTest></PageMDBTest>
          </Route>
          <Route path="/login-test">
            <PageLogin></PageLogin>
          </Route>
          <Route path="/testing-home">
            <PageTestingHome></PageTestingHome>
          </Route>
          <Route path="/PageMultistepsTest">
            <PageMultistepsTest></PageMultistepsTest>
          </Route>
          <Route path="/PageTestingMui">
            <PageTestingMui></PageTestingMui>
          </Route>
          <Route path={reservarPathRoute}>
            <PageBookingRouteHandler></PageBookingRouteHandler>
          </Route>
          <Route path={videoconferencePathRoute}>
            <PageVideoconferenceRouteHandler></PageVideoconferenceRouteHandler>
          </Route>
          <Route path={misReservasPathRoute}>
            <PageMyReservations></PageMyReservations>
          </Route>
          <Route path={misClasesPathRoute}>
            <PageMyClasses></PageMyClasses>
          </Route>
          <Route path={compartirPathRoute}>
            <PageSeeMore></PageSeeMore>
          </Route>
          <Route path={misEstudiantesPathRoute}>
            <PageMyStudents></PageMyStudents>
          </Route>
          <Route path={myTutorPublicProfile}>
            <PageTutorRouteHandler></PageTutorRouteHandler>
          </Route>
          <Route path={myThanksForCalif}>
            <PageThanksForCalification></PageThanksForCalification>
          </Route>
          <Route path="/test-update-state">
            <PageUpdateStateTest></PageUpdateStateTest>
          </Route>
          <Route path="/testing-bienvenida">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-4 offset-sm-4 text-center">
                  <h4>{welcomePhrase}</h4>
                </div>
              </div>
            </div>
          </Route>
          <Route path={ensenarPathRoute}>
            <PageTutorSignup></PageTutorSignup>
          </Route>
          <Route path={'/excepciones-en-horarios'}>
            <PageTutorDateExceptions></PageTutorDateExceptions>
          </Route>
          <Route path={confirmacionDeReservaPathRoute}>
            <PageBookingsConfirmation></PageBookingsConfirmation>
          </Route>
          <Route path={tutorNewEditProfilePathRoute}>
            <PageTutorNewEditProfile></PageTutorNewEditProfile>
          </Route>
          <Route path={misDatosPathRoute}>
            <PageMyDataPersonal></PageMyDataPersonal>
          </Route>
          <Route path={homePathRoute}>
            <PageBooking></PageBooking>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
