import React, { useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { compartirPathRoute, myThanksForCalif, reservarPathRoute } from '../App';
import AgostoHeader from '../components/AgostoHeader'
import { useProfile } from '../data/ProfileProvider';

const PageThanksForCalification = () => {

  const { search } = useLocation(); // this makes react do not clear the search (query parameters) when navigating
  const urlParams = new URLSearchParams(search);
  const name = urlParams.get("name") ?? "";
  const stars = urlParams.get("stars") ?? "5";
  const tutor = urlParams.get("tutor") ?? "";

  const history = useHistory();


  useEffect(() => {
    document.title = "Gracias | Puntual";
  }, []);

  return (
    <div>
      <div>
        <div className="h-100 d-inline-block" style={{ width: '100%' }}>
          {/*<AgostoHeader title={'Gracias'}></AgostoHeader>*/}
          <div className="container pt-4 px-4 pb-4 w-100 h-100">
            <div className="mt-2" style={{
              background: "#463dff",
              height: "70px",
              backgroundColor: "#463dff",
            }} ></div><br /><br />
            <div className="row">
              <div className="col-md-3 text-center"><img src="https://storage.googleapis.com/puntual-pe/pruebaCono.jpg" style={{ height: "180px", width: "180px" }} /></div>
              <div className="col-md-6 text-center">
                <h1><strong>{name}</strong></h1>
                <h1>¡Muchas gracias por tu calificación!</h1>
                {stars === "5" &&
                  <>
                    <br />
                    <h5><strong>{getRandomThankPhrase()}</strong></h5>
                  </>}
              </div>
              <div className="col-md-3 text-center"><img src="https://storage.googleapis.com/puntual-pe/pruebaConoInv.jpg" style={{ height: "180px", width: "180px" }} /></div>
              {stars === "5" &&
                <div className="d-grid gap-2 col-6 mx-auto mt-5">
                  <button 
                    onClick={() => {
                      history.push(reservarPathRoute + "/" + tutor);
                    }}
                    className="btn btn-primary" style={{ fontSize: 22, backgroundColor: 'rgba(18, 9, 173 , 0.95)' }} type="button">Seguir reservando</button>
                </div>
              }

            </div>
            {stars === "5" &&
              <div className="row pt-5">
                <div className="col-md-6 offset-md-3 text-center">
                  Comparte EliteClassroom con tus amigos
                  <br/>
                  Recuerda que puedes ganar S/20 compartiendo tu codigo de referido
                  <div className="d-grid gap-2 col-6 mx-auto pt-3">
                    <Link to={compartirPathRoute} className="btn btn-primary" type="button" >Gana S/20</Link>
                  </div>
                </div>
              </div>
            }
            <br />
            <div className="mt-5" style={{
              background: "#463dff",
              height: "70px",
              backgroundColor: "#463dff",
            }} ></div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default PageThanksForCalification

function getRandomThankPhrase() {
  const phrases = [
    "Encantados de haberte ayudado",
    "¡Apreciamos mucho tu confianza en nosotros!",
  ];
  return phrases[Math.floor(Math.random() * phrases.length)];
}