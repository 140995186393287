import { VisibilityOff, Visibility } from '@mui/icons-material';
import { TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, createTheme, Button, FormLabel, RadioGroup, FormControlLabel, Radio, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { fetchTutorPublicProfile, eliteRegistration, fetchSaveTutorProfile, ERROR_CONST } from '../api/EliteFetch';
import { TutorPublicProfileResponse } from '../api/TutorPublicProfileResponse';
import { ensenarPathRoute, homePathRoute, misClientesPathRoute } from '../App';
import AgostoHeader from '../components/AgostoHeader';
import AgostoLoader from '../components/AgostoLoader';
import AgostoSimpleRegistration from '../components/AgostoSimpleRegistration';
import Footer from '../components/Footer';
import RowSpacer from '../components/util/RowSpacer';
import { useProfile } from '../data/ProfileProvider';
import { SaveTutorProfilePayload } from '../model/SaveTutorProfilePayload';
import { themeOptions } from '../style/MuiThemes';
import { trackClick } from '../util/AnalyticsUtil';
import { getPerfilProfesorUrl } from '../util/LinkConstants';
import { CONNECTION_ERROR_MESSAGE } from '../util/Messages';
import { isEmpty } from '../util/StringUtil';

interface State {
  response: TutorPublicProfileResponse | null | "ERROR";
}

const PageTutorNewEditProfile = () => {

  const ph = useProfile();
  const profile = ph.value;
  const history = useHistory();


  const initialState: State = {
    response: null,
  }
  const [state, setState] = useState(initialState);
  // declaring loading state
  const [isLoading, setIsLoading] = useState(false);
  const [nProducts, setnProducts] = useState(0);

  useEffect(() => {
    document.title = "Editar mi perfil | Elite Classroom";
    if (profile.user_id === 0) { // user is not logged in
      history.push(homePathRoute);
    }
    if (profile.data?.tutorId == null) { // user is logged in and is NOT a tutor
      window.location.href = getPerfilProfesorUrl();
    }
    fetchTutorPublicProfile(Number(profile.data?.tutorId)).then(dat => {
      setState(ps => ({
        ...ps,
        response: dat,
      }));
      if (dat !== ERROR_CONST) {
        setnProducts(dat?.data.products?.length ?? 0);
      }
    });
  }, []);

  let content = <></>;
  if (state.response === ERROR_CONST) {
    content = <div>{CONNECTION_ERROR_MESSAGE}</div>
  } else if (state.response == null) {
    content = (<div>
      <AgostoLoader></AgostoLoader>
    </div>);
  } else {
    const tutor = state.response.data;
    const products = tutor.products ?? [];

    content = (<form onSubmit={async (event) => {
      event.preventDefault();
      const payload: SaveTutorProfilePayload = {
        "products": new Array(nProducts).fill(0).map((_, i) => {
          const n = (event.target as any)["productPrice" + i].value;
          return {
            name: (event.target as any)["productName" + i].value,
            price_in_cents: (isEmpty(n) ? null : Number(n) * 100),
          };
        }),
        booking_min_duration: (event.target as any)["booking_min_duration"].value,
      };
      setIsLoading(true);
      const r = await fetchSaveTutorProfile(payload);
      setIsLoading(false);
    }}>

      {/* Form content: */}
      <ThemeProvider theme={createTheme({
        ...themeOptions,
        typography: {
          // In Chinese and Japanese the characters are usually larger,
          // so a smaller fontsize may be appropriate.
          fontSize: 11.5,
        },
      })}>
        <h5>Clases</h5>
        <FormControl className='mt-3'>
          <FormLabel id="booking_min_duration-group-label">Elige la duración mínima de las clases que te agenden:</FormLabel>
          <RadioGroup
            row
            aria-labelledby="booking_min_duration-group-label"
            name="booking_min_duration"
            defaultValue={tutor.booking_min_duration}
          >
            {
              [15, 30, 45, 60, 75, 90].map((booking_minutes) => {
                return (<FormControlLabel key={booking_minutes} value={booking_minutes} 
                  control={<Radio />} label={booking_minutes + " minutos"} />);
              })
            }
          </RadioGroup>
        </FormControl>
        <RowSpacer height={15}></RowSpacer>

      </ThemeProvider>


      {/* Submit button: */}
      {/* Al parecer btn-block sirve para que el botón tome todo el ancho disponible (ancho del padre) */}
      <Button
        fullWidth
        className="mb-3"
        type='submit'
        variant="contained"
        disabled={isLoading}>{isLoading ? "Guardando...⌛" :
          "Guardar"}</Button>

      <br />

    </form>);
  }

  return (
    <div>
      <AgostoHeader title="Editar más detalles de mi perfil"></AgostoHeader>
      <div className="container">
        <RowSpacer height={20}></RowSpacer>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-6'>
            {content}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default PageTutorNewEditProfile
