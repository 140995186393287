import React from 'react'
import { ERROR_CONST } from '../api/EliteFetch';
import { FullUserDataResponse } from '../model/FullUserProfile';
import { format_minutes_to_lesson_hours } from '../util/ConversionUtil';

const AgostoBanner = (props : {
  userProfile: FullUserDataResponse | null | "ERROR",
  }) => {
  const balanceIsLoading = props.userProfile == null;
  const balanceForBanner = (props.userProfile === ERROR_CONST ? 0 : (props.userProfile?.data?.globalBalance ?? 0));
  const balanceCentsForBanner = (props.userProfile === ERROR_CONST ? 0 : (props.userProfile?.data?.centsBalance ?? 0));
  // const balanceCentsForBanner = 5000 * 1;

  return (
    <div>
      <div id="elite_saldo_banner" className="alert alert-danger alert-dismissible fade show" role="alert">
          {
            (balanceIsLoading ? "...⌛ " : <span className="me-1">
              <strong className="fs-6">{(balanceForBanner === 0 ? "" : "¡Tienes " + format_minutes_to_lesson_hours(balanceForBanner) + " de saldo! ")}</strong>
              <strong className="fs-6">
                {(balanceCentsForBanner === 0 ? "" : (balanceForBanner > 0 ? "¡Además tienes" : "¡Tienes") + " S/" + (balanceCentsForBanner / 100).toFixed(2) + " de saldo! ")}
              </strong>
            </span>)
          }
          [Ahorra el 5% reservando más de S/100 en clases y el 10% reservando más de S/200 en clases] (1 clase = 45 minutos)
          {/* [Ahorra reservando varias clases a la vez] */}
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" />
        </div>
    </div>
  )
}

export default AgostoBanner
