import React from 'react'
import { trackClick } from '../util/AnalyticsUtil';

const AgostoAcceptConditions = ({analyticsName} : {
  analyticsName : string,
}) => {
  return (
    <p>Al iniciar sesión o crear cuenta, aceptas 
      las <a href='https://eliteclassroom.com/terminos-y-condiciones/' onClick={(e) => {
        trackClick('btn_terminos' + analyticsName);
      }}>Condiciones del servicio</a> y
      la <a href="https://eliteclassroom.com/politicas-de-privacidad/" onClick={(e) => {
        trackClick('btn_terminos' + analyticsName);
      }}>Política de privacidad</a> de 
      Elite Classroom
    </p>
  )
}

export default AgostoAcceptConditions
