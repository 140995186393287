import AgostoLoader from "./AgostoLoader";
import { PaymentsResponse } from "../model/PaymentsResponse";
import { ERROR_CONST } from "../api/EliteFetch";
import { CONNECTION_ERROR_MESSAGE } from "../util/Messages";
import { formatCents } from "../util/CurrencyUtil";

const MyPayments = (props : {
  paymentsResponse : PaymentsResponse | null | "ERROR"
}) => {
  if (props.paymentsResponse == null) { // loading
    return (
      <AgostoLoader></AgostoLoader>
    );
  }

  if (props.paymentsResponse === ERROR_CONST) {
    return <div>{CONNECTION_ERROR_MESSAGE}</div>
  }

  // const comp = (
  //   <p>
  //     oli
  //   </p>
  // );
  // usarlo con {comp}

  const paymentEntries = Object.entries(props.paymentsResponse.payments);

  return (
    <div>
      <section className="courses section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <div className="section-icon wow zoomIn" data-wow-delay=".4s">
                  <i className="lni lni-graduation" />
                </div>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">Historial de mis pagos</h2>
              </div>
            </div>
          </div>
          <div className="single-head">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  {
                  paymentEntries.length === 0 ? 
                    <div className="text-center">No tienes pagos aún 🥺</div>
                  :
                  paymentEntries.map(
                    ([monthKey, paymentsUnk]) => {
                      const payments = paymentsUnk;
                      const accordionId = "flush-heading" + monthKey;
                      const accordionCollapseId = "flush-collapse" + monthKey;
                      return (
                        <div key={monthKey} className="accordion-item">
                          <h2 className="accordion-header" id={accordionId}>
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#" + accordionCollapseId} aria-expanded="false" aria-controls={accordionCollapseId}>
                              {monthKey}
                            </button>
                          </h2>
                          <div id={accordionCollapseId} className="accordion-collapse collapse" aria-labelledby={accordionId} data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                              <div className="container">
                                <div className="row justify-content-between">
                                  <div className="col-12">
                                    <p>Inversión total: {formatCents(payments.reduce((ac, pmt) => ac + (pmt.metadata.amount_paid_in_cents ?? 0), 0))}</p><br />
                                    {/* <p>Total: S/ --</p> */}
                                  </div>
                                  {/* <div className="col-4">
                                    <div className="alert alert-primary d-flex align-items-center" role="alert">
                                      <svg className="bi flex-shrink-0 me-2" width={24} height={24} role="img" aria-label="Info:"><use xlinkHref="#info-fill" /></svg>
                                      <div>
                                        1 clase = 45 minutos
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                              <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
                                <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </symbol>
                              </svg>
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">Fecha</th>
                                    {/* <th scope="col">Horario</th> */}
                                    <th scope="col">Saldo añadido</th>
                                    <th scope="col">Profesor</th>
                                    <th scope="col">Inversión</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {payments.map((payment) => (
                                    <tr key={payment.time}>
                                      <th scope="row">{new Date(payment.time * 1000).toLocaleString()}</th>
                                      <td>{formatCents(payment.change)}</td>
                                      <td>{payment.tutor_name}</td>
                                      <td>{formatCents(payment.metadata.amount_paid_in_cents ?? 0)}</td>
                                      {/* <th scope="row">Mie 27/01/2021</th>
                                <td>10:45 AM - 11:45 AM</td>
                                <td>60 minutos</td>
                                <td>Noah</td>
                                <td>S/ 50</td> */}
                                    </tr>
                                  ))}

                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default MyPayments
