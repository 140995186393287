import React from 'react'
import { ERROR_CONST, fetchAplicarCupon } from '../api/EliteFetch';
import { ScheduleSelection } from '../model/ScheduleSelection';
import { Tutor } from '../model/Tutor';
import { BookingProcessState } from '../pages/PageBooking'
import { trackClick } from '../util/AnalyticsUtil';
import { convert_date_standard_to_Peruvian, convert_minutes_to_time, DEFAULT_MIN_LESSON_DURATION, format_minutes_to_lesson_hours } from '../util/ConversionUtil';
import { CONNECTION_ERROR_MESSAGE } from '../util/Messages';
import AgostoLoader from './AgostoLoader';
import '../style/MdbWrapper.scss';
import '../style/TarjetaPaso3.scss';
import { sleep } from '../utils';
import { setMaterialEffectMishell } from '../util/DomUtils';
import { FullUserDataResponse } from '../model/FullUserProfile';
import { getNameByMateriaId, getNameByNivelId } from '../util/CoursesConstants';
import { openWhatsapp } from '../util/OpenWhatsapp';

export function mostrarerrorcupon(coupon_error: string) {

  let s = coupon_error;
  if (coupon_error == "CUPON_NO_ENCONTRADO") {
    s = "Lo siento 😞, el cupón no existe.";
  } else if (coupon_error == "CUPON_EXPIRADO") {
    s = "Lo siento 😞, este cupón llegó a su fecha limite.";
  } else if (coupon_error == "CUPON_NO_SE_PUEDE_APLICAR_CON_ESTE_MONTO") {
    s = "Lo siento 😢, no es posible usar este cupón con este monto.";
  } else if (coupon_error == "CUPON_NO_PERTENECE_A_ESTE_USUARIO") {
    s = "Lo siento 🥺, este cupón no pertenece a este usuario.";
  } else if (coupon_error == "CUPON_YA_USADO") {
    s = "Lo siento 😢, este cupón ya fue usado.";
  } else if (coupon_error == "CUPON_YA_APLICADO") {
    s = "Lo siento 🥺, este codigo promocional ya fue aplicado.";
  } else if (coupon_error == "PLAN_COMODO") {
    s = "Lo siento 😞, escoge otro plan para poder usar el cupón.";
  } else if (coupon_error == "CUPON_ALCANZO_LIMITE_DE_USUARIOS") {
    s = "Lo siento 😢, se llegó al limite de usuarios para usar este cupón.";
  }

  alert(s);

}


// en minutos
function getDuration(scheduleSelection: ScheduleSelection[]) {
  return scheduleSelection.map((a) => a.end_time - a.start_time).reduce((a, b) => a + b, 0);
}

export const getSchedulesIntervalDurationSum = getDuration;

export function flujo_amount_to_pay(
  balanceInMinutes: number, 
  balanceInCents: number, 
  bookingsDurationInMinutes: number,
  chosenTutor: Tutor | undefined | null,
  schedulesSelected: ScheduleSelection[], // may be empty
  ) {
  
  const first_booking_duration = schedulesSelected.length > 0 ? 
    schedulesSelected[0].end_time - schedulesSelected[0].start_time : 0;

  // const previous_balance_cents = 500;
  const previous_balance_cents = balanceInCents;

  // docs here: https://docs.google.com/document/d/1gZaKHBrJpQbyop8pXWLDe5-dj5EU2EkrbTByQQAlQGA/edit

  const lesson_duration = DEFAULT_MIN_LESSON_DURATION;
  const pricePerClass = chosenTutor?.price_per_lesson_hour_cents ?? 0;


  let pc_desc_minutes = 0;
  let usa_pc_desc = false;
  if (!(chosenTutor?.pc_usada ?? true)) {
      usa_pc_desc = true; // is returned from this method
      pc_desc_minutes = first_booking_duration * (chosenTutor?.pc_desc ?? 0) / 100;
  }
  const $bookingsDurationInMinutes_after_pc_desc = bookingsDurationInMinutes - pc_desc_minutes;

  const minutesDiscount = Math.min(balanceInMinutes, $bookingsDurationInMinutes_after_pc_desc);
  const previous_balance_minutes_discount_in_cents = Math.round((pricePerClass * minutesDiscount) / lesson_duration); // in PEN cents (from minutes)
  
  const durations_in_cents = Math.round((pricePerClass * $bookingsDurationInMinutes_after_pc_desc) / lesson_duration);
  const centsAfterMinutesBalanceDiscount = durations_in_cents - previous_balance_minutes_discount_in_cents;

  const previous_balance_cents_discount = Math.min(centsAfterMinutesBalanceDiscount, previous_balance_cents);

  const centsAfterCentsBalanceDiscount = centsAfterMinutesBalanceDiscount - previous_balance_cents_discount;

  let big_payment_discount_perc = 0; // in cents


  /*if (centsAfterCentsBalanceDiscount >= 15 * pricePerClass) {
    big_payment_discount_perc = 15;
  } else*/ if (centsAfterCentsBalanceDiscount >= 200 * 100) {
    big_payment_discount_perc = 10;
  } else if (centsAfterCentsBalanceDiscount >= 100 * 100) {
    big_payment_discount_perc = 5;
  }

  const big_payment_discount = Math.round((centsAfterCentsBalanceDiscount * big_payment_discount_perc) / 100);

  const total = centsAfterCentsBalanceDiscount - big_payment_discount; // in PEN cents

  // frontend additional helpers:
  const pc_desc_minutes_to_cents = Math.round(pc_desc_minutes * pricePerClass / lesson_duration);
  const subtotal = durations_in_cents + pc_desc_minutes_to_cents;

  return {
    subtotal,
    previous_balance_minutes_discount_in_cents,
    previous_balance_cents_discount,
    big_payment_discount,

    total,

    pc_desc_minutes,
    pc_desc_minutes_to_cents,
    usa_pc_desc,
  };
}

function onclickOtroMetodoPago(e: any, tutor: Tutor, scheduleSelection: ScheduleSelection[]) {
  // to prevent the a tag from firing (reloading the website)
  e.preventDefault();

  trackClick("btn_otro_metodo_pago");

  const tutor_name = tutor.name;
  /* Para luego:
Fecha, horario y duración:
...  2 
... 3 
  */;

  // hallando la suma de duraciones de los intervalos escogidos por el usuario
  const duration = getDuration(scheduleSelection); // en minutos

  const text = `Hola, estoy intentando reservar con el(la) profesor@ ${tutor_name}: 
Con una duración total de: ${duration} minutos. 

Deseo probar otro método de pago.    
`;

  openWhatsapp(text);
}

const AgostoPayment = (props: {
  state: BookingProcessState,
  handleCouponApplication: (a: string, b: number) => void,
}) => {
  const { scheduleSelection, fullUserDataResponse,
    chosenTutor,
    moneyToDiscountForAppliedCoupon,
    appliedCoupon,

  } = props.state;


  if (fullUserDataResponse == null) {
    return <AgostoLoader></AgostoLoader>
  }

  if (fullUserDataResponse === ERROR_CONST) {
    return <div>{CONNECTION_ERROR_MESSAGE}</div>
  }

  const schedulesComponent = scheduleSelection?.map((interval) => {
    return (
      <div>
        {
          convert_date_standard_to_Peruvian(interval.pick_date) +
          " " + convert_minutes_to_time(interval.start_time) +
          "-" + convert_minutes_to_time(interval.end_time)
        }
        <br></br>
      </div>
    )
  });

  const paymentFooter = (
    <div>
      <p className="mb-0 fw-bold" style={{ textAlign: 'center' }}>No hay métodos de pago online disponibles por el momento.</p>
      <br></br>
      <p className="mb-0 fw-bold" style={{ textAlign: 'center' }}><a href="." onClick={
        (e) => {
          return onclickOtroMetodoPago(e, chosenTutor as Tutor, scheduleSelection as ScheduleSelection[]);
        }
      }>Haz clic aquí para coordinar el pago mediante nuestro Whatsapp 😊</a></p>
    </div>
  );


  // STARTS calcularYMostrarPreciosYDescuentosDelNuevoFlujoV2

  // Si la sgte variable no es nula, entonces la variable 
  // moneyToDiscountForAppliedCoupon tendrá el valor a descontar en soles
  const thereIsAnAppliedCoupon = appliedCoupon != null;


  // https://docs.google.com/document/d/1gZaKHBrJpQbyop8pXWLDe5-dj5EU2EkrbTByQQAlQGA/edit
  const N = fullUserDataResponse.data?.globalBalance ?? 0;
  const cb = fullUserDataResponse.data?.centsBalance ?? 0;
  const S = getDuration(scheduleSelection!);

  const montos = flujo_amount_to_pay(N, cb, S, chosenTutor, scheduleSelection!);
  const cupon = moneyToDiscountForAppliedCoupon; // en PEN

  // la sgte variable sirve para enviar al backend el monto a cobrar (por cuestiones de validación)
  // y para decidir si hay un form de tarjeta que validar o no
  const toChargeInCents = montos.total - cupon * 100; // el nuevo total a cobrarse


  const subtotal = montos.subtotal;
  const big_payment_discount = montos.big_payment_discount;
  const previous_balance_cents_discount = montos.previous_balance_cents_discount;
  const previous_balance_minutes_discount_in_cents = montos.previous_balance_minutes_discount_in_cents;


  // rellenarDuracionDeReservasEnElPaso5:
  // hallando la suma de duraciones de los intervalos escogidos por el usuario
  const scheduleSelectionDuration = S;

  // esto era solo para el cupon de minutos, desactivado por ahora:
  // jQuery('.elite_coupon_addition').html('');

  // la siguiente parte la dejé solo como recordatorio de que esta es la
  // lista de afectaciones debido al cupon:
  //   if (thereIsAnAppliedCoupon) {
  //     // cambiando el texto del botón de aplicar
  //     jQuery("#btn_aplicar_cupon").html("Aplicado (fíjate el descuento aplicado)");

  //     // desactivando el botón aplicar
  //     jQuery("#btn_aplicar_cupon").attr("disabled", true);

  //     // bloqueando la edicion de cupon
  //     jQuery('[name="coupon_code"]').attr("readonly", true);


  //     jQuery('.coupon-price-discount-container').show();
  //     jQuery('.coupon-price-discount').show();
  //     //jQuery('.subtotal-container').show();

  //  } else {
  //     // the opposite of those actions
  //  }

  // ENDS calcularYMostrarPreciosYDescuentosDelNuevoFlujoV2



  // card stuff:

  let cardPart = <div></div>;
  if (fullUserDataResponse.data?.cardLast4Digits == null) {
    // setting js for material inputs
    sleep(100).then(() => {
      setMaterialEffectMishell();
    });
    cardPart = (
      <div className="mish-elite-card-styles">
        <div className="gutut twbs">
          <form method="post">
            <fieldset id="elite_card_form">
              <div className="form-card">
                <div className="sep-mis">
                  <label className="pay" id="user">
                    <span>Nombre y apellido del titular</span>
                    {/* Keys help React identify which items have changed */}
                    <input key="holdername" className="mish" type="text" name="holdername" required />
                    <i id="user-pay" className="fa fa-user"></i>
                  </label>
                </div>
                <div className="sep-mis">
                  <label className="pay style_pay" id="tarjeta">
                    <span> Número de la Tarjeta </span>
                    <input key="cardno" inputMode="numeric" pattern="[0-9\s]{13,19}" className="mish" type="text" name="cardno" required />
                    <i id="tarjeta-pay" className="fa fa-credit-card"></i>
                  </label>
                </div>
                <div className="sep-mis">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <p className="pay">Fecha de caducidad</p>
                      </div>
                      <div className="row mt-2">
                        <div className="col-6">
                          <select className="list-dt form-select col-6" id="month" name="expmonth">
                            <option selected>Mes</option>
                            <option>January</option>
                            <option>February</option>
                            <option>March</option>
                            <option>April</option>
                            <option>May</option>
                            <option>June</option>
                            <option>July</option>
                            <option>August</option>
                            <option>September</option>
                            <option>October</option>
                            <option>November</option>
                            <option>December</option>
                          </select>

                        </div>
                        <div className="col-6">
                          <select className="list-dt form-select col-6" id="year" name="expyear">
                            <option selected>Año</option>
                            <option>2021</option>
                            <option>2022</option>
                            <option>2023</option>
                            <option>2024</option>
                            <option>2025</option>
                            <option>2026</option>
                            <option>2027</option>
                            <option>2028</option>
                            <option>2029</option>
                          </select>

                        </div>
                      </div>

                    </div>
                    <div className="col-12 col-md-6 sep-mis">
                      <label className="pay" id="lock">
                        <span>CVV</span>
                        <input className="mish" type="password" name="cvcpwd" autoComplete="off" required />
                        <i id="lock-pay" className="fa fa-lock"></i>
                      </label>
                    </div>
                  </div>
                </div>
              </div><br />
              <div className="mt-3" />

              <div className="form-check form-switch fs-6">
                <input 
                defaultChecked={true}
                className="form-check-input" 
                type="checkbox" 
                id="flexSwitchCheckChecked" name="remember_card"></input>
                {/* mish-checkbox is to delete the border */}
                <label className="mish-checkbox form-check-label pt-0" htmlFor="flexSwitchCheckChecked">Recordar tarjeta
                  <p className="" style={{ textAlign: 'left', fontSize: '0.9em' }}>Podrás dejar de recordar tu tarjeta cuando quieras.</p>
                </label>
              </div>

              {/* <label className="mish-checkbox fs-6">
                <input type="checkbox" name="remember_card" id="cbox1" /> 
                
              </label> */}

              {paymentFooter}
            </fieldset>
          </form><br />


        </div>
      </div>);
  } else {
    cardPart = (

      <div className="mish-elite-card-styles">
        <div className="gutut twbs">
          <form>
            <fieldset id="formcarddummy">
              <div className="form-card">
                <div className="sep-mis">
                  <label className="pay style_pay" id="tarjeta">
                    <span className="top focus"> Número de la Tarjeta </span>
                    <input key="cardno" className="mish" type="text" name="cardno" value={"************" + fullUserDataResponse.data?.cardLast4Digits} readOnly={true} />
                    <i id="tarjeta-pay" className="fa fa-credit-card"></i>
                  </label>
                </div>
              </div><br />
              {paymentFooter}
            </fieldset>
          </form>

        </div>
      </div>
    );
  }


  function handleAplicarCupon(e: React.MouseEvent) {
    e.preventDefault();

    trackClick("btn_aplicar_cupon");

    const coupon_code = (document.querySelector('[name="coupon_code"]') as any)?.value ?? "";
    const tutor_id = chosenTutor?.id ?? 0;

    // hallando el total previo descuentos por cupón
    const N = (fullUserDataResponse as FullUserDataResponse)?.data?.globalBalance ?? 0;
    const S = getDuration(scheduleSelection as ScheduleSelection[]);
    const cb = (fullUserDataResponse as FullUserDataResponse)?.data?.centsBalance ?? 0;
    const montos = flujo_amount_to_pay(N, cb, S, chosenTutor, scheduleSelection!);
    const amount_to_charge_before_coupon = montos.total; // in cents

    fetchAplicarCupon(coupon_code, tutor_id, amount_to_charge_before_coupon).then((response) => {
      if (response === ERROR_CONST) {
        alert(CONNECTION_ERROR_MESSAGE);
        return;
      }

      if (response.status === "OK") {
        const minutesToAdd = parseInt(response.minutes ?? "");
        const moneyToDiscount = parseFloat(response.money ?? ""); // en soles

        // guardando el codigo del cupon aplicado que sera enviado con la informacion del pag        
        if (isNaN(minutesToAdd)) { // significa que es un cupón de dinero
          props.handleCouponApplication(coupon_code, moneyToDiscount);
          // calcularYMostrarPreciosYDescuentosDelNuevoFlujoV2();

        } else { // es cupón de minutos

          alert("Tipo de cupón no soportado por ahora 😔, comunícate con los admins para poder canjearlo");

          // // cambiando el texto del botón de aplicar
          // jQuery("#btn_aplicar_cupon").html("Aplicado (fíjate en tu cobertura adicional)");

          // jQuery('.elite_coupon_addition').html(
          //     "➕ Adicional por cupón: "
          //      + format_minutes_to_lesson_hours(minutesToAdd, elite_booking_min_duration)
          //     + "<br>"
          //     );    
          // jQuery('.coupon-price-discount-container').hide();
          // jQuery('.coupon-price-discount').hide();
          // jQuery('.subtotal-container').hide();

          // const balanceToBeBeforeBooking = Number(jQuery("#balanceToBe").val());
          // const duration = scheduleSelection.map((a) => a['end_time'] - a['start_time']).reduce((a, b) => a + b, 0);
          // jQuery('.elite_new_balance').html("⌛ Cobertura restante con este profesor al finalizar la reserva: " 
          //     + format_minutes_to_lesson_hours(balanceToBeBeforeBooking - duration + minutesToAdd, elite_booking_min_duration));
        }

      } else {
        mostrarerrorcupon(response.status);
      }
    });
  }

  return (
    <div>
      {/* {JSON.stringify(scheduleSelection)}
      {JSON.stringify(fullUserDataResponse)} */}
      <div className="row">
        <div className="form-row col-sm-7 style-all" id="booking_final_step_left_part" style={{
          display: (toChargeInCents > 0 ? "block" : "none")
        }}>
          {/* Esta parte se elimino, aqui estuvo los datos personales del cliente( nombre, telefono, correo, dni)*/}
          {/* <div className="border-bottom">
            <h6>Formas de pago</h6>
          </div> */}
          {paymentFooter}
        </div>
        <div className="form-row col-sm-7 style-all" id="booking_final_step_left_part_with_image" style={{
          display: (toChargeInCents > 0 ? "none" : "block")
        }}>
          <img className="img-fluid" src="https://www.frba.utn.edu.ar/wp-content/uploads/2020/06/webimar-Lunes-22-2.jpg" />
        </div>

        <div className="col-sm-5 pl-4">
          <div className="border-bottom">
            <h6>Resumen de tu reserva</h6>
          </div>
          <div className="row border-bottom pt-4 pb-4">
            <div className="col-sm-3 mt-1">
              <img src={chosenTutor?.picture} alt="tutor-avatar" className="img-fluid rounded tutor_avatar" />
            </div>
            <div className="col-sm-8">
              <span className="tutor_name fw-bold">{chosenTutor?.name}</span>
              <div className="course-detail">
                <span className="course_name" />
                <br />
                <span className="purchase_number" />
                <span className="course_time">
                  {
                    schedulesComponent
                  }
                </span>
                <br /><br />
                <span>Nivel: <strong>{getNameByNivelId(props.state.nivelValue)}</strong></span>
                <br />
                <span>Materia: <strong>{getNameByMateriaId(props.state.materiaValue)}</strong></span>
                <br />
                <span className="elite_old_balance" />
                <br />
                <span className="elite_plan_duration" />
                <span className="elite_coupon_addition" />
                <span className="elite_booked_class_duration">{"🕒 Duración total de las reservas a realizar: "
                  + format_minutes_to_lesson_hours(scheduleSelectionDuration)}</span>
                <br />
                {/*<span class="elite_new_balance"></span>*/}
                {/* <div>🕒 Actualmente tienes <b>
                  {format_minutes_to_lesson_hours(fullUserDataResponse?.data?.globalBalance ?? 0)}
                  </b> de saldo disponible
                </div> */}
                <br />
              </div>
            </div>
            {/* <div class="col-sm-1 pt-1 " id="elite_plan_price">
											<span class="pull-right price-number"></span>
										</div> */}
          </div>
          <div id="coupon_container" className="row border-bottom pt-4 pb-4 row">
            <div className="col-md-6">
              <input type="text" className="form-control form-control-sm"
                name="coupon_code" placeholder="Código de cupón" readOnly={thereIsAnAppliedCoupon} />
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={handleAplicarCupon}
                disabled={thereIsAnAppliedCoupon}
                id="btn_aplicar_cupon">{(thereIsAnAppliedCoupon ? "Aplicado (fíjate el descuento aplicado)" : "Aplicar")}
              </button>
            </div>
          </div>
          {/* aqui se borro la parte del cupon */}
          <div id="elite_payment_amounts">
            <div className="row border-bottom pt-2 pb-2">
              <div className="subtotal-container">Subtotal <span className="pull-right price-number">{'S/ ' + (subtotal / 100).toFixed(2)}</span></div><br />
              {montos.pc_desc_minutes_to_cents === 0 ? "" : <div>Descuento por primera clase con este profesor <span className="pull-right desc-sec">{'S/ -' + (montos.pc_desc_minutes_to_cents / 100).toFixed(2)}</span></div>}
              {previous_balance_minutes_discount_in_cents === 0 ? "" : <div>Descuento por saldo disponible que tenías en minutos <span className="pull-right desc-sec">{'S/ -' + (previous_balance_minutes_discount_in_cents / 100).toFixed(2)}</span></div>}
              {previous_balance_cents_discount === 0 ? "" : <div>Descuento por saldo disponible <span className="pull-right desc-sec">{'S/ -' + (previous_balance_cents_discount / 100).toFixed(2)}</span></div>}
              {big_payment_discount === 0 ? "" : <div className="Descuento-volumen">Descuento por volumen <span className="pull-right desc-pri">{'S/ -' + (big_payment_discount / 100).toFixed(2)}</span></div>}
              {/*<label><?php echo esc_html__('IGV', 'gu-tutor') ?> <span class="pull-right tax"></span></label>*/}
              <div className="coupon-price-discount-container" style={{ display: thereIsAnAppliedCoupon ? "block" : "none" }}>
                Descuento por cupón 🎟️ <span className="pull-right coupon-price-discount">{'S/ -' + cupon.toFixed(2)}</span>
              </div>
            </div>
            <div className="pt-2 fw-bold" style={{ fontSize: '1.3em', color: 'black' }}>Total<span className="pull-right fw-bold total">{'S/ ' + (toChargeInCents / 100).toFixed(2)}</span></div><br />
            <div className="row  pt-2 pb-2">
              <span className="recordatorio">
                <b>Recuerda: </b>
                <br />
                <span className="text">Si tienes saldo disponible, éste se te descontará progresivamente cuando confirmes nuevas reservas.</span>
                <br />
                {/* <span className="text">Tu banco te podría cobrar una comisión.</span>
                <br /> */}
                <br />
                {/* <span className="style-col"><strong>¡Si reservas 6 clases pagadas solo se te cobrará 5 o si reservas 10 solo se te cobrará por 8! 🤩</strong></span><br /> */}
              </span></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgostoPayment
