import React from 'react'
import { Link } from 'react-router-dom'
import { confirmacionDeReservaPathRoute, reservarPathRoute } from '../App'
import { loadTutorResponseExample } from '../util/DummyData'

const ProvisionalNavigator = () => {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/mis-pagos">Mis pagos</Link>
        </li>
        <li>
          <Link to="/testing-home">Testing</Link>
        </li>
        <li>
          <Link to="/testing-bienvenida">/testing-bienvenida</Link>
        </li>
        <li>
          <Link to="/monedero">monedero</Link>
        </li>
        <li><Link to="/reservar">/reservar</Link></li>
        <li><Link to="/mis-reservas">/mis-reservas</Link></li>
        <li><Link to={reservarPathRoute + "/3020"}>{reservarPathRoute + " de un profesor"}</Link></li>
        <li><Link
          to={{
            pathname: confirmacionDeReservaPathRoute,
            state: {
              tutor: loadTutorResponseExample[0],
              bookings : [],
            }
          }}
        >{"booking confirm example"}</Link></li>
      </ul>
    </nav>
  )
}

export default ProvisionalNavigator
