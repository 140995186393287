// Note: only put old (wordpress) links in here:
// For new (react) navigation you should check the routes in app.tsx

export const WORDPRESS_BASE_URL = "https://eliteclassroom.com";
export const VIDEOCONFERENCE_BASE_URL = "https://meet.jit.si/ec-";
// export const WORDPRESS_BASE_URL = window.location.origin;

export function getPerfilProfesorUrl() {
  return window.location.origin + "/perfil-profesor";
}
export function getPublicTutorProfileUrl(tutorId: number) {
  return window.location.origin + "/tutor?id=" + tutorId;
}