import React, { useState } from 'react'
import { eliteLogout, ERROR_CONST } from '../api/EliteFetch';
import { useMuiEasySnackbar } from "mui-easy-snackbar";
import { isProductionWebsite } from '../util/DevEnvUtil';
import { misReservasPathRoute } from '../App';
import { Link } from 'react-router-dom';

const Footer = () => {

  const snackbar = useMuiEasySnackbar();
  const [logoutLoading, setlogoutLoading] = useState(false);

  async function handleLogout() {
    setlogoutLoading(true);
    const r = await eliteLogout();
    setlogoutLoading(false);
    if (r !== ERROR_CONST) {
      snackbar.showSuccess("Sesión cerrada, vuelve pronto!");
      // window.location.href = '.';
    }
  }

  return (
    <footer className="footer style2">
      {/* Start Middle Top */}
      <div className="footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">
              {/* Single Widget */}
              <div className="f-about single-footer">
                <div className="logo">
                  <a href="index.html"><img src="https://storage.googleapis.com/elite-classroom/logo-final-sticker-1.png" alt="Logo" /></a>
                </div>
                <div className="footer-social">
                  <ul>
                    <li><a href="https://www.facebook.com/eliteclassroom"><i className="lni lni-facebook-original" /></a>
                    </li>
                    <li><a href="https://www.instagram.com/eliteclassroom.official/?igshid=f8519sic38z6"><i className="lni lni-instagram-original" /></a></li>
                    <li><a href="https://www.youtube.com/channel/UCfU9JvCLs8QGFMcgtjR35wA/featured"><i className="lni lni-youtube" /></a></li>
                    <li><a href={window.location.origin}><i className="lni lni-google" /></a></li>
                  </ul>
                </div>
              </div>
              {/* End Single Widget */}
            </div>
            <div className="col-lg-8 col-md-8 col-12">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                  {/* Single Widget */}
                  <div className="single-footer sm-custom-border f-link">
                    <h3>Compañía</h3>
                    <ul>
                      <li><a href="https://eliteclassroom.com/sobre-nosotros/">Sobre nosotros</a></li>
                      <li><a href="https://eliteclassroom.com/contactanos/">Contáctanos</a></li>
                      <li><a href="https://eliteclassroom.com/preguntas-frecuentes/">Preguntas frecuentes</a></li>
                      <li><a href="https://eliteclassroom.com/terminos-y-condiciones/">Términos y condiciones</a></li>
                      <li><a href="https://eliteclassroom.com/politicas-de-privacidad/">Políticas de privacidad</a></li>
                    </ul>
                  </div>
                  {/* End Single Widget */}
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  {/* Single Widget */}
                  <div className="single-footer sm-custom-border f-link">
                    <h3>Recursos</h3>
                    <ul>
                      <li><a href="https://eliteclassroom.com/ensena-en-elite-classroom/">Enseña en Elite Classroom</a>
                      </li>
                      {/*<li>
                        <a href="https://eliteclassroom.com/blog-de-temas">Blog de temas</a>
                      </li>*/}
                      {/*<li>
                        <a href="https://eliteclassroom.com/articulos/">Artículos</a>
                      </li>*/}
                      <li><Link to={misReservasPathRoute}>📜 Historial de clases</Link></li>
                      {!isProductionWebsite && <li><a href="." onClick={(e) => {
                        e.preventDefault();
                        if (!logoutLoading) {
                          handleLogout();
                        }
                        return false;
                      }}>{logoutLoading ? "Cerrando sesión..." : "Cerrar sesión"}</a></li>}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*/ End Footer Middle */}
    </footer>
  );
}

export default Footer
