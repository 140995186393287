import React from 'react'
import AgostoLoader from '../components/AgostoLoader'
import Header from '../components/Header'
import ProvisionalNavigator from '../components/ProvisionalNavigator'
import TutorReviews from '../components/tutor/TutorReviews'

const PageTestingHome = () => {
  return (
    <div>
      {/* <Header></Header> */}
      <ProvisionalNavigator></ProvisionalNavigator>
      {/* <TutorReviews></TutorReviews> */}
    </div>
  )
}

export default PageTestingHome
