import React from 'react'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import PageBooking from './PageBooking';

const PageBookingRouteHandler = () => {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        {/* when a tutorId has been set */}
        <Route path={`${match.path}/:tutorId`}>
          <PageBooking></PageBooking>
        </Route>

        {/* when no param is set in the URL: */}
        <Route path={match.path}>
          <PageBooking></PageBooking>
        </Route>
      </Switch>
    </div>
  )
}

export default PageBookingRouteHandler
