// with help from: https://bareynol.github.io/mui-theme-creator/?firstName=&lastName=&email=sfds&password=#TextField
// and https://material.io/inline-tools/color/

import { ThemeOptions } from "@mui/material";

const whatsappForeground = '#25d334';

export const themeOptions: ThemeOptions = {
  palette: {
    // type: 'light',
    primary: {
      main: '#0022e6',
    },
    secondary: {
      main: '#aa00ff',
    },
    background: {
      default: '#ffffff',
    },
    success: {
      // main: '#1fdc28',
      main: whatsappForeground, // por ahora tiene el color de whatsapp
    },
    error: {
      main: '#ea1406',
    },
  },
};