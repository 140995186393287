import React, { useEffect } from 'react'
import AgostoHeader from '../components/AgostoHeader';

const PageMonedero = () => {
  useEffect(() => {
    document.title = "Mi monedero | Elite Classroom";
  }, []);

  return (
    <div>
      <AgostoHeader title="Mi monedero"></AgostoHeader>
      <section className="monedero section">
        <div className="container">
          <div className="single-head">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="card">
                  <div className="card-header">
                    <div className="row g-3">
                      <div className="col">
                        <p className="text-decoration-underline fs-4 ">Mi monedero</p>
                      </div>
                      {/*<div className="col">
                        <a href="#" className="btn btn-primary">Comprar saldo disponible</a>
                      </div>  */}
                    </div>
                  </div>
                  <div className="card-body">
                    <p className="card-title">Saldo Disponible </p>
                    <p className="fs-1">S/ 0.00 </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="monedero-list section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <div className="section-icon wow zoomIn" data-wow-delay=".4s">
                  <i className="lni lni-revenue" />
                </div>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">Mi historial de movimientos</h2>
              </div>
            </div>
          </div>
          <div className="single-head">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="responsive-table">
                  <table className="table table-bordered text-center">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Monto</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td >1</td>
                        <td data-label="Fecha">2021-01-11 9:00</td>
                        <td data-label="Descripción">Recarga</td>
                        <td data-label="Monto">S/ 120.00</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td data-label="Fecha">2021-01-29 9:00</td>
                        <td data-label="Descripción">Clase del 10/8/2021 con el profesor Javier Gonzales</td>
                        <td data-label="Monto">S/ -45.00</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td data-label="Fecha">2021-02-22 9:00</td>
                        <td data-label="Descripción">Bono de referido</td>
                        <td data-label="Monto">S/ 20.00t</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td data-label="Fecha">2021-01-22 9:00</td>
                        <td data-label="Descripción">Promoción por ser nuevo cliente</td>
                        <td data-label="Monto">S/ 25.00</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PageMonedero
