import React from 'react'
import { ERROR_CONST } from '../../api/EliteFetch';
import { PaymentsResponseTutor } from '../../model/TutorPaymentsResponse';
import { CONNECTION_ERROR_MESSAGE } from '../../util/Messages';
import AgostoLoader from '../AgostoLoader';
import '../../style/TableResponsive.scss';

const TutorPayments = (props: {
  paymentsTutorResponse: PaymentsResponseTutor | null | "ERROR"
}) => {

  if (props.paymentsTutorResponse == null) { // loading
    return (
      <AgostoLoader></AgostoLoader>
    );
  }

  if (props.paymentsTutorResponse === ERROR_CONST) {
    return <div>{CONNECTION_ERROR_MESSAGE}</div>
  }

  const paymentTutorEntries = Object.entries(props.paymentsTutorResponse.paymentsAll);
  return (
    <div>
      <section className="courses section">
        <div className="container">
          <div className="single-head">
            <div className="row">
              <div className="">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                  {
                    paymentTutorEntries.length === 0 ?
                      <div className="text-center">No tienes pagos aún 🥺</div>
                      :
                      paymentTutorEntries.map(
                        ([monthKey, paymentsUnk]) => {
                          const payments = paymentsUnk;
                          const accordionId = "flush-heading" + monthKey;
                          const accordionCollapseId = "flush-collapse" + monthKey;
                          return (
                            <div key={monthKey} className="accordion-item">
                              <h2 className="accordion-header" id={accordionId}>
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#" + accordionCollapseId} aria-expanded="false" aria-controls={accordionCollapseId}>
                                  {monthKey}
                                </button>
                              </h2>
                              <div id={accordionCollapseId} className="accordion-collapse collapse" aria-labelledby={accordionId} data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                  <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
                                    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </symbol>
                                  </svg>
                                  <div className="Table-responsive">
                                    <div className="table-responsive respons-table">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col">Fecha</th>
                                            <th scope="col">Horario</th>
                                            <th scope="col">Nombre del Alumno</th>
                                            <th scope="col">Nombre del Padre</th>
                                            <th scope="col">Enviar Mensaje</th>
                                            <th scope="col">Duración</th>
                                            <th scope="col">Pago</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {payments.dataPayment.map((paymenTData) => (
                                            <tr>
                                              <th scope="row">{paymenTData.fecha}</th>
                                              <td data-label="Horario">{paymenTData.horario}</td>
                                              <td data-label="Nombre del alumno">{paymenTData.name_son}</td>
                                              <td data-label="Nombre del padre">{paymenTData.name_parent}</td>
                                              <td data-label="Enviar mensaje al alumno"><a href={paymenTData.mensaje}>✉️</a></td>
                                              <td data-label="Duración">{paymenTData.duration} minutos</td>
                                              <td data-label="Pago">S/ {paymenTData.pago}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                        <tbody>
                                          <tr>
                                            <th>Total</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th data-label="Total">S/ {payments.totalStr}</th>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}


export default TutorPayments