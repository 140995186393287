import React, { useEffect } from 'react'
import { useState } from 'react';
import { trackClick } from '../util/AnalyticsUtil';
import { removeClasses } from '../util/DomUtils';

const PageMultistepsTest = () => {
  const [state, setState] = useState(
    {
      stepNumber: 0,
    }
  );

  // runs when this component loads:
  useEffect(() => {
    // initializations
    changeStepInStepsBarAndPanel(0);
  }, []);

  // functions:
  const getActiveStep = (elem: Element) => {
    const stepsBtns = document.querySelectorAll(`.multisteps-form__progress-btn`);
    return Array.from(stepsBtns).indexOf(elem);
  };

  function changeStepInStepsBarAndPanel(newActiveStep: number) {
    // const setFormHeight = () => {
    //   const activePanel = getActivePanel();

    //   formHeight(activePanel);
    // };

    const setActiveStep = (activeStepNum: number) => {
      const stepsBtns = document.querySelectorAll(`.multisteps-form__progress-btn`);

      //remove active state from all the state
      removeClasses(stepsBtns, 'js-active'); // marks progress
      removeClasses(stepsBtns, 'js-active-current'); // marks current step

      //set picked items to active
      stepsBtns.forEach((elem, index) => {

        if (index <= activeStepNum) {
          elem.classList.add('js-active');
          if (index == activeStepNum) {
            elem.classList.add('js-active-current');
          }
        }

      });
    };

    //open active panel (and close unactive panels)
    const setActivePanel = (activePanelNum: number) => {
      const stepFormPanels = document.querySelectorAll('.multisteps-form__panel');

      //remove active class from all the panels
      removeClasses(stepFormPanels, 'js-active');

      //show active panel
      stepFormPanels.forEach((elem, index) => {
        if (index === activePanelNum) {

          elem.classList.add('js-active');

          // setFormHeight(elem);

        }
      });

    };

    //set all steps before clicked (and clicked too) to active
    setActiveStep(newActiveStep);

    //open active panel
    setActivePanel(newActiveStep);
    // resize_container_height();
  }

  function validateAndChangeStep(newActiveStep: number) {
    // probably some validations before next line
    changeStepInStepsBarAndPanel(newActiveStep);
    // to be able to retrieve the step number to use it in the steps bar click listener
    setState({
      stepNumber: newActiveStep,
    })
  }

  function btnNextHandler(step: number) {
    if (step === 1) { // click on Next in the schedules step
      trackClick("btn_next4");
    }
    validateAndChangeStep(step + 1);
  }

  function btnPrevHandler(step: number) {
    validateAndChangeStep(step - 1);
  }

  function stepBarClickHandler(e: React.MouseEvent) {
    //check if click target is a step button
    const eventTarget = e.target as Element;

    //get active button step number
    const activeStep = getActiveStep(eventTarget);
    
    const stepsBtnClass = 'multisteps-form__progress-btn';


    if (!eventTarget.classList.contains(`${stepsBtnClass}`)) {
      return;
    }
    validateAndChangeStep(activeStep);
  }
  // end of functions

  return (
    <div className="container">
      {/*multisteps-form*/}
      <div className="multisteps-form">
        {/*progress bar*/}
        <div className="row mt-4 multi-step">
          {/* ml-auto, me-auto (usados para centrar en bootstraps anteriores al 5) en bootstrap 5 cambian a ms-auto, me-auto (de start y end) (tested) */}
          <div className="col-12 col-lg-8 ms-auto me-auto mb-4">
            <div className="multisteps-form__progress" onClick={(e) => stepBarClickHandler(e)}>
              <button className="multisteps-form__progress-btn js-active step-2" type="button" title="Paso 1">
                <div className="handCircle"></div>
                <span className="desktop-text">Paso 1</span><span className="mobile-text">1</span>
              </button>
              <button className="multisteps-form__progress-btn step-3" type="button" title="Paso 2">
                <div className="handCircle"></div>
                <span className="desktop-text">Paso 2</span><span className="mobile-text">2</span></button>
              <button className="multisteps-form__progress-btn step-4" type="button" title="Paso 3">
                <div className="handCircle"></div>
                <span className="desktop-text">Paso 3</span><span className="mobile-text">3</span></button>
            </div>
          </div>
        </div>
        {/*form panels*/}
        <div className="row">
          <div className="col-12 m-auto">
            <form className="multisteps-form__form">
              {/*single form panel step 2*/}
              <div className="multisteps-form__panel shadow p-4 js-active rounded bg-white step-2" data-animation="scaleIn">
                <h3 className="multisteps-form__title" id="tutor-found" /><br />
                <div className="multisteps-form__content">
                  <div className="row col-12">
                    <div className="col-xs-4 col-sm-4 col-md-4" id="style-space">
                      <div className="card row" id="style-search">
                        <div>
                          <h4 style={{ margin: '10px' }}>¿Qué quieres aprender?</h4>
                        </div>
                        <div className="input-group container-fluid">
                          <select className="custom-select col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" id="john_materia" aria-label="Example select with button addon">
                            <option value="all" selected>Todas las materias</option>
                            <option value="math">Matemáticas</option>
                            <option value="physical">Física</option>
                          </select>
                        </div>
                        <br />
                        <div className="input-group container-fluid">
                          <select className="custom-select col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" id="john_nivel" aria-label="Example select with button addon">
                            <option value="all" selected>Todos los niveles</option>
                            <option value="preschool">Inicial</option>
                            <option value="primary">Primaria</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="data-tutors col-xs-7 col-sm-7 col-md-7">
                    </div>
                  </div>
                  <div className="row">
                    <div className="button-row d-flex mt-4 col-12">
                      {/*<button class="btn btn-outline-secondary js-btn-prev" type="button" onclick="scrollToTop();" title="Anterior"><?php echo esc_html__('Anterior', 'gu-tutor') ?></button>*/}
                      <button disabled={false} className="btn btn-primary ms-auto js-btn-next" type="button" title="Siguiente" onClick={() => btnNextHandler(0)}>Siguiente</button>
                    </div>
                  </div>
                </div>
              </div>
              {/*single form panel step 4*/}
              <div className="multisteps-form__panel shadow p-4 rounded bg-white step-4" data-animation="scaleIn">
                <h3 className="multisteps-form__title">Escoge al menos un horario</h3>
                <div className="multisteps-form__content">
                  <div className="form-row mt-2">
                    <div className="timetable-note mb-2">
                      <span className="square rounded available-hour " /><span className="pr-2"> Disponible</span>
                      <span className="square rounded selected-hour " /><span className="pr-2"> Reservado por ti</span>
                      <span className="square rounded border border-dark not-inva" /><span className="pr-2"> No disponible</span>
                    </div>
                    <div id="notification" className="alert alert-primary card tex-center" role="alert">
                      Invalid time!
                    </div>
                    {/* <div id="availability"></div> */}
                    <table id="table-availability" className="table">
                      <thead>
                        <tr>
                          <td className="row">
                            <div className="col-md-offset-3 col-xs-6 col-sm-4" />
                            <div className="col-11 d-flex justify-content-around">
                              <div id="previousWeek" className="btn btn-primary btn-lg margin-paso4a" data-toggle="tooltip" data-placement="top" title="Semana anterior"><i className="fa fa-chevron-left" aria-hidden="true" /></div>
                              <span className="calendar-title " id="calendarTitle">Selecciona y arrastra (este es de la página de testing) </span>
                              <span className="calendar-title-mobile" id="calendarTitle">Selecciona </span>
                              <div id="nextWeek" className="btn btn-primary btn-lg margin-paso4" data-toggle="tooltip" data-placement="top" title="Semana siguiente"><i className="fa fa-chevron-right" aria-hidden="true" /></div>
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <tbody id="availability">
                      </tbody>
                    </table>
                  </div>
                  <div className="button-row d-flex mt-4">
                    <button className="btn btn-outline-secondary js-btn-prev" type="button" title="Anterior" onClick={() => btnPrevHandler(1)}>Anterior</button>
                    <button disabled={false} className="btn btn-primary ms-auto js-btn-next" type="button" title="Siguiente" onClick={() => btnNextHandler(1)}>Siguiente</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PageMultistepsTest