import { Button, DialogActions, DialogContentText } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { ERROR_CONST, fetchCancel, fetchClasses, fetchFullUserData, PendingClass, PendingClassWithTutor } from '../api/EliteFetch';
import { homePathRoute, myTutorPublicProfile, reservarPathRoute } from '../App';
import AgostoBanner from '../components/AgostoBanner';
import AgostoHeader from '../components/AgostoHeader';
import AgostoLoader from '../components/AgostoLoader';
import Footer from '../components/Footer';
import { eliteModalTransition01 } from '../components/UiTransitions';
import { useProfile } from '../data/ProfileProvider';
import { CancelBookingResponse } from '../model/CancelResponse';
import { FullUserDataResponse } from '../model/FullUserProfile';
import { PendingClassesResponse } from '../model/PendingClassesResponse';
import { trackClick } from '../util/AnalyticsUtil';
import { getNameByMateriaId, getNameByNivelId } from '../util/CoursesConstants';
import { CONNECTION_ERROR_MESSAGE } from '../util/Messages';
import { isEmpty } from '../util/StringUtil';


interface State {
  response: PendingClassesResponse | null | "ERROR"; // null means loading
  fullUserData: FullUserDataResponse | null | "ERROR"; // null means loading
  cancelResponse: CancelBookingResponse | null | "ERROR" | "NO_REALIZADO_AUN"; // TODO: set the correct response type
  reservaAAnular: PendingClass | null;
}

function PageMyClasses() {

  const initialState: State = {
    response: null,
    fullUserData: null,
    cancelResponse: "NO_REALIZADO_AUN",
    reservaAAnular: null,
  }
  const [state, setState] = useState(initialState);
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);

  function randomRateText() {
    const ar = [
      'Deja tu calificación',
      '¿Te gustó mi clase?',
      '¡Califícame!'
    ];
    return ar[Math.floor(Math.random() * ar.length)];
  }

  async function init() {
    setState(ps => ({
      ...ps,
      response: null,
    }));
    fetchClasses().then(res => {
      setState(ps => ({
        ...ps,
        response: res,
      }));
    });
    fetchFullUserData().then(res => {
      setState(ps => ({
        ...ps,
        fullUserData: res,
      }));
    });
  }

  const ph = useProfile();
  const profile = ph.value;

  const history = useHistory();

  // runs when this component is injected in the view tree
  useEffect(() => {
    if (profile.user_id === 0) {
      // navigate to home page
      history.push(homePathRoute);
    }

    document.title = "Mis clases | Elite Classroom"; // para que cambie el nombre de la pestaña que se muestra en el navegador

    // initializations
    init();
  }, []);

  let body = null;

  if (state.response === ERROR_CONST) {
    body = <div>{CONNECTION_ERROR_MESSAGE}</div>
  } else if (state.response == null) {
    body = (<div>
      <AgostoLoader></AgostoLoader>
    </div>);
  } else {

    // temporary solution:
    /*const pendingClasses = [
      (state.response.tutores as any) as PendingClassWithTutor,      
    ];*/

    const pendingClasses = state.response.tutores;
    // const pendingClasses : PendingClassWithTutor[] = [];

    body =
      <div>

        <div className="container p-1">
          <AgostoBanner userProfile={state.fullUserData}></AgostoBanner>
          {pendingClasses.length === 0 &&
            <>
              <br></br>
              <br></br>
              <div className="text-center">
                No has reservado clases aún
                <br></br>
                <Link className='fw-bold' to={homePathRoute} onClick={(e) => {
                  trackClick('btn_no_classes_yet');
                }}>
                  Click aquí para buscar un profesor 😊
                </Link>
              </div>
              <br></br>
              <br></br>
            </>}
          {pendingClasses.map((tutor_data) => {

            // en typescript y javascript, respecto a variables:
            // var: casi no usarlo
            // let: cuando quieres que tu variable pueda ser reasignable (cambiarle de valor)
            // const: cuando quieres un alias de algo, que tu variable sea inmutable (no puede cambiarle de valor)

            // if (true) {
            //   let e = 23;
            //   const x = 12;
            // }
            // console.log(e); // da error
            // console.log(x); // da error

            return (
              <div className="row d-flex justify-content-center align-items-center pt-4 px-4 pb-4" style={{
                border: '1px solid #EFEBEA', fontFamily: 'sans-serif', fontSize: '16'
              }}>
                <div className="" style={{
                  width: 280,
                }}>
                  <h5 className='text-center text-lg-start'>{tutor_data.tutor_name}</h5>
                  <br />
                  <div className="row justify-content-center ">
                    <img src={tutor_data.tutor_picture} className="" style={{
                      width: 250,
                      height: 250,
                      borderRadius: 15,
                      objectFit: 'cover',
                      cursor: 'pointer',
                    }} 
                    onClick={(e) => {
                      trackClick('tp_' + tutor_data.tutorId);
                      history.push(myTutorPublicProfile + '/' + tutor_data.tutorId);
                    }}
                    alt="..." />
                  </div>
                  {isEmpty(tutor_data.link_quelify) ? <div></div> :
                    <div className="mx-auto">
                      <p className="pnormal1 text-center">👉<a href={tutor_data.link_quelify} style={{ color: '#af21ac', fontWeight: 780 }}>{randomRateText()}</a>⭐</p>
                    </div>
                  }
                </div>
                <div className="col-sm-8">
                  {tutor_data.classes.length === 0 ? <div>No hay clases reservadas 😯</div> :
                    <div className="row align-items-center" >
                      <div className="table-responsive responsive-table">
                        <table className="table table-bordered text-left">
                          <thead className="table-light">
                            <tr>
                              <th scope="col">Fecha</th>
                              <th scope="col">Horario</th>
                              <th scope="col">Curso</th>
                              <th scope="col">Nivel</th>
                              <th scope="col">Link a la videoconferencia</th>
                              {(tutor_data.tutorId === "" ? <></> : <th scope="col">Enviar mensaje</th>)}
                              {(tutor_data.special === "YES" ? <></> : <th scope="col">Anular reserva</th>)}
                            </tr>
                          </thead>
                          <tbody>
                            {tutor_data.classes.map((pendingClass) => {
                              return (
                                <tr>
                                  <td scope="row">{pendingClass.fecha}</td>
                                  <td data-aria-label="Horario">{pendingClass.horario}</td>
                                  {/*traducir*/}
                                  <td data-aria-label="Curso">{getNameByMateriaId(pendingClass.materia)}</td>
                                  <td data-aria-label="Nivel">{getNameByNivelId(pendingClass.nivel)}</td>
                                  <td data-aria-label="Link"><a href={pendingClass.link}>LINK</a></td>
                                  {(tutor_data.tutorId === "" ? <></> : <td data-aria-label="Mensaje"><a href={pendingClass.mensaje}>✉️</a></td>)}
                                  {(tutor_data.special === "YES" ? <></> : <td data-aria-label="Anular" onClick={() => {
                                    setCancelModalIsOpen(true);
                                    setState(ps => ({
                                      ...ps,
                                      reservaAAnular: pendingClass,
                                    }));
                                  }}>❌</td>)}
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                  <div>
                    {tutor_data.special === "YES" ?
                      // we need an "a" tag to be able to route correctly in this case
                      <a href={getTutorBookingRoute(tutor_data.tutorId, tutor_data.special)} tabIndex={1} role="button" className="mt-5"
                        onClick={(e) => {
                          trackClick("btn_mset_perfil_" + tutor_data.tutorId);
                        }}
                      >Seguir reservando </a> :
                      <Link to={getTutorBookingRoute(tutor_data.tutorId, tutor_data.special)} tabIndex={1} role="button" className="mt-5"
                        onClick={(e) => {
                          trackClick("btn_met_perfil_" + tutor_data.tutorId);
                        }}
                      >Seguir reservando </Link>
                    }

                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
  }


  return (
    <div>
      <AgostoHeader title="Mis Clases"></AgostoHeader>
      <br />
      {body}
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={cancelModalIsOpen}
        TransitionComponent={eliteModalTransition01}
        onClose={() => {
          setCancelModalIsOpen(false);
        }}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Seguro que deseas anular esta reserva?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCancelModalIsOpen(false);
            }}> NO
          </Button>
          <Button
            disabled={state.cancelResponse === null}
            onClick={async () => {
              setState(ps => ({
                ...ps,
                cancelResponse: null,
              }));
              // TODO: await ajaxResponse
              const r = await fetchCancel(state.reservaAAnular?.unique_id ?? "");

              //await sleep(3000); // TODO: quitar esto
              setState(ps => ({
                ...ps,
                cancelResponse: r, // TODO: poner el response obtenido del ajax
              }));

              // reloading the page:
              setCancelModalIsOpen(false);
              if (r != "ERROR") {
                if (r.result === "OK") {
                  init();
                } else {
                  alert("Sólo puedes anular esta reserva hasta 4 horas antes del inicio de tu clase.");
                }
              } else {
                alert(CONNECTION_ERROR_MESSAGE);
              }
            }}>
            {
              state.cancelResponse === null ? "Cancelando..." : "SI"
            }
          </Button>
        </DialogActions>
      </Dialog>
      <Footer></Footer>
    </div>
  )
}

export default PageMyClasses

export function getTutorBookingRoute(tutorId: string, special: string | null | undefined) {
  if (special === "YES") {
    return window.location.origin + "/reserva-especial?special_type=" + tutorId;
  }
  return reservarPathRoute + "/" + tutorId;
}
