import React, { useEffect, useState } from 'react'
import { ERROR_CONST } from '../api/EliteFetch';
import { BookingProcessState } from '../pages/PageBooking'
import { CONNECTION_ERROR_MESSAGE } from '../util/Messages';
import { initAvailabilityTimeSheet } from '../util/TimesheetUtil';
import { sleep } from '../utils';

const AgostoChooseSchedules = (props: {
  state: BookingProcessState,
  handleScheduleSelectionChange: any,
  handlerClickPreviousWeek: () => void,
  handlerClickNextWeek: () => void,
}) => {
  const { tutorAvailabilityResponse, tutorBookingsResponse,
    scheduleComponentLastLoadDate, scheduleSelection, 
    weekStartForAvailability,
  } = props.state;

  // runs when this component loads:
  useEffect(() => {
  }, []);

  async function initAvailTable() {
    if (tutorAvailabilityResponse === ERROR_CONST || tutorAvailabilityResponse == null) return;
    await sleep(100);
    initAvailabilityTimeSheet(tutorBookingsResponse, 
      tutorAvailabilityResponse.disponibilidad, 
      weekStartForAvailability,
      scheduleSelection,
      scheduleComponentLastLoadDate,
      props.handleScheduleSelectionChange,
      tutorAvailabilityResponse.horas_anticipacion,
      tutorAvailabilityResponse.fecha_caducidad,
      
      tutorAvailabilityResponse.exception_dates ?? [],
      tutorAvailabilityResponse,
    );
  }

  let tableSchedulesElement = <div></div>;
  if (tutorAvailabilityResponse === ERROR_CONST) {
    tableSchedulesElement = <div>{CONNECTION_ERROR_MESSAGE}</div>
  } else if (tutorAvailabilityResponse == null) {
    tableSchedulesElement = <div>Buscando un horario para tí...</div>
  } else {
    initAvailTable();

    tableSchedulesElement = (
      <div>
        <div className="row" id="desktop-style-paso2">
          <div className="col-md-offset-3 col-xs-6 col-sm-4" />
          <div className="col-11 d-flex justify-content-around">
            <div onClick={props.handlerClickPreviousWeek} className="btn btn-primary btn-sm margin-paso4a elitePreviousWeek" data-toggle="tooltip" data-placement="top" title="Semana anterior"><i className="fa fa-chevron-left" aria-hidden="true" /> Semana anterior</div>
            <span className="calendar-title " id="calendarTitle">Selecciona y arrastra </span>
            <span className="calendar-title-mobile" id="calendarTitle">Selecciona </span>
            <div onClick={props.handlerClickNextWeek} className="btn btn-primary btn-sm margin-paso4 eliteNextWeek" data-toggle="tooltip" data-placement="top" title="Semana siguiente">Semana siguiente <i className="fa fa-chevron-right" aria-hidden="true" /></div>
          </div>
        </div>
        <div className="row" id="mobile-style-paso2">
          <div className="col-md-offset-3 col-xs-6 col-sm-4" />
          <div className="col-11 d-flex justify-content-around">
            <div onClick={props.handlerClickPreviousWeek} className="btn btn-primary btn-sm margin-paso4a elitePreviousWeek" data-toggle="tooltip" data-placement="top" title="Semana anterior"><i className="fa fa-chevron-left" aria-hidden="true" /></div>
            <span className="calendar-title " id="calendarTitle">Selecciona y arrastra </span>
            <span className="calendar-title-mobile" id="calendarTitle">Selecciona </span>
            <div onClick={props.handlerClickNextWeek} className="btn btn-primary btn-sm margin-paso4 eliteNextWeek" data-toggle="tooltip" data-placement="top" title="Semana siguiente"><i className="fa fa-chevron-right" aria-hidden="true" /></div>
          </div>
        </div>
        <table id="table-availability" className="table">
          <tbody id="availability">
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div>
      <div className="form-row mt-2">
        <div className="timetable-note mb-2">
          <span className="square rounded available-hour " /><span className="pe-2 ps-2"> Disponible</span>
          <span className="square rounded selected-hour " /><span className="pe-2 ps-2"> Reservado por ti</span>
          <span className="square rounded border border-dark not-inva" /><span className="pe-2 ps-2"> No disponible</span>
        </div>
        {/* <div id="notification" className="alert alert-primary card tex-center" role="alert">
          Invalid time!
        </div> */}
        {/* <div id="availability"></div> */}
        {tableSchedulesElement}
      </div>
    </div>
  )
}

export default AgostoChooseSchedules
