import { DisponibilidadEntity } from "../../model/DisponibilidadEntity";

export const SCHEDULE_INTERVAL = 6 * 60; // in minutes, must be a divisor of 24 hours

const DAYS_IN_WEEK = 7;
const INTERVALS_PER_DAY = Math.floor((24 * 60) / SCHEDULE_INTERVAL);

/**
 * Returns the number of minutes that the tutor is available for each day and interval.
 * @param intervals 
 * @returns 
 */
export function getFixedIntervals(intervals: DisponibilidadEntity[]) {
  // generate a list of numbers from 0 to INTERVALS_PER_DAY - 1, representing the intervals of the day
  const intervalsPerDay = Array.from(Array(INTERVALS_PER_DAY).keys());

  const fixedIntervals = intervalsPerDay.map(interval => {
    // generate a list of numbers from 0 to 6, representing the days of the week
    const days = Array.from(Array(DAYS_IN_WEEK).keys());
    return days.map(day => ({
      day,
      interval,
      minutes : 0,
    }));
  });

  // fill the fixedIntervals with the intervals from the intervals array
  for (const interval of intervals) {
    const day = parseInt(interval.weekday);
    const start_time = parseInt(interval.start_time);
    const end_time = parseInt(interval.end_time);
    
    const intervalIndex = Math.floor(start_time / SCHEDULE_INTERVAL);
    const intervalEndIndex = Math.floor(end_time / SCHEDULE_INTERVAL);

    for (let i = intervalIndex; i <= Math.min(intervalEndIndex, INTERVALS_PER_DAY - 1); i++) {
      const fixedIntervalStart = i * SCHEDULE_INTERVAL;
      const fixedIntervalEnd = (i + 1) * SCHEDULE_INTERVAL;
      const intersection = getIntersection(fixedIntervalStart, fixedIntervalEnd, start_time, end_time);

      fixedIntervals[i][day].minutes += intersection;
    }
  }

  return fixedIntervals;
}

function getIntersection(fixedIntervalStart: number, fixedIntervalEnd: number, start_time: number, end_time: number) {
  if (fixedIntervalStart > end_time || fixedIntervalEnd < start_time) return 0;
  const intersectionStart = Math.max(fixedIntervalStart, start_time);
  const intersectionEnd = Math.min(fixedIntervalEnd, end_time);
  return intersectionEnd - intersectionStart;
}
