// mainly gotten from https://github.com/rehooks/local-storage 

import { createContext, useContext } from 'react';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { FullUserDataResponseOk } from '../model/FullUserProfile';

// NOTE: for some reason when importing this from a non-tsx file it generated errors
const defaultProfile : FullUserDataResponseOk = {
  "status" : "OK",
  "user_id" : 0,
  "data" : null,
};

const defaultContextValue = {
  "value" : defaultProfile,
  "writeValue" : (newData : FullUserDataResponseOk) => {},
  "deleteValue" :  () => {},
};
// [defaultProfile, (newData : FullUserDataResponseOk | null) => {}, () => {}];

const ProfileContext = createContext(defaultContextValue);

const KEY = 'elite_profile';

export const ProfileProvider = ({ children } : {
  children : JSX.Element
}) => {
  const ctxValue = useLocalStorage<FullUserDataResponseOk>(KEY, defaultProfile);
  const v = {
    "value" : ctxValue[0],
    "writeValue" : ctxValue[1],
    "deleteValue" : ctxValue[2],
  }
  return (
    <ProfileContext.Provider value={v}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);
export const writeProfile = (r : FullUserDataResponseOk) => writeStorage(KEY, r);
export const readProfile = () : FullUserDataResponseOk => {
  return JSON.parse(localStorage.getItem(KEY) ?? JSON.stringify(defaultProfile))
};
