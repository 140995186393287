import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { fetchPayments } from '../api/EliteFetch';
import { homePathRoute } from '../App';
import AgostoHeader from '../components/AgostoHeader';
import Footer from '../components/Footer';
import MyPayments from '../components/MyPayments'
import { useProfile } from '../data/ProfileProvider';
import { PaymentsResponse } from '../model/PaymentsResponse';

const PageMyPayments = () => {

  const [paymentsRes, setPaymentsRes] = useState(null as (null | "ERROR" | PaymentsResponse));


  const ph = useProfile();
  const profile = ph.value;

  const history = useHistory();

  // load when this app loads:
  useEffect(() => {
    // redirect to home page if not logged in
    if (profile.user_id === 0) {
      history.push(homePathRoute);
      return;
    }

    // initializations
    // request payments
    fetchPayments().then(r => {
      setPaymentsRes(r);
    });
    document.title = "Mis pagos | Puntual";
  }, []);

  return (
    <div>
      <AgostoHeader title="Mis Pagos"></AgostoHeader>
      <MyPayments paymentsResponse={paymentsRes}></MyPayments>
      <Footer></Footer>
    </div>
  )
}

export default PageMyPayments
