import { count } from 'console';
import React from 'react'
import { ERROR_CONST } from '../../api/EliteFetch';
import { useDummyData } from '../../config/WpBackend';
import { ClassesResponseTutor, classesResponseTutorExample } from '../../model/TutorClassesResponse'
import { getNameByMateriaId, getNameByNivelId } from '../../util/CoursesConstants';
import { CONNECTION_ERROR_MESSAGE } from '../../util/Messages';
import AgostoLoader from '../AgostoLoader';
import '../../style/TableResponsive.scss';

const TutorClasses = (props: {
  classesTutorResponse: ClassesResponseTutor | null | "ERROR"
}) => {

  if (props.classesTutorResponse == null) { // loading
    return (
      <AgostoLoader></AgostoLoader>
    );
  }

  if (props.classesTutorResponse === ERROR_CONST) {
    return <div>{CONNECTION_ERROR_MESSAGE}</div>
  }

  const classesTutorEntries = props.classesTutorResponse.classesAll;

  return (
    <div className="Table-responsive">
      <div className="row">
        <div className="col-sm-12">
          {classesTutorEntries.length > 0 ?
            <div>
              <div className="card">
                <div className="card-header"><br />
                  <h3 className="card-title">Clases por dictar</h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive respons-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Fecha</th>
                          <th scope="col">Horario</th>
                          <th scope="col">Nombre del alumno </th>
                          <th scope="col">Edad del alumno </th>
                          <th scope="col">Nombre del padre</th>
                          <th scope="col">Duración</th>
                          <th scope="col">Materia</th>
                          <th scope="col">Nivel</th>
                          <th scope="col">Enviar mensaje al alumno</th>
                          <th scope="col">Link a la videoconferencia</th>
                        </tr>
                      </thead>
                      <tbody>
                        {classesTutorEntries.map((bookData) => (
                          <tr>
                            <th scope="row">{bookData.fecha}</th>
                            <td data-label="Horario">{bookData.horario}</td>
                            <td data-label="Nombre del alumno">{bookData.nom_alumno}</td>
                            <td data-label="Edad del alumno">{bookData.edad_alumno}</td>
                            <td data-label="Nombre del padre">{bookData.nom_padre}</td>
                            <td data-label="Duración">{bookData.duracion} minutos</td>
                            <td data-label="Materia">{getNameByMateriaId(bookData.materia)}</td>
                            <td data-label="Nivel">{getNameByNivelId(bookData.nivel)}</td>
                            <td data-label="Enviar mensaje al alumno"><a href={bookData.mensaje}>✉️</a></td>
                            <td data-label="Link a la videoconferencia"><a href={bookData.link_conferencia}>Link</a></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br />
              <p>Por favor abre el link a la videoconferencia y asegúrate de que todo esté funcionando bien antes de la clase 😉</p>
            </div>
            : <p>No tienes clases programadas 📅</p>}
        </div>
      </div>
    </div>
  )
}

export default TutorClasses