import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent';
import { eliteModalTransition01 } from '../components/UiTransitions';
import RowSpacer from '../components/util/RowSpacer';
import { Tutor } from '../model/Tutor';
import { TutorAvailability } from '../model/TutorAvailability';
import { convert_date_standard_to_Peruvian } from '../util/ConversionUtil';
import { openWhatsapp } from '../util/OpenWhatsapp';

const ModalPastExpirationDate = (props: {
  isOpen: boolean,
  onClose: () => void,
  tutor: Tutor | null,
  tutorAvailabilityResponse: null | TutorAvailability | "ERROR",
}) => {

  const { tutor, tutorAvailabilityResponse } = props;

  if (tutor == null || tutorAvailabilityResponse == null || tutorAvailabilityResponse === "ERROR") {
    return <></>;
  }

  const fecha_caducidad = convert_date_standard_to_Peruvian(tutorAvailabilityResponse.fecha_caducidad);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={props.isOpen}
      TransitionComponent={eliteModalTransition01}
      onClose={props.onClose}>
      <DialogContent>
        <div className="px-2 px-sm-2 mt-2">
          <h6 className="text-center">
            <span>No hay más dias disponibles 😞 </span>
          </h6>
          <RowSpacer height={20} />
          <div>
            <p>El horario de {tutor.name} está hasta el {fecha_caducidad} </p>
            <br />
            <p>Si te gustaria que actualice su horario y te avisemos cuando éste disponible, haz click aquí abajo:</p>
          </div>
          <RowSpacer height={15}></RowSpacer>
          {
            <div style={{ textAlign: 'center' }}>
              <a href="." onClick={(e) => {
                e.preventDefault();
                openWhatsapp("Deseo reservar clase con " + tutor.name
                  + " pero no puedo porque su horario está hasta el " + fecha_caducidad
                  + ". Me gustaría que actualice su horario y me avisen cuando esté disponible!!");
                return false;
              }}
                className="btn btn-primary btn-lg white-text fs-6" role="button" aria-disabled="false">
                Avisarme
              </a>
            </div>
          }
        </div>
      </DialogContent>
    </Dialog >
  )
}

export default ModalPastExpirationDate
