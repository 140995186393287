import { Box, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { UserServices } from '../api/App01';
import { eliteLogout, ERROR_CONST, fetchFullUserData, fetchMyData, fetchReservations } from '../api/EliteFetch';
import AgostoHeader from '../components/AgostoHeader'
import AgostoLoader from '../components/AgostoLoader';
import Footer from '../components/Footer';
import RowSpacer from '../components/util/RowSpacer';
import { FullUserDataResponse } from '../model/FullUserProfile';
import { CONNECTION_ERROR_MESSAGE } from '../util/Messages';
import { useMuiEasySnackbar } from "mui-easy-snackbar";
import { homePathRoute } from '../App';
import { useHistory } from 'react-router-dom';
import { useProfile } from '../data/ProfileProvider';

interface State {
  response: FullUserDataResponse | null | "ERROR";
}

const PageMyDataPersonal = () => {
  const ph = useProfile();
  const profile = ph.value;
  const history = useHistory();

  const initialState: State = {
    response: null,
  }
  const [state, setState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false); // whether it is saving the info
  const [logoutLoading, setlogoutLoading] = useState(false);

  const snackbar = useMuiEasySnackbar();

  async function init() {
    document.title = "Mis datos | Puntual";
    if (profile.user_id === 0) { // user is not logged in
      history.push(homePathRoute);
    }
    fetchFullUserData().then(dat => {
      setState(ps => ({
        ...ps,
        response: dat,
      }));
    });
  }
  useEffect(() => {
    init();
  }, []);


  async function handleLogout() {
    setlogoutLoading(true);
    const r = await eliteLogout();
    setlogoutLoading(false);
    if (r !== ERROR_CONST) {
      snackbar.showSuccess("Sesión cerrada, vuelve pronto!");
      history.push(homePathRoute);
    }
  }

  let content = null;
  if (state.response === ERROR_CONST) {
    content = <div>{CONNECTION_ERROR_MESSAGE}</div>
  } else if (state.response == null) {
    content = (<div>
      <AgostoLoader></AgostoLoader>
    </div>);
  } else {
    const datauser = state.response;
    content =
      <div>
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '80ch' },
          }}
          onSubmit={async (event: any) => {
            event.preventDefault();
            setIsLoading(true);
            const r = await UserServices.saveClientProfile({
              name_pa: (document.getElementById("name_pa") as HTMLInputElement).value,
              email_pa: (document.getElementById("email_pa") as HTMLInputElement).value,
              phone_pa: (document.getElementById("phone_pa") as HTMLInputElement).value,
            });
            if (r === ERROR_CONST) {
              snackbar.showError(CONNECTION_ERROR_MESSAGE);
            } else {
              if (r.status === "OK") {
                snackbar.showSuccess("Guardado exitoso 😊");
              } else {
                snackbar.showError("Error: " + (r.message ?? r.code ?? r.status));
              }
            }
            setIsLoading(false);
          }}
          autoComplete="off"
        >
          <div>
            <TextField
              fullWidth
              id="name_pa"
              label="Nombres y Apellidos"
              defaultValue={datauser.data?.names} />
          </div>
          <div>
            <TextField
              fullWidth
              id="email_pa"
              type="email"
              label="Email"
              defaultValue={datauser.data?.user_email} />
          </div>
          <div>
            <TextField
              fullWidth
              id="phone_pa"
              label="Celular"
              defaultValue={datauser.data?.phone} />
          </div>
          <br />
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Guardando...⌛" : "Guardar cambios"}
          </Button>
        </Box>
        <br />
        <br />
        <Button
          sx={{
            backgroundColor: '#000000',
            width: '100%',
          }}
          onClick={handleLogout}
          variant="contained">
          {isLoading ? "Cerrando sesión...⌛" : "Cerrar Sesión"}
        </Button>
      </div>

  }
  return (
    <div>
      <AgostoHeader title="Mis datos"></AgostoHeader>
      <div className="container">
        <RowSpacer height={20}></RowSpacer>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-6'>
            <h5>Tu información</h5>
            <br />
            {content}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>

  )

}


export default PageMyDataPersonal