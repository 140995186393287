import {
  Slide,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

// for the transition slide effect
export const eliteModalTransition01 = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide timeout={1} 
    direction="down" 
    ref={ref} 
    {...props} 
  />;
});