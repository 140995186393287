import { ToggleButton } from '@mui/material';
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { fetchUpdateBookings } from '../api/EliteFetch';
import { eliteModalTransition01 } from '../components/UiTransitions';
import RowSpacer from '../components/util/RowSpacer';
import { Tutor } from '../model/Tutor';
import { trackClick } from '../util/AnalyticsUtil';
import { allKeyword, getIdByMateriaName, getIdByNivelName, getNameByMateriaId, getNameByNivelId } from '../util/CoursesConstants';
import { getMateriasFromSubjects, getNivelesFromSubjects } from '../util/TutorUtil';

const ModalConfirmMateriaYNivel = (props: {
  isOpen: boolean,
  onClose: () => void,
  tutor: Tutor | null,
  materiaId?: string | null,
  nivelId?: string | null,
  booking_group_id?: string | null,
}) => {

  let history = useHistory();
  const { tutor, materiaId: defMateriaId, nivelId: defNivelId } = props;

  const escogerText = [];
  if (defMateriaId === allKeyword) escogerText.push("la materia");
  if (defNivelId === allKeyword) escogerText.push("el nivel");

  const materiasTutorNames = getMateriasFromSubjects(tutor?.subjects ?? []);
  const materiasTutorIds = materiasTutorNames.map(n => getIdByMateriaName(n));

  const [materia, setmateria] = useState((defMateriaId === allKeyword ? null : defMateriaId) as (string | null));
  const [nivel, setnivel] = useState((defNivelId === allKeyword ? null : defNivelId) as (string | null));

  function getNivelesTutorIds(materia: string) {
    const nivelesTutorNames = getNivelesFromSubjects(tutor?.subjects ?? [], materia == null ? null : getNameByMateriaId(materia));
    const nivelesTutorIds = nivelesTutorNames.map(n => getIdByNivelName(n));
    return nivelesTutorIds;
  }


  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={props.isOpen}
      TransitionComponent={eliteModalTransition01}
      onClose={props.onClose}>
      <DialogContent>
        <div className="px-2 px-sm-2 mt-2">
          <h6 className="text-center">
            <span>🧩 Completa estos datos referenciales:</span>
          </h6>
          <RowSpacer height={20}/>
          <p className='mb-3 text-center'>
            Por favor escoge la materia:
          </p>
          <div>
            <ToggleButtonGroup
              color="primary"
              exclusive={true}
              value={materia}
              onChange={(e, v) => {
                setmateria(v);

                // the nivel in case of no match to prevent the user from choosing a non-matching nivel
                const niveles = getNivelesTutorIds(v);
                // if the nivel is not in the list, then we set it to null
                setnivel(niveles.includes(nivel ?? "") ? nivel : null);

              }}
              sx={{
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}  >
              {
                materiasTutorIds.map((mId) => {
                  return (<ToggleButton key={mId} value={mId}>{getNameByMateriaId(mId)}</ToggleButton>);
                })
              }
            </ToggleButtonGroup>
          </div>
          <RowSpacer height={15}></RowSpacer>

          {
            materia &&
            <>
              <p className='text-center'>
                Y el nivel:
              </p>
              <RowSpacer height={15}></RowSpacer>
              <div className='row d-flex'>
                <ToggleButtonGroup
                  color="primary"
                  exclusive={true}
                  value={nivel}
                  onChange={(e, v) => {
                    setnivel(v);
                  }}
                  sx={{
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                  }}  >
                  {
                    getNivelesTutorIds(materia).map((mId) => {
                      return (<ToggleButton key={mId} value={mId}>{getNameByNivelId(mId)}</ToggleButton>);
                    })
                  }
                </ToggleButtonGroup>
              </div>
              <RowSpacer height={25}></RowSpacer>

              {
                nivel &&
                <div style={{ textAlign: 'center' }}>
                  <a href={"."} onClick={(e) => {
                    e.preventDefault();
                    trackClick("btn_confirm_materia_nivel", {
                      materia,
                      nivel,
                    });
                    // send the data to the server
                    fetchUpdateBookings(props.booking_group_id ?? "", materia, nivel);
                    props.onClose();
                    return false;
                  }} className="btn btn-primary btn-lg white-text fs-6" role="button" aria-disabled="false">
                    Enviar</a>
                </div>
              }
            </>
          }
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalConfirmMateriaYNivel
