export const allKeyword = "all";

export const materias = [
  { id: allKeyword, name: "Todas las materias", },
  { id: "math", name: "Matemáticas", },
  { id: "physical", name: "Física", },
  { id: "chemistry", name: "Química", },
  { id: "english", name: "Inglés", },
  { id: "language", name: "Lenguaje", },
  { id: "comprehension", name: "Comprensión lectora", },
  { id: "writing", name: "Redacción y ortografía", },
  { id: "literacy", name: "Lectoescritura", },
  { id: "readiness", name: "Aprestamiento", },
  { id: "psychological", name: "Consulta psicológica", },
  { id: "soft", name: "Habilidades blandas", },
  { id: 'Biologa', name: "Biología", },
  { id: 'Anatoma', name: "Anatomía", },
  { id: 'Medicinahumana', name: "Medicina humana", },
  { id: 'Bioqumica', name: "Bioquímica", },
  { id: 'Fisiologa', name: "Fisiología", },
  { id: 'Patologa', name: "Patología", },
  { id: 'Matemticabsica', name: "Matemática básica", },
  { id: 'Clculo1', name: "Cálculo 1", },
  { id: 'Clculo2', name: "Cálculo 2", },
  { id: 'Clculo3', name: "Cálculo 3", },
  { id: 'Ballet', name: "Ballet", },
  { id: 'Clasesdebaile', name: "Clases de baile", },
  { id: 'Clasesdecanto', name: "Clases de canto", },
  { id: 'DanceFitness', name: "Dance Fitness", },
  { id: 'Introduccinalatcnicavocal', name: "Introducción a la técnica vocal", },
  { id: 'Gramtica', name: "Gramática", },
  { id: 'Literatura', name: "Literatura", },
  { id: 'AptitudVerbal', name: "Aptitud Verbal", },
  { id: 'Redaccin', name: "Redacción", },
  { id: 'Marketing', name: "Marketing", },
  { id: 'Oratoria', name: "Oratoria", },
  { id: 'Marineranorteayballet', name: "Marinera norteña y ballet", },
  { id: "others", name: "Otros", },
];
export const getNameByMateriaId = (id: string) => {
  return materias.filter(v => v.id === id)[0]?.name ?? id;
}

// get id from name
export const getIdByMateriaName = (name: string) => {
  return materias.filter(v => v.name === name)[0]?.id ?? name;
}

export const niveles = [
  { id: allKeyword, name: "Todos los niveles" },
  { id: "preschool", name: "Inicial" },
  { id: "primary", name: "Primaria" },
  { id: "high", name: "Secundaria" },
  { id: "preuniversitario", name: "Pre-universitario" },
  { id: "Universitario", name: "Universitario" },
  { id: "general", name: "General" },
];

export const getNameByNivelId = (id: string) => {
  return niveles.filter(v => v.id === id)[0]?.name ?? id;
}

// get id from name
export const getIdByNivelName = (name: string) => {
  return niveles.filter(v => v.name.toLowerCase() === name.toLowerCase())[0]?.id ?? name;
}

export const all_schedules = "all_schedules";

export const disponibilidades = [
  { id: all_schedules, name: "Todos los horarios" },
  { id: "8am_1pm", name: "8am a 1pm" },
  { id: "1pm_6pm", name: "1pm a 6pm" },
  { id: "6pm_11pm", name: "6pm a 11pm" },
];

export const getNameByDisponibilidad = (id: string) => {
  return disponibilidades.filter(v => v.id === id)[0]?.name ?? id;
}

