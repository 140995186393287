import React, { Component, useEffect, useState } from 'react'
import { sleep } from '../utils';

// const PageUpdateStateTest = () => {

//   const [state, setState] = useState({
//     step: 1,
//     finishedFetch: false,
//   });

//   // load when this app loads:
//   useEffect(() => {
//     setState({
//       ...state,
//       step: 2,
//     })
//     fetchAvData();
//   }, []);

//   async function fetchAvData() {
//     await sleep(2500);
//     setState({
//       ...state,
//       finishedFetch: true,
//     });
//   }

//   return (
//     <div>
//       {/* 
//       Starts with: {"step":2,"finishedFetch":false}
//       Then after ~2 seconds changes to this: {"step":1,"finishedFetch":true}
//       */}
//       {JSON.stringify(state)}
//     </div>
//   )
// }

class PageUpdateStateTest extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      step: 1,
      finishedFetch: false,
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state, // creo que esto ni siquiera es requerido
      step: 2,
    });

    this.fetchAvData();

  }

  
  async fetchAvData() {
    await sleep(2500);
    this.setState({
      ...this.state,  // creo que esto ni siquiera es requerido
      finishedFetch: true,
    });
  }

  render() {
    return (
      <div>
        {/* 
        Starts with: {"step":2,"finishedFetch":false}
        Then after ~2 seconds changes to this: {"step":2,"finishedFetch":true}
        */}
        {JSON.stringify(this.state)}
      </div>
    )
  }
}


export default PageUpdateStateTest
