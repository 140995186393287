import { useEffect } from 'react'
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { VIDEOCONFERENCE_BASE_URL } from '../util/LinkConstants';
import PageBooking from './PageBooking';


const PageVideoconference = () => {
  const routeParams = (useParams() as any);
  const meetingId = routeParams.meetingId as string;

  const history = useHistory();

  useEffect(() => {
    window.location.href = VIDEOCONFERENCE_BASE_URL + meetingId;
  }, []);

  return <div>
    Redirecting...
  </div>;
};

const PageVideoconferenceRouteHandler = () => {
  let match = useRouteMatch();
  return (
    <div>
      <Switch>
        {/* when a tutorId has been set */}
        <Route path={`${match.path}/:meetingId`}>
          <PageVideoconference></PageVideoconference>
        </Route>

        {/* when no param is set in the URL: */}
        <Route path={match.path}>
          <PageBooking></PageBooking>
        </Route>
      </Switch>
    </div>
  )
}

export default PageVideoconferenceRouteHandler
