// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
import { isProductionWebsite } from "./DevEnvUtil";

// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfigProduction = {
  apiKey: "AIzaSyBMRAH0Tue6rtjtn9-cajPj3x-0ZALiwFU",
  authDomain: "elite-classroom-01.firebaseapp.com",
  projectId: "elite-classroom-01",
  storageBucket: "elite-classroom-01.appspot.com",
  messagingSenderId: "921985004631",
  appId: "1:921985004631:web:39a2e00d1c2d5c1bf11f12",
  measurementId: "G-LGBBH5VX2V"
};

const firebaseConfigTesting = {
  apiKey: "AIzaSyBMRAH0Tue6rtjtn9-cajPj3x-0ZALiwFU",
  authDomain: "elite-classroom-01.firebaseapp.com",
  projectId: "elite-classroom-01",
  storageBucket: "elite-classroom-01.appspot.com",
  messagingSenderId: "921985004631",
  appId: "1:921985004631:web:2102d62b31fa550ff11f12",
  measurementId: "G-GVEMHXYDLC"
};

const firebaseConfig = isProductionWebsite ? firebaseConfigProduction : firebaseConfigTesting;

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Init analytics
export const fa = firebase.analytics();
