export interface Quote {
  quote: string,
  author: string,
}

// criterio para agregar frases: no exagerar mucho en un solo concepto (fracaso, éxito, motivación)
// de forma que en general el conjunto de frases intente comunicar una filosofía de vida
export const phrases : Quote[] = [
  {
    "quote" : "Todos nuestros sueños se pueden hacer realidad si tenemos el coraje de perseguirlos.",
    "author" : "Walt Disney",
  },
  // {
  //   "quote" : "La mejor forma de predecir el futuro es crearlo.",
  //   "author" : "Peter Drucker",
  // },
  // {
  //   "quote" : "Puedo aceptar el fracaso, todos fracasan en algo. Pero no puedo aceptar no intentarlo.",
  //   "author" : "Michael Jordan",
  // },
  {
    "quote": "El éxito consiste en ir de fracaso en fracaso sin perder el entusiasmo.",
    "author" : "Winston Churchill",
  },
  {
    "quote" : "Podría o bien verlo suceder o bien ser parte de ello",
    "author" : "Elon Musk",
  },
  // https://www.goodreads.com/quotes/754489-tomorrow-is-a-mystery-today-is-a-gift-that-is
  {
    "quote" : "El ayer es historia, el mañana es un misterio, pero el hoy es un obsequio. Por eso se le llama presente.",
    "author" : "Eleanor Roosevelt",
  },
  {
    "quote" : "Debes levantarte cada mañana con determinación si quieres irte a dormir con satisfacción.",
    "author" : "George Lorimer",
  },
  {
    "quote" : "El éxito no es un accidente. Es trabajo duro, perseverancia, aprendizaje, estudio, sacrificio y, sobre todo, amor por lo que estás haciendo o aprendiendo a hacer.",
    "author" : "Pelé",
  },
  // tal vez mucho de Churchill por ahora:
  // {
  //   "quote" : "El éxito no es el final, el fracaso no es fatal, el coraje de continuar es lo que cuenta.",
  //   "author" : "Winston Churchill",
  // },
  {
    "quote" : "Una persona que jamás ha cometido un error nunca ha intentado algo nuevo.",
    "author" : "Albert Einstein",
  },
  { 
    "quote" : "La educación es el arma más poderosa que puedes usar para cambiar el mundo.",
    "author" : "Nelson Mandela",
  },
];

export const getRandomQuote = () => {
  return phrases[Math.floor(Math.random() * phrases.length)];
}