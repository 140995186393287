import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { ERROR_CONST, fetchTutorPublicProfile } from '../api/EliteFetch';
import { TutorPublicProfileResponse } from '../api/TutorPublicProfileResponse';
import AgostoLoader from '../components/AgostoLoader';
import TutorInfoProfile from '../components/tutor/TutorInfoProfile';
import { useProfile } from '../data/ProfileProvider';
import '../style/showTutorprofile.scss';
import { CONNECTION_ERROR_MESSAGE } from '../util/Messages';
import { isEmpty } from '../util/StringUtil';
interface State {
  response: TutorPublicProfileResponse | null | "ERROR";
}

const PageShowTutorProfile = () => {
  // Note: DO NOT use 'any' in all situations, just when you are sure about something 
  // actually exists
  const routeParams = (useParams() as any);  // this contains tha path part after /tutor
  // for example: when the whole path is "/tutor/323" , then the tutorId variable is "323"
  const tutorId = parseInt(routeParams.tutorId as string);

  const initialState: State = {
    response: null,
  }
  const [state, setState] = useState(initialState);

  async function init() {
    fetchTutorPublicProfile(tutorId).then(dat => {
      setState(ps => ({
        ...ps,
        response: dat,
      }));
    });
  }
  useEffect(() => {
    init();
  }, []);
  

  document.title = "Tutor | Elite Classroom";
  let content = null;
  if (state.response === ERROR_CONST) {
    content = <div>{CONNECTION_ERROR_MESSAGE}</div>
  } else if (state.response == null) {
    content = (<div>
      <AgostoLoader></AgostoLoader>
    </div>);
  } else if (state.response.status !== "OK") {
    content = <div>Tutor no encontrado</div>
  } else {

    const tutor = state.response.data;

    document.title = tutor.name + " | Elite Classroom";
    content = <div className="">
      <div className="col-12 bg-white shadow rounded overflow-hidden">
        <div className="row">
          <div className={!isEmpty(tutor.URL_video)? "col-sm-4 text-center" : "col-sm-12"}>
            <div className="px-4 pt-0 pb-5 cover">
              <div className="media align-items-end profile-head">
                <div className="profile mr-3"><img 
                src={tutor.picture} alt="..." width={210} 
                className="mb-2 img-thumbnailv2" 
                style={{
                  objectFit: 'cover',
                }}
                /></div>
                <div className="media-body mb-5 text-white">
                  <h4 className="mt-0 mb-0 text-white">{tutor.name}</h4>
                  <h5 className="text-white">{tutor.verif === "on" && <div className="p-3 ps-0">✅ Verificado</div>}</h5>
                  <h5 className="text-white">{tutor.verif !== "on" && <div className="p-3 ps-0">No verificado</div>}</h5>
                </div>
              </div>
            </div>
          </div>
          {
            isEmpty(tutor.URL_video)? "":
          <div className="col-sm-8 video-responsive cover ">
            <iframe width={900} height={440} src={isEmpty(tutor.URL_video) ? " ": "https://www.youtube.com/embed/" + tutor.URL_video} title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
          }
        </div>
        
        <TutorInfoProfile 
          tutor={tutor}
          butonAgendar={true}
        ></TutorInfoProfile>
      </div>
    </div>
  }

  return (
    <div>
      <div className="container-fluid p-0 m-0 eliteshowprofile">
        <div className="row py-4 px-4">
          <div className="col-md-9 mx-auto p-0">
            {/* Profile widget */}
            {content}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageShowTutorProfile