
export interface ClassesResponseTutor {
  status: string;
  classesAll: ClassesTutorsResp[];
}

export interface ClassesTutorsResp {
  fecha: string;
  horario: string;
  nom_alumno: string;
  edad_alumno: number;
  nom_padre: string;
  duracion: string;
  materia: string;
  nivel: string;
  mensaje: string;
  link_conferencia: string;
}

export const classesResponseTutorExample: ClassesResponseTutor = {
  "status": "OK",
  "classesAll": [
    {
      "fecha": "Vie 22/10/2021",
      "horario": "03:00 AM - 03:45 AM",
      "nom_alumno": "llll",
      "edad_alumno": 15,
      "nom_padre": "rod",
      "duracion": "45",
      "materia": "mate",
      "nivel": "sec",
      "mensaje": "",
      "link_conferencia": "",
    },
    {
      "fecha": "Vie 22/10/2021",
      "horario": "03:00 AM - 03:45 AM",
      "nom_alumno": "llll",
      "edad_alumno": 15,
      "nom_padre": "rod",
      "duracion": "45",
      "materia": "mate",
      "nivel": "sec",
      "mensaje": "",
      "link_conferencia": "",
    },
    {
      "fecha": "Vie 22/10/2021",
      "horario": "03:00 AM - 03:45 AM",
      "nom_alumno": "llll",
      "edad_alumno": 15,
      "nom_padre": "rod",
      "duracion": "45",
      "materia": "mate",
      "nivel": "sec",
      "mensaje": "",
      "link_conferencia": "",
    }
  ]
}