import { useEffect, useState } from 'react'
import DayPicker, { DayModifiers } from 'react-day-picker';
import '../style/ReactDayPickerCustom.scss';
import { Link, useHistory } from 'react-router-dom';
import { ERROR_CONST, fetchSaveTutorExceptionDates, fetchTutorAvailability, TutorExceptionDates } from '../api/EliteFetch';
import { ensenarPathRoute, reservarPathRoute } from '../App';
import AgostoHeader from '../components/AgostoHeader';
import AgostoLoader from '../components/AgostoLoader';
import RowSpacer from '../components/util/RowSpacer';
import { useProfile } from '../data/ProfileProvider';
import { CONNECTION_ERROR_MESSAGE } from '../util/Messages';
import { trackClick } from '../util/AnalyticsUtil';
import Footer from '../components/Footer';
import { getPerfilProfesorUrl } from '../util/LinkConstants';


interface State {
  saveLoading: boolean;
  response: TutorExceptionDates | null | "ERROR";
}

const PageTutorDateExceptions = () => {

  const ph = useProfile();
  const profile = ph.value;

  const history = useHistory();

  const initialState: State = {
    saveLoading: false,
    response: null,
  }
  const [state, setState] = useState(initialState);

  const tutorId = Number(profile.data?.tutorId ?? "0");

  useEffect(() => {
    document.title = "Excepciones en horarios | Elite Classroom";
    if (profile.user_id === 0) { // user is not logged in
      history.push(ensenarPathRoute);
    }
    fetchTutorAvailability(tutorId).then(r => {
      if (r === ERROR_CONST) {
        setState(ps => ({ ...ps, response: ERROR_CONST }));
      } else {
        const dates = r.exception_dates ?? [];
        setState(ps => ({
          ...ps,
          response: {
            dates: dates,
          }
        }));
      }
    });
  }, []);



  function handleDayClick(day: Date, { selected, disabled, past }: DayModifiers) {
    trackClick("btn_click_date_exception");
    if (past) { // do nothing while loading
      return;
    }
    if (disabled) { // do nothing while loading
      return;
    }
    if (state.response === null || state.response === ERROR_CONST) return;
    const dayString = day.toISOString().split("T")[0]; // yyyy-mm-dd
    const selectedDays = state.response.dates;

    let newSelectedDays = selectedDays;
    if (selected) { // remove day
      newSelectedDays = selectedDays.filter(d => d !== dayString);
    } else { // add day
      newSelectedDays = [...selectedDays, dayString];
    }

    // send to backend
    const newDatesPayload = {
      dates: newSelectedDays,
    };

    setState(ps => ({
      ...ps,
      saveLoading: true,
    }));
    fetchSaveTutorExceptionDates(newDatesPayload).then(response => {
      setState(ps => ({
        ...ps,
        saveLoading: false,
        response,
      }));
    });
  }

  let content = <div></div>;
  if (state.response == null) {
    content = <AgostoLoader></AgostoLoader>
  } else if (state.response === ERROR_CONST) {
    content = <div>{CONNECTION_ERROR_MESSAGE}</div>
  } else {
    content = (
      <>
        <p className='h5 text-center'>Marca de morado los días que no estarás disponible:</p>
        <DayPicker
          disabledDays={state.saveLoading ? [
            {
              after: new Date(2000, 7, 1),
            }
          ] : []}
          modifiers={{
            past: {
              before: new Date(),
            }
          }}
          selectedDays={state.response.dates.map(d => {
            const whole_date_format = d + "T" + "00:00:00.000-05:00";
            const dateObject = new Date(whole_date_format);
            return dateObject;
          })}
          onDayClick={handleDayClick}
          todayButton="Ir a hoy"
          numberOfMonths={3}
          fixedWeeks
        />
        <p className='text-center'>
          <Link to={reservarPathRoute + "/" + tutorId}
            onClick={(e) => {
              trackClick("btn_see_ha_" + tutorId);
            }}
          >Click aquí para ver como luce tu horario ante los alumnos</Link>
        </p>
        <p className='text-center m-3'>
          <a href={getPerfilProfesorUrl()}>
          👈 Mi perfil</a>
        </p>
      </>
    )
  }

  return (
    <div>
      <AgostoHeader title="Tutor - Excepciones en horarios"></AgostoHeader>
      <div className="container">
        <RowSpacer height={20}></RowSpacer>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-9'>
            <div className='row justify-content-center'>
              {content}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default PageTutorDateExceptions
