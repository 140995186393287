import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { useMuiEasySnackbar } from "mui-easy-snackbar";
import { compartirPathRoute, reservarPathRoute } from '../../App';
import { useDummyData } from '../../config/WpBackend';
import { Tutor } from '../../model/Tutor';
import { trackClick } from '../../util/AnalyticsUtil';
import { isEmpty } from '../../util/StringUtil';
import AgostoReviewStars from '../AgostoReviewStars';
import TutorReviewsforShareProfile from './TutorReviewforShareProfile';
import '../../style/ScheduleTutor.scss';
import TutorScheduleSmall from './TutorScheduleSmall';
import { getPublicTutorProfileUrl } from '../../util/LinkConstants';
import { hideBootstrapModal } from '../../util/Bootstrap';
import { copyTextToClipboard } from '../../util/ClipboardUtil';

interface State {
}

type Response = Tutor[];

interface InitialState {
}

interface MyPropsType {
  tutor: Tutor,
  onChooseTutor?: () => void,
  butonAgendar: boolean,
}


const TutorInfoProfile: React.FC<MyPropsType> = (props: MyPropsType) => {

  const { tutor } = props;
  const tutorId = tutor.id;

  const initialState: InitialState = {
  }
  const [state, setState] = useState(initialState);
  const snackbar = useMuiEasySnackbar();
  const history = useHistory();

  async function init() {
  }

  useEffect(() => {
    init();
  }, []);


  let content = null;

  // runs when this component loads:
  useEffect(() => {
    // initializations
    init();
  }, []);

  if (useDummyData) { // just for testing
    tutor.last_finished_booking_timestamp = 1634941761;
  }

  return (
    <div>

      <div className="row mx-4 my-3">
        <div className="col-12 px-3">
          <div className="row p-4 rounded shadow-sm bg-light">
            <div className="col">
              <h5 className="mb-0">Enseña</h5>
            </div>
            <ul className="list-group list-group-horizontal d-flex justify-content-center pt-2">
              {
                tutor.subjects.map(subject => {
                  const i = subject.name.search(" para");
                  const i2 = (i === -1 ? subject.name.length : i);
                  return subject.name.substr(0, i2).trim();
                })
                  .filter((item, i, ar) => ar.indexOf(item) === i) // uniques
                  .filter(v => v !== "").map((name) => {
                    return (<li key={name} className="list-group-item list-group-item-elite-01">{
                      name
                    }</li>);
                  })
              }
            </ul>
          </div>
          <br />
          <div className="row px-4 rounded shadow-sm bg-light">
            <div className="col-sm-6 col-md-8 py-4">
              <h5 className="mb-0">Sobre mí</h5><br />
              <p style={{
                whiteSpace: 'pre-line',
              }}>{tutor.intro}</p>
            </div>
            <div className="col-sm-5 offset-sm-1 col-md-4 offset-md-0 text-end  py-4" >
              {tutor.students !== 0 ?
                <div className="col-sm-6 col-md-5" style={{ width: '100%' }} >
                  <ul className="list-inline ">
                    <li className="list-inline-item text-end text-lg-center ps-3 pe-3 me-0">
                      <h5 className="font-weight-bold mb-0 d-block ">{tutor.tutored_minutes > 0 ? (tutor.tutored_minutes / 45).toFixed(0) + "" : "0"}</h5><small className="text-muted"> Clases</small>
                    </li>
                    {/*<li className="list-inline-item text-end text-lg-center ps-2 pe-2  me-0">
                              <h5 className="font-weight-bold mb-0 d-block">{tutor.tutored_minutes > 0 ? (tutor.tutored_minutes / 60).toFixed(1) + "" : "0"} </h5><small className="text-muted"> Horas enseñadas</small>
                            </li>*/}
                    <li className="list-inline-item text-end text-lg-center ps-2 pe-2 me-0">
                      <h5 className="font-weight-bold mb-0 d-block">{tutor.students}</h5><small className="text-muted"> Alumnos</small>
                    </li>
                  </ul>
                  <br />
                  <div className="col-6 col-md-7 fs-5 text-end " style={{ width: '100%' }} >
                    <ul>
                      {tutor.stars != null && <AgostoReviewStars stars={tutor.stars}></AgostoReviewStars>}
                      {tutor.stars == null && <div>Aun no tiene valoraciones</div>}
                      <p className="ms-2">{tutor.stars?.toFixed(1)} </p>
                    </ul>
                  </div>
                </div>
                : <div className="d-flex justify-content-center pt-4">
                  <strong style={{ fontSize: 18 }}>
                    Profesor nuevo
                  </strong>
                </div>}
            </div>
          </div><br />
          <div className="row p-4 rounded shadow-sm bg-light">
            <div className="">
              <h5 className="mb-0">Materias y Niveles</h5><br />
              {
                tutor.subjects.map(cursos => {
                  return (
                    <div key={cursos.name} className="text-center p-2 fw-bold">{cursos.name}</div>
                  )
                })
              }
            </div>
          </div><br />
          <div className="row p-4 rounded shadow-sm bg-light">
            <div className="">
              <h5 className="mb-0">Estadísticas</h5><br />
              {tutor.last_finished_booking_timestamp != null && <p className="text-center">
                Fecha de la última clase : <strong>
                  {/* 14/09/2021 */}
                  {/* Using the locale of the user: */}
                  {new Date(tutor.last_finished_booking_timestamp).toLocaleDateString()}
                </strong>
              </p>}
              {tutor.attendance != null && <p className="text-center">
                Tasa de asistencia : <strong>
                  {(tutor.attendance * 100).toFixed(2) + "%"}
                </strong>
              </p>}

            </div>
          </div><br />
          {!isEmpty(tutor.education) || !isEmpty(tutor.work_experience) ?
            <>
              <div className="row p-4 rounded shadow-sm bg-light">
                <h5 className="mb-0">Información</h5><br /><br />
                <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                  {!isEmpty(tutor.education) ?
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="pills-education-tab" data-bs-toggle="pill" data-bs-target={"#" + "pills-" + tutorId + "-education"} type="button" role="tab" aria-controls="pills-education">Educación</button>
                    </li>
                    : " "}
                  {!isEmpty(tutor.work_experience) ?
                    <li className="nav-item" role="presentation">
                      <button className={"nav-link " + (isEmpty(tutor.education) ? "active" : "")} id="pills-experience-tab" data-bs-toggle="pill" data-bs-target={"#" + "pills-" + tutorId + "-experience"} type="button" role="tab" aria-controls="pills-experience">Experiencia Laboral</button>
                    </li>
                    : " "}
                </ul>
                <div className="tab-content d-flex justify-content-center m-3" id="pills-tabContent">
                  <div className={"tab-pane fade " + (!isEmpty(tutor.education) ? " show active" : "")}
                    id={"pills-" + tutorId + "-education"} role="tabpanel" aria-labelledby="pills-education-tab"
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                  >{tutor.education}</div>
                  <div className={"tab-pane fade " + (isEmpty(tutor.education) ? " show active" : "")}
                    id={"pills-" + tutorId + "-experience"} role="tabpanel" aria-labelledby="pills-experience-tab"
                    style={{
                      whiteSpace: 'pre-line',
                    }}
                  >{tutor.work_experience}</div>
                </div>
              </div>
              <br />
            </>
            : " "}

          <div className="row p-4 rounded shadow-sm bg-light">
            <div className="">
              <h5 className="mb-0">Horarios</h5><br />
            </div>
            <div className='p-0 p-md-4' style={{
              cursor: "pointer",
            }} onClick={(e) => {
              if (props.onChooseTutor != null) {
                e.preventDefault();
                props.onChooseTutor();
                return false;
              } else {
                trackClick("btn_eth_perfil_" + tutorId);
                history.push(reservarPathRoute + "/" + tutorId);
              }
            }}>
              <TutorScheduleSmall tutorId={tutor.id}></TutorScheduleSmall>
            </div>
          </div><br />

          <div className="row p-4 rounded shadow-sm bg-light">
            <h5 className="mb-0">Comentarios</h5>
            <div className="col-12 p-0">
              <TutorReviewsforShareProfile tutorId={tutorId} tutor={tutor}></TutorReviewsforShareProfile>
            </div>
          </div><br />


          <div className="row p-4 rounded shadow-sm bg-light">
            <h5 className="mb-0">Compartir</h5>
            <br></br>
            <div className="col-12">
              <span>
                <br></br>
                Recomienda a <a href={getPublicTutorProfileUrl(tutorId)} onClick={() => {
                  trackClick('btn_stpu');
                  // closing the tutor modal (used in the tutor modal, page 1 of booking)
                  const id = "exampleModal" + tutor.id;
                  try {
                    hideBootstrapModal(id);
                  } catch (e) {
                    // ignore for now
                  }
                }}>este profesor</a> con tus amig@s: <a href='.' onClick={async (e) => {
                  e.preventDefault();
                  try {
                    trackClick("btn_ctp");
                    await copyTextToClipboard(getPublicTutorProfileUrl(tutorId));
                    snackbar.showSuccess("Link copiado 😊");
                  } catch (e) {
                    snackbar.showError(e);
                  }

                  return false;
                }}>Copiar link</a>
                <br></br>
                Ganar S/20 💰 por cada amig@ que refieras: <Link to={compartirPathRoute} onClick={() => {
                  trackClick('btn_ptp_referral');
                  // closing the tutor modal (used in the tutor modal, page 1 of booking)
                  const id = "exampleModal" + tutor.id;
                  try {
                    hideBootstrapModal(id);
                  } catch (e) {
                    // ignore for now
                  }
                }}>Más información</Link>
              </span>
            </div>
          </div><br />
          {(props.butonAgendar === true) ?   
          <div className="row rounded mt-5">
            <div className="p-0 d-grid gap-2">
              <Link to={reservarPathRoute + "/" + tutorId} className="btn btn-primary btn-lg btn-flotante" tabIndex={1} role="button"
                onClick={(e) => {
                  if (props.onChooseTutor != null) {
                    e.preventDefault();
                    props.onChooseTutor();
                    return false;
                  } else {
                    trackClick("btn_et_perfil_" + tutorId);
                  }
                }}
              >Reservar clase</Link>
            </div>
          </div>
          : ""}
        </div>
      </div>
    </div>
  )
}

export default TutorInfoProfile
