import { LOGIN_ERROR_CONST } from "../api/EliteFetch";
import { BACKEND_BASE_URL, showAjaxRequestsResponses } from "../config/WpBackend";

export const fetchWithWordpressAjax = async (data: {}) => {
  const res = await fetch(
    BACKEND_BASE_URL + '/wp-admin/admin-ajax.php', {
    method: 'POST',
    body: new URLSearchParams(data),
  });
  const responseData = await res.json();
  if (res.status === 400) { // Bad request, usually because login was required but hasn't been done yet
    throw LOGIN_ERROR_CONST;
  }
  if (showAjaxRequestsResponses) {
    console.log(responseData);
  }
  return responseData;
};
