import { useState } from 'react'
import { trackClick } from '../util/AnalyticsUtil';
import { eliteRegistration, eliteUpdateUserInfo, ERROR_CONST } from '../api/EliteFetch';
import { EliteError } from '../model/EliteError';
import { FullUserDataResponse } from '../model/FullUserProfile';
import { RegistrationPayload } from '../model/RegistrationPayload';
import TextField from '@mui/material/TextField';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, DialogTitle, Button, Divider, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { themeOptions } from '../style/MuiThemes';
import AgostoAcceptConditions from './AgostoAcceptConditions';
import { useProfile } from '../data/ProfileProvider';
import { isEmpty } from '../util/StringUtil';

interface State {
  isLoading: boolean;
  registrationResponse?: EliteError | FullUserDataResponse | "ERROR";
  password: string;
  showPassword: boolean;
}

const AgostoSimpleRegistration = (props: {
  onSuccess: (arg0: FullUserDataResponse) => void,
  children?: JSX.Element,
}) => {

  const inputsClassname = "mb-2-5";
  const ph = useProfile();
  const profile = ph.value;

  const initialState: State = {
    showPassword: false,
    password: '',
    isLoading: false,
  }
  const [state, setState] = useState(initialState);

  async function tryToRegister(payload: RegistrationPayload) {
    setState(ps => ({
      ...ps,
      isLoading: true,
    }));
    const r = await eliteRegistration(payload);

    setState(ps => ({
      ...ps,
      isLoading: false,
      registrationResponse: r,
    }));

    if (r !== ERROR_CONST
      && ('data' in r)) { // success (the 'data' check is only for types not to complain)
      props.onSuccess(r);
    }
  }


  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setState(ps => ({
      ...ps,
      showPassword: !state.showPassword,
    }));
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const formContent = (
    <>
      <TextField
        fullWidth
        variant="outlined"
        className={inputsClassname}
        label='Correo electrónico'
        type='email'
        name="parentEmail"
        size="small"

        defaultValue={profile?.data?.user_email}
      />

      <FormControl
        fullWidth
        size="small"
        className={inputsClassname}
        variant="outlined"
      >
        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          name="password"
          required
          type={state.showPassword ? 'text' : 'password'}
          value={state.password}
          onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Contraseña"
        />
      </FormControl>
    </>
  );

  let registrationBottomPart = <></>;
  registrationBottomPart = (
    <>
      <div className="text-center mt-3 mb-3" style={{
        fontSize: 12,
      }}>
        <AgostoAcceptConditions analyticsName={"signup"}></AgostoAcceptConditions>
      </div>
    </>
  );
  const myForm = (
    <form onSubmit={(event) => {
      event.preventDefault();
      const m_password = (event.target as any).password?.value; // doesnt exist in case we are updating data
      // const rememberme = (event.target as any).rememberme.checked;
      const payload = {
        "pass_pa": m_password,
        // "remember": rememberme,
        "email_pa": (event.target as any).parentEmail.value,
      };
      // alert(JSON.stringify(payload));
      tryToRegister(payload);
    }}>

      {/* Form content: */}
      <ThemeProvider theme={createTheme({
        ...themeOptions,
        typography: {
          // In Chinese and Japanese the characters are usually larger,
          // so a smaller fontsize may be appropriate.
          fontSize: 11.5,
        },
      })}>
        {formContent}
      </ThemeProvider>


      {/* Submit button: */}
      {/* Al parecer btn-block sirve para que el botón tome todo el ancho disponible (ancho del padre) */}
      <Button
        fullWidth
        className="mb-3"
        type='submit'
        variant="contained"
        disabled={state.isLoading}>{state.isLoading ? "Preparándonos para iniciar... ✌️" :
          "Registrarse"}</Button>

      <br />

      <p className="text-center" style={{fontSize: 12}} >Al crear o usar el perfil de profesor estás aceptando los <a href="https://eliteclassroom.com/terminos-y-condiciones-del-profesor/">términos y condiciones del profesor.</a></p>

      {registrationBottomPart}

    </form>
  );
  return (
    <div>
      {
        state.registrationResponse != null
        && state.registrationResponse !== ERROR_CONST
        && ('error' in state.registrationResponse)
        && (
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <span>
              {(state.registrationResponse.error === "existing_user_email" ? "Este correo electrónico ya se usó" :
                state.registrationResponse.error)}
            </span>
            {/* Se removió porque generar luego de que el usuario lo cierra y una nueva renderización */}
            {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
          </div>)
      }
      <DialogTitle className="text-center mb-1 pt-0 fs-4">
        {"Inicia tu experiencia como profesor Elite"}
      </DialogTitle>
      <div className="row">
        {myForm}
      </div>
    </div>
  )
}

export default AgostoSimpleRegistration
