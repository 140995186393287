import { isLocalhost, isProductionWebsite } from "../util/DevEnvUtil";

export const PRODUCTION_BACKEND_BASE_URL = "https://eliteclassroom.com";
export const TESTING_BACKEND_BASE_URL = "http://34.94.50.254";

// export const BACKEND_BASE_URL = TESTING_BACKEND_BASE_URL;
export const BACKEND_BASE_URL = window.location.origin;

const useDummyDataConfig = false; // only used while in the testing server
export const useDummyData = ((useDummyDataConfig && !isProductionWebsite) || isLocalhost); // it means, do not use server/backend, in production never using dummy data
export const showAjaxRequestsResponses = ((true && !isProductionWebsite) || isLocalhost); 
export const showAjaxRequestsRequests = showAjaxRequestsResponses;