import React from 'react'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import PageShowTutorProfile from './PageShowTutorProfile';

const PageTutorRouteHandler = () => {
  let match = useRouteMatch();
  return (
    <div>
      {/* The PageTutorRouteHandler page has its own <Switch> with more routes
          that build on the /tutor URL path. You can think of the
          2nd <Route> here as an "index" page for all tutors, or
          the page that is shown when no tutorId is set in the URL */}
      <Switch>
        {/* Capturing the path part that follows to the /tutor part */}
        <Route path={`${match.path}/:tutorId`}>
          <PageShowTutorProfile></PageShowTutorProfile>
        </Route>

        {/* when no tutorId is set in the URL: */}
        <Route path={match.path}>
          <h6>Por favor escoge un tutor en:</h6>
          <Link to="/">Encuentra tu profesor ideal</Link>
        </Route>
      </Switch>
    </div>
  )
}

export default PageTutorRouteHandler
