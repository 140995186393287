import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ERROR_CONST, fetchTutorAvailability } from '../../api/EliteFetch'
import { TutorAvailability } from '../../model/TutorAvailability'
import { CONNECTION_ERROR_MESSAGE } from '../../util/Messages'
import { getFixedIntervals, SCHEDULE_INTERVAL } from '../../util/schedule/ScheduleUtil'
import AgostoLoader from '../AgostoLoader'

interface State {
  response: TutorAvailability | "ERROR" | null;
}

const TutorScheduleSmall = ({ tutorId }: {
  tutorId: number,
}) => {

  const initialState: State = {
    response: null,
  }
  const [state, setState] = useState(initialState)

  async function init() {
    const response = await fetchTutorAvailability(tutorId);
    setState(ps => ({ ...ps, response }))
  }

  useEffect(() => {
    init();
  }, []);


  if (state.response === null) {
    return <AgostoLoader></AgostoLoader>
  }

  if (state.response === ERROR_CONST) {
    return <div>{CONNECTION_ERROR_MESSAGE}</div>
  }

  // local short names of days of week:
  const daysOfWeek = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];

  // local short names of intervals:
  const intervalNames = ["Madrugada", "Mañana", "Tarde", "Noche"];
  const intervalHours = ["00:00 - 06:00", "06:00 - 12:00", "12:00 - 18:00", "18:00 - 24:00"];

  const intervalsData = getFixedIntervals(state.response.disponibilidad);

  return (

    <TableContainer component={Paper} variant='outlined' sx={{
      border: 0,
    }}>
      <Table sx={{
        minWidth: 300,
        border: 0,
        '& td, & th': {
          border: "1px solid #e0e0e0",
        }
      }} aria-label="Tutor Schedule">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {daysOfWeek.map(day => <TableCell align="center" key={day}>{day}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {intervalsData.map((row, idx) => {
            return <TableRow
              key={idx}
              sx={{
                // '&:last-child td, &:last-child th': { border: 0 }
              }}
            >
              <TableCell component="th" scope="row">
                <strong>{intervalNames[idx]}</strong>
                <br></br>
                {intervalHours[idx]}
              </TableCell>
              {row.map((cell, dayIdx) => {
                const dayIdxFromSunday = (dayIdx + 1) % 7;
                const actualCell = row[dayIdxFromSunday];

                return <TableCell key={dayIdx} align="center" sx={{
                  backgroundColor: `rgba(19,79,241, ${getOpacityForInterval(actualCell)})`,
                }}></TableCell>;
              })}
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TutorScheduleSmall

function getOpacityForInterval(actualCell: { day: number; interval: number; minutes: number }) {
  if (actualCell.minutes === 0) {
    return 0;
  }
  return Math.min((actualCell.minutes + 100) / SCHEDULE_INTERVAL, 1);
}

