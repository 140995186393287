import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { compartirPathRoute, homePathRoute, misClasesPathRoute, misDatosPathRoute, misEstudiantesPathRoute, misPagosPathRoute } from '../App';
import { useProfile } from '../data/ProfileProvider';
import { trackClick } from '../util/AnalyticsUtil';
import { isProductionWebsite } from '../util/DevEnvUtil';
import { getPerfilProfesorUrl, WORDPRESS_BASE_URL } from '../util/LinkConstants';

export const getPathnamesWithLoginAllowed = () => [
  homePathRoute,
  compartirPathRoute,
];

const MenuItems = (props: {
  id: string | undefined,
  className: string | undefined,
  first2Important: boolean,
  onLoginClick: (defaultToLogin : boolean) => void,
}) => {
  const ph = useProfile();
  const profile = ph.value;
  // const isTutor = (props.userProfile === ERROR_CONST ? false : (props.userProfile?.data?.tutorId != null));
  // const isLoggedIn = (props.userProfile === ERROR_CONST ? false : (props.userProfile?.data != null));
  const isTutor = profile.data?.tutorId != null;
  const isLoggedIn = profile.data != null;

  const location = useLocation();

  return (

    <ul id={props.id} className={props.className} >
      <li>
        <Link
          to={homePathRoute}
          className={(location.pathname === homePathRoute ? "disabled btn text-start" : "") + " " + (props.first2Important ? "btn text-start important" : "")}
        >👉 Encontrar un profesor</Link></li>
      {isLoggedIn && (<li>
        <Link
          to={misClasesPathRoute}
          className={(location.pathname === misClasesPathRoute ? "disabled btn text-start" : "") + " " + (props.first2Important ? "btn text-start important" : "")}
        >👩‍🎓 Mis próximas clases</Link>
      </li>)} {/* Mis proximas clases */}
      {/* TODO: descomentar lo sgte cuando se termine el backend de mis-pagos (monedero) */}
      {isLoggedIn && (<li><Link to={misPagosPathRoute} className={(location.pathname === misPagosPathRoute ? "btn disabled text-start" : "")}>💳 Mis pagos</Link></li>)}
      {isLoggedIn && (<li><a href={WORDPRESS_BASE_URL + "/mensajes"}>💬 Mis mensajes</a></li>)}
      {isLoggedIn &&
        (<li>
          <Link to={misDatosPathRoute} className={" " +
            (location.pathname === misDatosPathRoute ? "btn disabled text-start" : "")}
          >👥 Mis datos</Link>
        </li>)
      }
      {/* TODO: descomentar lo sgte cuando se termine el backend de mis-pagos (monedero) */}
      {/* <li><Link to="/monedero">Mi monedero</Link></li> */}
      {/* <li><a href="https://comunidad.eliteclassroom.com/">🌎 Comunidad</a></li> */}
      {isLoggedIn && (<li>
        <Link to={compartirPathRoute} className={" " + (location.pathname === compartirPathRoute ? "btn disabled text-start" : "")} >💰 Gana S/20</Link>
      </li>)}
      {(isTutor ? <li><Link to={misEstudiantesPathRoute + "#proximasclases"}>👨‍🏫 [Profesor] Mis clases</Link></li> : "")}
      {(isTutor ? <li><a href={getPerfilProfesorUrl()}>👩‍🏫 [Profesor] Mi perfil</a></li> : "")}
      {/* <li><a href="#">Cambiar de perfil</a></li> */}
      {(!isLoggedIn && getPathnamesWithLoginAllowed().includes(location.pathname)) && (<li><a href="." onClick={(e) => {
        e.preventDefault();
        props?.onLoginClick(true);
        return false;
      }}>👥 Iniciar sesión</a></li>)}
      {(!isLoggedIn && getPathnamesWithLoginAllowed().includes(location.pathname)) && (<li><a href="." onClick={(e) => {
        e.preventDefault();
        props?.onLoginClick(false);
        return false;
      }}>📝 Registrarse</a></li>)}
      {(!isProductionWebsite ? <li><Link to="/testing-home">Testing page</Link></li> : "")}
    </ul>
  );
};


const AgostoHeader = (props: {
  title: string,
  onLoginClick?: (defaultToLogin: boolean) => void,
}) => {

  const ph = useProfile();
  const profile = ph.value;
  const names = profile?.data?.names ?? "";
  const firstName = names.split(" ")[0];

  const _onLoginClick = props?.onLoginClick ?? ((defaultToLogin: boolean) => {});

  return (
    <div>
      {/*Responsive header options*/}
      <div className="header style2 navbar-area hide-desktop">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div className="nav-inner">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div>
                  <a className="navbar-brand col-lg-12 text-start" href="index.html">
                    <img src={process.env.PUBLIC_URL + "/tp_assets/icon/elite_large.webp"} alt="Logo" />
                  </a>
                </div>
                <a className="navbar-brand" href="index.html" />
                <button className="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="toggler-icon" />
                  <span className="toggler-icon" />
                  <span className="toggler-icon" />
                </button>
                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                  <MenuItems
                    first2Important={false}
                    id="nav"
                    className="navbar-nav ms-auto"
                    onLoginClick={_onLoginClick}
                  ></MenuItems>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/*End Responsive header options*/}

      {/* Start Breadcrumbs */}
      <div className="breadcrumbs overlay style-elite1" onClick={(e: React.MouseEvent) => {
        const eventTarget = e.target as Element;
        if (["A", "LI"].includes(eventTarget.tagName)) return; // to prevent reacting to children onclick (like the menu items)

        trackClick("btn_banner_to_home");
        window.open(window.location.origin, '_self')?.focus();
      }}>
        <div className="hide-responsive ">
          <div className="d-flex justify-content-start">
            <a className="navbar-brand" href="index.html">
              <img src={process.env.PUBLIC_URL + "/tp_assets/icon/elite_large.webp"} alt="Logo" style={{
                // backgroundColor: "white",
                paddingTop: 10,
                paddingBottom: 10,
              }} />
            </a>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-2 p-0 hide-responsive text-start">
              <nav id="menu">
                <ul>
                  <li><a href="#" className="important-pri">{"¡Hola" + (firstName.length === 0 ? "" : " " + firstName) + "!"}<br /> HAZ CLICK AQUÍ ⬇</a>
                    <MenuItems
                      id={undefined}
                      first2Important={true}
                      className=""
                      onLoginClick={_onLoginClick}
                    ></MenuItems>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-8 offset-lg-0 col-md-6 col-6 justify-content-start">
              <div className="breadcrumbs-content">
                <h1 className="page-title">{props.title}</h1>
              </div>
            </div>
          </div>
        </div><br />
        <div>
          <footer id="footer">
            <div className="container">
              <div className="row">
                <div className="col-md-4 miniphrase"><p>#ClasesIndividualesYGrupales</p></div>
                <div className="col-md-4 ms-auto miniphrase">Hacemos que aprender sea mas fácil.💜</div>
              </div>
            </div>
          </footer>
        </div>
      </div>
      {/* End Breadcrumbs */}
    </div>
  );
}

export default AgostoHeader
