import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import reportWebVitals from './reportWebVitals';
import { ProfileProvider } from './data/ProfileProvider';
import { createTheme, ThemeProvider } from '@mui/material';
import { themeOptions } from './style/MuiThemes';
import { MuiEasySnackbarProvider } from 'mui-easy-snackbar'


Sentry.init({
  dsn: "https://2d5eae65cae14c84bc6d47e1a3e8b768@o991465.ingest.sentry.io/5948448",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const theme = createTheme(themeOptions);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MuiEasySnackbarProvider>
        <ProfileProvider>
          <App />
        </ProfileProvider>
      </MuiEasySnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
