import { useEffect, useState } from 'react'
import { TutorReview } from "../../model/TutorReview";
import { Tutor } from '../../model/Tutor';
import { CONNECTION_ERROR_MESSAGE } from '../../util/Messages';
import AgostoLoader from '../AgostoLoader';
import AgostoReviewStars from '../AgostoReviewStars';
import { ERROR_CONST, fetchTutorReviews } from '../../api/EliteFetch';

// define response type:
type Response = TutorReview[];

interface InitialState {
  response: Response | null | "ERROR"; // null means loading, "ERROR" means network error
}

interface MyPropsType {
  tutorId: number,
  tutor: Tutor,
}


const TutorReviewsforShareProfile: React.FC<MyPropsType> = (props: MyPropsType) => {

  const { tutorId, tutor } = props;

  const initialState: InitialState = {
    response: null,
  }
  const [state, setState] = useState(initialState)

  // runs when this component loads:
  useEffect(() => {
    // initializations
    fetchTutorReviews(tutorId).then(r => {
      setState({
        response: r,
      });
    });
  }, []);

  if (state.response === null) {
    return <AgostoLoader></AgostoLoader>
  }

  if (state.response === ERROR_CONST) {
    return <div>{CONNECTION_ERROR_MESSAGE}</div>
  }

  return (
    <div className="row row-cols-1 row-cols-md-2 g-3 p-4">
      {state.response.length === 0 || state.response[0].not === 0 ?
        // if there are no reviews, show a message:
        "Aún no tiene comentarios"
        :
        state.response.map((review) => {
          return (
            <div key={review.booking_date} className="col">
              <div className="card">
                <div className="card-body">
                  <AgostoReviewStars stars={parseFloat(review.rating)}></AgostoReviewStars>
                  <span>{parseFloat(review.rating).toFixed(1)}</span>
                  <br />
                  <p className="card-text">{review.comment}</p>
                </div>
                <div className="card mb-1 border-light">
                  <div className="row g-0 m-2">
                    <div className="col-md-2 p-0 mb-1 mx-3">
                      <img src={review.avatar_url} style={{ borderRadius: '50%' }} className="img-fluid " alt="..." width={100} />
                    </div>
                    <div className="col-md-9 mt-2">
                      <div className="card-body">
                        <h6 className="card-title">{(review.student_name.length === 0 ? "Cliente sin nombre" : review.student_name)}</h6>
                        {/* <p className="card-text">6 clases</p> */}
                        <p className="card-text"><small className="text-muted">{review.booking_date}</small></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  )
}

export default TutorReviewsforShareProfile
