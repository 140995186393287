//arreglar esta linea 
//document.getElementById('btnCopiar').addEventListener('click', copiarAlPortapapeles);

export const whitespaceChar = String.fromCharCode(160);

export function copiarAlPortapapeles(id) {
    var codigoACopiar = document.getElementById(id);    //Elemento a copiar
    var seleccion = document.createRange(); //Creo una nueva selección vacía
    seleccion.selectNodeContents(codigoACopiar);    
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(seleccion);  //Y la añado a lo seleccionado actualmente
    try {
        var res = document.execCommand('copy'); //Intento el copiado
        if (res)
            exito();
        else
            fracaso();

        mostrarAlerta();
    }
    catch(ex) {
        excepcion();
    }
    window.getSelection().removeRange(seleccion);
}

function exito() {
    const divAlerta = document.getElementById('alerta');
    divAlerta.innerText = '¡¡Código copiado¡¡';
    divAlerta.classList.add('alert-success');
}

function fracaso() {
    const divAlerta = document.getElementById('alerta');
    divAlerta.innerText = '¡¡Ha fallado el copiado!!';
    divAlerta.classList.add('alert-warning');
}

function excepcion() {
    const divAlerta = document.getElementById('alerta');
    divAlerta.innerText = 'Se ha producido un error al copiar';
    divAlerta.classList.add('alert-danger');
}

function mostrarAlerta() {
    const divAlerta = document.getElementById('alerta');
    divAlerta.classList.remove('invisible');
    divAlerta.classList.add('visible');
    setTimeout(ocultarAlerta, 1500);
}

function ocultarAlerta() {
    const divAlerta = document.getElementById('alerta');
    divAlerta.innerText = whitespaceChar;
    divAlerta.classList.remove('alert-success', 'alert-warning', 'alert-danger', 'visible');
    divAlerta.classList.add('invisible');
}
