import React from 'react'
import { genRange } from '../util/TypescriptUtil';

interface MyPropsType {
  stars: number,
  maxStars?: number,
}


const AgostoReviewStars = (props : MyPropsType) => {
  if (isNaN(props.stars)) {
    return <div></div>
  }
  const intStars = Math.ceil(props.stars); // gotten from wordpress logic
  const maxStars = props.maxStars ?? 5;
  // console.log("maxStars", maxStars);
  return (
    <div>
      {
        genRange(maxStars).map((v) => {
          if (v < intStars) {
            return <span key={v} className="fa fa-star checked"></span>;
          }
          return <span key={v} className="fa fa-star"></span>
        })
      }
    </div>
  )
}

export default AgostoReviewStars
