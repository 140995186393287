import { VisibilityOff, Visibility } from '@mui/icons-material'
import { Box as div, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import React from 'react'
import ProvisionalNavigator from '../components/ProvisionalNavigator'

interface State {
  password: string;
  showPassword: boolean;
}

const PageTestingMui = () => {
  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div className="container">
      <ProvisionalNavigator></ProvisionalNavigator>
      <div className="col-md-4 offset-md-4">

        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          className="mb-3"
          label='Nombre y apellido del apoderado'
          type='text'
          name="parentNames"
          required
        />

        <TextField
          fullWidth
          variant="outlined"
          className="mb-3"
          label='Número de teléfono del apoderado'
          type='text'
          name="phoneNumber"
          required
        />

        <TextField
          fullWidth
          variant="outlined"
          className="mb-3"
          label='Correo electrónico del apoderado'
          type='email'
          name="parentEmail"
          required
        />

        <TextField
          fullWidth
          variant="outlined"
          className="mb-3" label='Nombre del alumno(a)' type='text'
          name="studentNames"
          required
        />
        <TextField
          className="mb-3"
          fullWidth
          label="Fecha de nacimiento del alumno(a)"
          type="date"
          // defaultValue="2017-05-24"
          // sx={{ width: 220 }}
          InputLabelProps={{
            shrink: true,
          }}
          name="studentBirthdate"
          required
        />


        <TextField
          fullWidth
          variant="outlined" className="mb-3" label='Correo electrónico del alumno(a)' type='email'
          name="studentEmail"
          // helperText="Opcional"
        />


        <FormControl
          fullWidth
          className="mb-3"
          variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            name="password"
            required
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Contraseña"
          />
        </FormControl>
      </div>
    </div>
  )
}

export default PageTestingMui
