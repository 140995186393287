import { Box, Popover, Slider } from '@mui/material';
import { useState } from 'react';
import { BookingProcessState } from '../pages/PageBooking'
import { trackClick } from '../util/AnalyticsUtil';
import { all_schedules, disponibilidades, getNameByDisponibilidad, getNameByMateriaId, getNameByNivelId, materias, niveles } from '../util/CoursesConstants';
import { CONNECTION_ERROR_MESSAGE } from '../util/Messages';
import { openWhatsapp } from '../util/OpenWhatsapp';
import { getRandomQuote } from '../util/PhrasesRepo';
import AgostoLoader from './AgostoLoader';
import AgostoReviewStars from './AgostoReviewStars';
import RowSpacer from './util/RowSpacer';
import '../style/SizeImagen.scss';
import { isEmpty } from '../util/StringUtil';
import { scrollToId } from '../util/ScrollUtil';

export const PRICE_RANGE_MAXIMUM_PEN = 80;

function getPriceRangeString(mini: number, maxi: number) {
  if (mini === 0 && maxi === PRICE_RANGE_MAXIMUM_PEN) {
    return "Cualquier precio";
  }
  return "S/" + mini.toFixed(2) + " - " + "S/" + maxi.toFixed(2);
}

interface State {
  priceRangeAnchorElement?: HTMLElement | null; // null when not opened
}

const AgostoFilterTutors = (props: {
  state: BookingProcessState,
  handleSelectChangeMateria: any,
  handleSelectChangeNivel: any,
  handleSelectChangeDisp: any,
  handleElegirTutor: any,
  handlePriceRangeChange: (newValue: number[]) => void,
  handlePriceRangeChangeCommitted: (newValue: number[]) => void,
  handleOpenTutorModal: (tutorId: number) => void,
}) => {
  const { state, handleSelectChangeMateria, handleSelectChangeDisp, handleSelectChangeNivel, handleElegirTutor } = props;
  const quote = getRandomQuote();


  const initialState: State = {
  }
  const [myState, setMyState] = useState(initialState);

  return (
    <div>
      <div className="row justify-content-md-center">
        {/*filtros*/}
        <div className="col-md-3">
          <select className="form-select form-select-sm" id="john_materia" aria-label="Materia"
            value={state.materiaValue}
            onChange={handleSelectChangeMateria}
          >
            {materias.map(v => (<option value={v.id}>{v.name}</option>))}
          </select>
          <br />
          <select className="form-select form-select-sm" id="john_nivel" aria-label="Nivel"
            value={state.nivelValue}
            onChange={handleSelectChangeNivel}
          >
            {niveles.map(v => (<option value={v.id}>{v.name}</option>))}
          </select>
          <br />
          <select className="form-select form-select-sm" id="disponibilidad" aria-label="Disponibilidad"
            value={state.disponibilidadValue}
            onChange={handleSelectChangeDisp}
          >
            {disponibilidades.map(v => (<option value={v.id}>{v.name}</option>))}
          </select>

          <br />

          <select className="form-select form-select-sm" aria-label="Rango de precios"
            onClick={(event: React.MouseEvent<HTMLSelectElement>) => {
              setMyState(ps => ({
                ...ps,
                priceRangeAnchorElement: event.currentTarget,
              }))
            }}
          >
            <option value="price" selected disabled>{getPriceRangeString(state.minPrice, state.maxPrice)}</option>
          </select>

          <Popover
            // id={id}
            open={myState.priceRangeAnchorElement != null}
            anchorEl={myState.priceRangeAnchorElement}
            onClose={() => {
              setMyState(ps => ({
                ...ps,
                priceRangeAnchorElement: null,
              }));
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          // sx={{
          //   width: 600
          // }}
          >
            <Slider

              sx={{
                paddingBottom: 3,
                paddingTop: 3,
                marginX: 3,
                width: 300,
              }}

              getAriaLabel={() => 'Price range'}
              value={[state.minPrice, state.maxPrice]}

              // when the mouse is up (left the slider):
              onChangeCommitted={(e, nv) => {
                const range = nv as number[];
                trackClick('slider_price_range', {
                  'minPrice': range[0],
                  'maxPrice': range[1],
                });
                props.handlePriceRangeChangeCommitted(range);  // we know it is an array because it is a range picker
              }}
              // while sliding:
              onChange={(e, nv) => props.handlePriceRangeChange(nv as number[])} // we know it is an array because it is a range picker

              // valueLabelDisplay="auto"
              getAriaValueText={(v: number) => "S/" + v}
              max={PRICE_RANGE_MAXIMUM_PEN}
              disableSwap
            />
          </Popover>


        </div>

        {/* tutors list */}
        <div className="col-md-9">
          <div className="row">
            <div className="mb-4 pt-4 pt-md-0 ">
              {
                (state.loadTutorsResponse == null) ?
                  (<div className="text-center justify-content-center">
                    <AgostoLoader></AgostoLoader>
                    <p className="fw-bold fst-italic">{quote.quote}</p>
                    <p>{quote.author}</p>
                  </div>) :
                  (state.loadTutorsResponse === "ERROR" ? CONNECTION_ERROR_MESSAGE :
                    (state.loadTutorsResponse.length === 0 ?
                      <div className="text-center">
                        No se encontró profesor, por favor intenta cambiando los filtros de búsqueda ⚙️
                        <br></br>
                        <a className='fw-bold' href='.' onClick={(e) => {
                          e.preventDefault();
                          trackClick('btn_tutors_not_found_ws');
                          const text = `Hola, vengo de la web pero estoy buscando un profesor 
                          de ${getNameByMateriaId(state.materiaValue)} para ${getNameByNivelId(state.nivelValue)}
                          ${state.disponibilidadValue !== all_schedules ?
                              `y en el horario ${getNameByDisponibilidad(state.disponibilidadValue)}` : ''}
                          ${state.minPrice === 0 && state.maxPrice === PRICE_RANGE_MAXIMUM_PEN ? '' :
                              ` con un precio entre ${getPriceRangeString(state.minPrice, state.maxPrice)}`}
                          `;
                          openWhatsapp(text);
                          return false;
                        }}>
                          O haz click aquí para ayudarte a encontrar el profesor que necesitas.
                        </a>
                      </div>
                      :
                      state.loadTutorsResponse.map((tutor) => {
                        const imgWidth = 180;
                        const imgHeight = imgWidth;
                        return (
                          <div key={tutor.id}>
                            <div className="col-lg-12 col-md-12 p-0">
                              <div className="card tutor-info mb-6 mt-0">
                                <div className="row card-body p-3 mt-2">
                                  <div className="col-sm-4 col-md-3 cent_eli ps-3">
                                    <a className="pb-3">
                                      {/* colocamos el width y height acá tmb para que haga espacio en caso la imagen no exista */}
                                      <div className="img-proov" style={{
                                        objectFit: 'cover',
                                        width: imgWidth,
                                        height: imgHeight,
                                      }}>
                                        <img src={tutor.picture} alt="tutor-avatar" className="rounded-circle img-proov" style={{
                                          objectFit: 'cover',
                                          width: imgWidth,
                                          height: imgHeight,
                                        }} />
                                      </div>
                                    </a>
                                    <div className="txt-center">
                                      <div className="rating">
                                        {
                                          (tutor.stars == null ? "" :
                                            <div>
                                              <AgostoReviewStars stars={tutor.stars}></AgostoReviewStars>
                                              <span>{tutor.stars?.toFixed(1)}</span>
                                            </div>
                                          )
                                        }
                                      </div>
                                    </div><br />
                                    <div className="mt-2 pt-0">{tutor.tutored_minutes > 0 ? 'Ha enseñado ' + (tutor.tutored_minutes / 45).toFixed(0) + ' ' + (Math.round(tutor.tutored_minutes / 45) === 1 ? 'clase' : 'clases') : 'Profesor Nuevo'}</div><br />
                                    {/* Button elegir tutor */}
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                      handleElegirTutor(tutor);
                                    }}>
                                      Elegir
                                    </button>
                                    <div className="pt-3">
                                      <p><span className="verfi-tutor">{tutor.verif === "on" ? '✅ Verificado' : 'Profesor no verificado '}</span></p>
                                    </div>
                                  </div>

                                  <div className="col-sm-8 col-md-9 ps-3 ps-md-4 pe-3 pe-md-4">
                                    <div className="d-flex flex-row-reverse bd-highlight px-2 justify-content-center justify-content-sm-start">
                                      <div className="p-2 bd-highlight text-uppercase">
                                        Precio por clase: S/{((tutor.price_per_lesson_hour_cents ?? 0) / 100).toFixed(2)}
                                        {tutor.pc_usada === false && tutor.pc_desc != 0 ?
                                        <p style={{
                                          color: '#DB212C',
                                          textAlign: 'right',
                                          textTransform: 'none',
                                        }}><strong>Prueba con desc. de {tutor.pc_desc}%</strong></p>
                                        : <></>}
                                      </div>
                                    </div>
                                    <h6 className="card-title"> {tutor.name} </h6>
                                    <p className="mb-2 tutor-brief-intro">
                                      <span className="intro elite-clamp-line-tutor-filters">{tutor.intro}</span></p>
                                    <hr />
                                    <h6 className="card-title"> Enseña </h6>
                                    <div className="d-flex flex-row flex-wrap">
                                      {
                                        tutor.subjects.map(subject => {
                                          const i = subject.name.search(" para");
                                          const i2 = (i === -1 ? subject.name.length : i);
                                          return subject.name.substr(0, i2).trim();
                                        })
                                          .filter((item, i, ar) => ar.indexOf(item) === i) // uniques
                                          .filter(v => v !== "").map((name) => {
                                            return (<div key={name} className="list-group-item list-group-item-elite-01">{
                                              name
                                            }</div>);
                                          })
                                      }
                                    </div>
                                    <div className="mt-2">
                                      <button type="button"
                                        className="btn btn-link p-0 sub" data-bs-toggle="modal"
                                        data-bs-target={"#exampleModal" + tutor.id}
                                        onClick={() => {
                                          trackClick("btn_ver_pc_" + tutor.id);
                                          props.handleOpenTutorModal(tutor.id);
                                          setTimeout(() => {
                                            if (isEmpty(tutor.URL_video)) { // only scrolling when there is no video
                                              scrollToId("info_" + tutor.id + "_comentarios");
                                            } else {
                                              scrollToId("info_" + tutor.id + "_presentacion");
                                            }
                                          }, 500);
                                        }}
                                      >
                                        Ver más
                                      </button>
                                      {/* The modal content was moved outside multisteps form because multisteps had configuration (css) that prevented showing the modals */}
                                    </div>
                                    <p />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                          </div>
                        )
                      }))
                  )
              }
            </div>
          </div>
        </div>
      </div>

      {/*end*/}
    </div>

  )
}

export default AgostoFilterTutors
