import {
  Dialog,
  DialogContent,
} from '@mui/material'
import AgostoLogin from '../components/AgostoLogin';
import { eliteModalTransition01 } from '../components/UiTransitions';
import { FullUserDataResponse } from '../model/FullUserProfile';

const ModalLogin = ({ isOpen, defaultToLogin, onClose, onSuccess }: {
  isOpen: boolean,
  defaultToLogin: boolean,
  onClose: () => void,
  onSuccess: (arg0: FullUserDataResponse) => void,
}) => {
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={isOpen}
        TransitionComponent={eliteModalTransition01}
        onClose={onClose}>
        <DialogContent>
          <div className="px-2 px-sm-4">
            <AgostoLogin onSuccess={onSuccess}
            defaultToLogin = {defaultToLogin}
            ></AgostoLogin>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ModalLogin