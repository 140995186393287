import { showAjaxRequestsRequests, showAjaxRequestsResponses, useDummyData } from "../config/WpBackend";
import { sleep } from "../utils";
import { ERROR_CONST } from "./EliteFetch";
import { SupplierViewsResponse, supplierViewsResponseExample } from "../model/App01/SupplierViewsResponse";
import { readProfile } from "../data/ProfileProvider";
import { EliteCommonResponse } from "../model/EliteError";
import { ClientInfo } from "../model/App01/ClientInfo";
import { ClientWithBookings } from "../model/App01/ClientWithBookings";

const APP01_BASE_URL = 'https://us-central1-elite-classroom-01.cloudfunctions.net/app_01';

export const fetchApp01Base = async (path: string, data: {}) => {
  const pr = readProfile();
  if (showAjaxRequestsRequests) {
    console.log(data);
  }
  const res = await fetch(
    APP01_BASE_URL + path, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'my-wp-cookie': pr.data?.logged_in_cookie ?? '', // sending the cookie to the backend
    },
  });
  const responseData = await res.json();
  if (showAjaxRequestsResponses) {
    console.log(responseData);
  }
  return responseData;
};

const viewsCache: { [key: number]: SupplierViewsResponse } = {};

export class SupplierServices {


  static async getViews(tutorId: number) {
    try {
      if (useDummyData) {
        await sleep(1500);
        return supplierViewsResponseExample;
      }
      if (viewsCache[tutorId] != null) {
        return viewsCache[tutorId];
      }
      viewsCache[tutorId] = await fetchApp01Base(
        "/supplierViews",
        {
          id: tutorId,
        }
      ) as SupplierViewsResponse;
      return viewsCache[tutorId];
    } catch (e) {
      console.error(e);
    }
    return ERROR_CONST;
  }
}

export class UserServices {
  static BASE_PATH = '/api/v1';

  static async saveClientProfile(clientInfo: ClientInfo) {
    try {
      if (useDummyData) {
        await sleep(500);
        return {
          status: 'OK',
        } as EliteCommonResponse;
      }
      return await fetchApp01Base(
        this.BASE_PATH + "/p/client_profile",
        clientInfo,
      ) as EliteCommonResponse;
    } catch (e) {
      console.error(e);
    }
    return ERROR_CONST;
  }

  static async createClientWithBookings(clientInfo: ClientWithBookings) {
    try {
      if (useDummyData) {
        await sleep(500);
        return {
          status: 'OK',
        } as EliteCommonResponse;
      }
      return await fetchApp01Base(
        this.BASE_PATH + "/p/create_client_with_bookings",
        clientInfo,
      ) as EliteCommonResponse;
    } catch (e) {
      console.error(e);
    }
    return ERROR_CONST;
  }
}