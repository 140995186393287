import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import AgostoLoader from '../components/AgostoLoader';
import { fetchTutorClasses, fetchTutorPayments } from '../api/EliteFetch';
import { homePathRoute } from '../App';
import AgostoHeader from '../components/AgostoHeader';
import Footer from '../components/Footer';
import TutorClasses from '../components/tutor/TutorClasses';
import TutorPayments from '../components/tutor/TutorPayments';
import { useProfile } from '../data/ProfileProvider';
import { ClassesResponseTutor } from '../model/TutorClassesResponse';
import { PaymentsResponseTutor } from '../model/TutorPaymentsResponse';

import { getPerfilProfesorUrl } from '../util/LinkConstants';

enum SupplierTab {
  proximasclases = "proximasclases",
  pagos = "pagos",
}

const PageMyStudents = () => {
  const [paymentsTutorRes, setPaymentsTutorRes] = useState(null as (null | "ERROR" | PaymentsResponseTutor));
  const [classesTutorRes, setClassesTutoRes] = useState(null as (null | "ERROR" | ClassesResponseTutor));

  const ph = useProfile();
  const profile = ph.value;

  const history = useHistory();
  const location = useLocation();

  const hash = location.hash.replace("#", "");
  let tab: SupplierTab = SupplierTab.proximasclases;
  if (!(hash in SupplierTab) || hash === "proximasclases") {
    tab = SupplierTab.proximasclases;
  } else {
    tab = SupplierTab.pagos;
  }


  useEffect(() => {
    if (profile.user_id === 0) {
      history.push(homePathRoute);
      return;
    }

    fetchTutorPayments().then(r => {
      setPaymentsTutorRes(r);
    });

    fetchTutorClasses().then(x => {
      setClassesTutoRes(x);
    });

  }, []);


  return (
    <div>
      <AgostoHeader title="Mis estudiantes"></AgostoHeader>
      <br />
      <div className="container" >
        <div className="row mx-4 my-3">
          <div className="col-12 px-3">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link"
                  id="perfil-tab"
                  data-bs-toggle="tab" data-bs-target="#perfil" type="button"
                  role="tab" aria-controls="perfil"
                  aria-selected="false"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = getPerfilProfesorUrl();
                    return false;
                  }}
                >Mi perfil</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={"nav-link " + (tab === SupplierTab.proximasclases ? "active" : "")} id="proximasclases-tab" data-bs-toggle="tab" data-bs-target="#proximasclases" type="button" role="tab" aria-controls="proximasclases"
                  aria-selected="true">Mis clases</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={"nav-link " + (tab === SupplierTab.pagos ? "active" : "")} id="payment-tab" data-bs-toggle="tab" data-bs-target={"#payment"} type="button" role="tab" aria-controls="payment"
                  aria-selected="false">Mis pagos</button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="mesage-tab"
                  data-bs-toggle="tab" data-bs-target="#mesage" type="button" role="tab" aria-controls="mesage"
                  aria-selected="false"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = window.location.origin + "/mensajes";
                    return false;
                  }}
                >Mis mensajes</button>
              </li>

            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade" id="perfil" role="tabpanel" aria-labelledby="perfil-tab">
                <AgostoLoader></AgostoLoader>
              </div>
              <div className={"tab-pane fade" + (tab === SupplierTab.proximasclases ? " show active" : "")} id="proximasclases" role="tabpanel" aria-labelledby="proximasclases-tab">
                <TutorClasses classesTutorResponse={classesTutorRes}></TutorClasses>
              </div>
              <div className={"tab-pane fade" + (tab === SupplierTab.pagos ? " show active" : "")} id="payment" role="tabpanel" aria-labelledby="payment-tab">
                <TutorPayments paymentsTutorResponse={paymentsTutorRes}></TutorPayments>
              </div>
              <div className="tab-pane fade" id="mesage" role="tabpanel" aria-labelledby="mesage-tab">
                <AgostoLoader></AgostoLoader>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}


export default PageMyStudents

