import { useDummyData } from "../config/WpBackend";
import { FullUserDataResponse, FullUserDataResponseOk } from "../model/FullUserProfile";
import { EliteCommonResponse, EliteError } from "../model/EliteError";
import { IntervalBooked } from "../model/IntervalBooked";
import { TutorAvailability } from "../model/TutorAvailability";
import { fetchWithWordpressAjax } from "../util/FetchUtil";
import { sleep } from "../utils";
import { PendingClassesResponse } from "../model/PendingClassesResponse";
import { LoginPayload } from "../model/LoginPayload";
import { RegistrationPayload } from "../model/RegistrationPayload";
import { writeProfile } from "../data/ProfileProvider";
import { onLogout, onUserIdJustReceived } from "../util/AnalyticsUtil";
import { loadReviewResponseExample, loadTutorResponseExample } from "../util/DummyData";
import { TutorPublicProfileResponse } from "./TutorPublicProfileResponse";
import { ReferralResponse } from "../model/Referral";
import { ReferralRewardsClaimResult } from "../model/ReferralRewardsClaimResult";
import { TutorReview } from "../model/TutorReview";
import { PaymentsResponse } from "../model/PaymentsResponse";
import { Reservations, ReservationsResponse } from "../model/Reservations";
import { CancelBookingResponse } from "../model/CancelResponse";
import { PaymentsResponseTutor, paymentsResponseTutorExample } from "../model/TutorPaymentsResponse";
import { ClassesResponseTutor, classesResponseTutorExample } from "../model/TutorClassesResponse";
import { SaveTutorProfilePayload, SaveTutorProfileResponse, saveTutorProfileResponseExample } from "../model/SaveTutorProfilePayload";
import { MyDataPersonalData, MyDataPersonalResponseExample } from "../model/MyDataPersonalResponse";

export const ERROR_CONST = "ERROR";
export const LOGIN_ERROR_CONST = "LOGIN_ERROR";
const dummyDataLoadTime = 500; // ms

// next variables are local not prevent accidentally importing them and using them in the components
const tutorIntervalsBookedResponseExample: IntervalBooked[] = [{ "start_time": "1095", "end_time": "1140", "pick_date": "2021-08-27", "weekday": "5" }, { "start_time": "1095", "end_time": "1140", "pick_date": "2021-08-25", "weekday": "3" }, { "start_time": "1035", "end_time": "1080", "pick_date": "2021-08-25", "weekday": "3" }, { "start_time": "1020", "end_time": "1065", "pick_date": "2021-08-18", "weekday": "3" }, { "start_time": "1080", "end_time": "1125", "pick_date": "2021-08-18", "weekday": "3" }, { "start_time": "1080", "end_time": "1125", "pick_date": "2021-08-16", "weekday": "1" }, { "start_time": "960", "end_time": "1005", "pick_date": "2021-08-13", "weekday": "5" }, { "start_time": "1080", "end_time": "1125", "pick_date": "2021-08-13", "weekday": "5" }, { "start_time": "1080", "end_time": "1125", "pick_date": "2021-08-11", "weekday": "3" }, { "start_time": "1080", "end_time": "1125", "pick_date": "2021-08-09", "weekday": "1" }, { "start_time": "1080", "end_time": "1125", "pick_date": "2021-08-06", "weekday": "5" }, { "start_time": "1140", "end_time": "1185", "pick_date": "2021-08-04", "weekday": "3" }, { "start_time": "1080", "end_time": "1125", "pick_date": "2021-08-02", "weekday": "1" }];
export const tutorAvailabilityResponseExample: TutorAvailability = {
  "disponibilidad": [
    { "start_time": "960", "end_time": "1200", "weekday": "5" },
    { "start_time": "960", "end_time": "1200", "weekday": "3" },
    { "start_time": "960", "end_time": "1200", "weekday": "1" }
  ], "fecha_caducidad": "2023-03-30", "horas_anticipacion": "10", "exception_dates": [
    "2022-01-05",
    "2022-01-03",
    "2022-01-10",
    "2022-02-01",
    "2022-02-05",
  ],
  "booking_min_duration" : 30,
};
const fullUserDataResponseExample_notSignedUp: FullUserDataResponseOk = { "status": "OK", "user_id": 0, "data": null };
const fullUserDataResponseExample_balanceBig_noCard: FullUserDataResponse = {
  "status": "OK", "user_id": 1, "data": {
    "cod_referido": "",
    "user_email": "jota@jota.jota",
    "child": {
      "count_items": "0", "status": "Active",
      "name_son": "champion",

      "date_birth": "2021-05-01",
      // "date_birth": "",
      "email_son": "letsgo@go.go", "user_id": "1", "ID": 10891, "__created_date": "2021-04-16 00:27:45",
    },
    "names": "John Li\u00f1an", "phone": "965041479", "user_login": "admin", "user_nicename": "admin", "display_name": "admin", "globalBalance": 56940, "centsBalance": 5000, "tutorId": "8889", "cardLast4Digits": null,
    "logged_in_cookie" : "aaaa",
  }
};
const fullUserDataResponseExample_balance0_withCard: FullUserDataResponse = {
  "status": "OK", "user_id": 65, "data": {
    "cod_referido": "",
    "user_email": "pelo@pelo.pelo",
    "child": { "count_items": "0", "status": "Active", "name_son": "binance", "date_birth": "2021-04-26", "email_son": "", "user_id": "65", "ID": 11070, "__created_date": "2021-05-23 02:49:17" }, "names": "ethereum", "phone": "32323232", "user_login": "JOSUEAJCETHEREUMC75", "user_nicename": "josueajcethereumc75", "display_name": "JOSUEAJCETHEREUMC75", "globalBalance": 0, "centsBalance": 0, "tutorId": null, "cardLast4Digits": "3222",
    "logged_in_cookie" : "aaaa",
  }
};
const fullUserDataResponseExample_balance0_noCard: FullUserDataResponse = {
  "status": "OK", "user_id": 65, "data": {
    "cod_referido": "POLO11",
    "user_email": "pato@pato.pato",
    "child": { "count_items": "0", "status": "Active", "name_son": "binance", "date_birth": "2021-04-26", "email_son": "", "user_id": "65", "ID": 11070, "__created_date": "2021-05-23 02:49:17" }, "names": "ethereum", "phone": "32323232", "user_login": "JOSUEAJCETHEREUMC75", "user_nicename": "josueajcethereumc75", "display_name": "JOSUEAJCETHEREUMC75", "globalBalance": 0, "centsBalance": 0, "tutorId": "323", "cardLast4Digits": null,
    "logged_in_cookie" : "aaaa",
  }
};

const cuponResponseExample: AplicarCouponResponse = { "status": "OK", "minutes": null, "money": "20.00" };
const cuponResponseExampleError: AplicarCouponResponse = { "status": "CUPON_NO_ENCONTRADO" };

const eliteCommonResponseOk: EliteCommonResponse = {
  "status": "OK",
}

const referralDataResponseExample: ReferralResponse = {
  "status": "OK", "data": {
    "refCode": "SDFSDF07G7387", "referred_users": [
      { "user_id": 31, "names": "sfsfvsdsf", "durations_sum": 360, "claim_time_goal_01": 0, "reached_referral_goal_01": true },
      { "user_id": 32, "names": "fsdfsf", "durations_sum": 120, "claim_time_goal_01": 0, "reached_referral_goal_01": true },
      { "user_id": 32, "names": "Uno Más", "durations_sum": 120, "claim_time_goal_01": 0, "reached_referral_goal_01": true },
      { "user_id": 34, "names": "whats done", "durations_sum": 420, "claim_time_goal_01": 1621127973, "reached_referral_goal_01": true },
      { "user_id": 123, "names": "Lolito Peluchero", "durations_sum": 0, "claim_time_goal_01": 0, "reached_referral_goal_01": false },
    ], "n_users": 4, "rewards_to_claim": 100 * 60
  }
};

const referralDataResponseExample_no_earnings: ReferralResponse = {
  "status": "OK", "data": {
    "refCode": "SDFSDF07G7387", "referred_users": [
      { "user_id": 123, "names": "Lolito Peluchero", "durations_sum": 0, "claim_time_goal_01": 0, "reached_referral_goal_01": false },
    ], "n_users": 1, "rewards_to_claim": 0
  }
};

const dataReservations: ReservationsResponse = {
  "status": "OK",
  //"user_id": 59,
  "bookings":
  {
    "2021-07": [
      {
        "date": "Sab 08/01/2022",
        "tutor_name": "Noah",
        "schedules": "10:45 AM - 11:30 AM",
        "horario": "45",
      }
    ],
    "2021-08": [
      {
        "date": "Dom 09/01/2022",
        "tutor_name": "Angel",
        "schedules": "10:45 AM - 11:30 AM",
        "horario": "90",
      }
    ],
  },
};



export interface PendingClass {
  horario: string;
  fecha: string;
  materia: string;
  nivel: string;
  link: string;
  mensaje: string;
  id: number;
  unique_id: string;
}
export interface PendingClassWithTutor {
  tutorId: string;
  special?: string | null;
  link_quelify: string;
  tutor_name: string;
  tutor_picture: string;
  classes: PendingClass[];

}
export const pendingClassesResponse: PendingClassesResponse = {
  "status": "OK",
  "tutores": [
    {
      "tutorId": "9213",
      "special": "NO",
      "link_quelify": "",
      "tutor_name": "Fiorella Algo",
      "tutor_picture": "https://storage.googleapis.com/elite-classroom/tutortest.jpg",
      "classes": [
        {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Química",
          "nivel": "Secundaria",
          "link": "",
          "mensaje": "",
          "id": 232423,
          "unique_id": "",
        }
      ],
    },
    {
      "tutorId": "9213",
      "special": "NO",
      "link_quelify": "",
      "tutor_name": "Fiorella Algo",
      "tutor_picture": "https://storage.googleapis.com/elite-classroom/tutortest.jpg",
      "classes": [
        {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Química",
          "nivel": "Secundaria",
          "link": "",
          "mensaje": "",
          "id": 232423,
          "unique_id": "",
        },
        {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Química",
          "nivel": "Secundaria",
          "link": "",
          "mensaje": "",
          "id": 232423,
          "unique_id": "",
        },
        {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Química",
          "nivel": "Secundaria",
          "link": "",
          "mensaje": "",
          "id": 232423,
          "unique_id": "",
        },
        {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Química",
          "nivel": "Secundaria",
          "link": "",
          "mensaje": "",
          "id": 232423,
          "unique_id": "",
        },
        {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Química",
          "nivel": "Secundaria",
          "link": "",
          "mensaje": "",
          "id": 232423,
          "unique_id": "",
        },
      ],
    },
    {
      "tutorId" : "3020",
      "special" : "YES",
      "link_quelify": "",
      "tutor_name": "Pedro Algo",
      "tutor_picture": "https://storage.googleapis.com/elite-classroom/tutortest.jpg",
      "classes": [
        {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Matemáticas",
          "nivel": "primary",
          "link": "",
          "mensaje": "",
          "id": 2324233,
          "unique_id": "",
        },
        {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Matemáticas",
          "nivel": "primary",
          "link": "",
          "mensaje": "",
          "id": 2324233,
          "unique_id": "",
        }, {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Matemáticas",
          "nivel": "primary",
          "link": "",
          "mensaje": "",
          "id": 2324233,
          "unique_id": "",
        }, {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Matemáticas",
          "nivel": "primary",
          "link": "",
          "mensaje": "",
          "id": 2324233,
          "unique_id": "",
        }, {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Matemáticas",
          "nivel": "primary",
          "link": "",
          "mensaje": "",
          "id": 2324233,
          "unique_id": "",
        }, {
          "horario": "12:00am a 15:00am",
          "fecha": "Mar 23/04/2021",
          "materia": "Matemáticas",
          "nivel": "primary",
          "link": "",
          "mensaje": "",
          "id": 2324233,
          "unique_id": "",
        },
      ]
    }
  ]
};

export async function fetchTutorIntervalsBooked(tutorId: number) {
  try {
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      return tutorIntervalsBookedResponseExample;
    }
    return await fetchWithWordpressAjax({
      action: "get_pick_available_day",
      tutor_id: tutorId,
    }) as IntervalBooked[];
  } catch (e) {
    return ERROR_CONST;
  }
}

export async function fetchTutorAvailability(tutorId: number) {
  try {
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      return tutorAvailabilityResponseExample;
    }
    return await fetchWithWordpressAjax({
      action: "get_available_day",
      tutor_id: tutorId,
    }) as TutorAvailability;
  } catch (e) {
    return ERROR_CONST;
  }
}

export async function fetchFullUserData() {
  try {
    let r = null;
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      // r = fullUserDataResponseExample_balance0_withCard;
      r = fullUserDataResponseExample_balance0_noCard;
      // r = fullUserDataResponseExample_balanceBig_noCard;
      // r = fullUserDataResponseExample_notSignedUp;
    } else {
      r = await fetchWithWordpressAjax({
        action: "elite_get_full_user_profile",
      }) as FullUserDataResponse;
    }

    if (r['status'] === "OK") {
      onUserIdJustReceived(r);
      // couldnt find a better way to make typescript understand that this is
      // an Ok response:
      writeProfile(r as FullUserDataResponseOk);
    }
    return r;
  } catch (e) {
    return ERROR_CONST;
  }
}

export interface AplicarCouponResponse {
  status: string;
  minutes?: string | null;
  money?: string | null; // in PEN
}

export async function fetchAplicarCupon(coupon_code: string, tutor_id: number, amount_to_charge_before_coupon: number) {
  try {
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      // return cuponResponseExample;
      return cuponResponseExampleError;
    }
    return await fetchWithWordpressAjax({
      action: "aplicar_cupon",
      coupon_code: coupon_code,
      tutor_id: tutor_id + "",
      amount_to_charge_before_coupon: amount_to_charge_before_coupon,
    }) as AplicarCouponResponse;
  } catch (e) {
    return ERROR_CONST;
  }
}

export interface SuccessfulSaveBookingResponse extends EliteCommonResponse {
  status: "OK";
  charge_later: string;
  booking_group_id: string;
  charge: string;
  payment_booking_id: unknown;
  charged_in_cents: number;
  payment_already_handled: string;
}

export interface FailedSaveBookingResponse extends EliteCommonResponse {
  status: "INTERSECTION" | "NO_HAY_SUFICIENTE_ANTICIPACION"
  | "NO_TIENES_SUFICIENTE_COBERTURA" | "CARD_ERROR" | "STRIPE_ERROR"
  | "COBERTURA_DISTINTA" | "ERROR_DE_CUPON"
  // there maybe more errors types, but we dont set them all here yet
  ,
  intersections?: any,
  card_error_message?: string,
  stripe_payment_error?: string,
  coupon_error?: string,
}
export type SaveBookingResponse = SuccessfulSaveBookingResponse | FailedSaveBookingResponse;

const saveBookingResponseExample: SuccessfulSaveBookingResponse = {
  "status": "OK",
  charge_later: "YES",
  booking_group_id: "232323_123456789",
  charge: "NO",
  charged_in_cents: 3000,
  payment_booking_id: null,
  payment_already_handled: "YES",
}

export async function fetchSaveBooking(bookings_payload: any) {
  try {
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      return saveBookingResponseExample;
    }
    return await fetchWithWordpressAjax({
      action: "save_booking",
      bookings_payload: JSON.stringify(bookings_payload),
    }) as SaveBookingResponse;
  } catch (e) {
    return ERROR_CONST;
  }
}


export async function fetchClasses() {
  try {
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      return pendingClassesResponse;
    }
    // TODO: connect with backend
    return await fetchWithWordpressAjax({
      action: "elite_get_classes",
    }) as PendingClassesResponse;
  } catch (e) {
    return ERROR_CONST;
  }
  return null;
}

export async function fetchMyData() {
  try {
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      return MyDataPersonalResponseExample;
    }
    // TODO: connect with backend
     return await fetchWithWordpressAjax({
      action: "elite_get_",
     }) as MyDataPersonalData;
  } catch (e) {
    return ERROR_CONST;
  }
  return null;
}


export async function eliteLogin(payload: LoginPayload):
  Promise<EliteError | FullUserDataResponse | "ERROR"> {
  try {
    let r;
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      r = fullUserDataResponseExample_balanceBig_noCard;
    } else {
      r = await fetchWithWordpressAjax({
        action: "elite_login",
        json_payload: JSON.stringify(payload),
      }) as (EliteError | FullUserDataResponse);
    }

    if (r['status'] === "OK") {
      // couldnt find a better way to make typescript understand that this is
      // an Ok response:
      const _r = r as FullUserDataResponseOk;
      onUserIdJustReceived(_r);
      writeProfile(_r);
    }
    return r;

  } catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}

export async function eliteLogout():
  Promise<EliteCommonResponse | "ERROR"> {
  try {
    let r;
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      r = eliteCommonResponseOk;
    } else {
      r = await fetchWithWordpressAjax({
        action: "elite_logout",
      }) as (EliteCommonResponse);
    }

    if (r['status'] === "OK") {
      onLogout();
      // onUserIdJustReceived(_r);
    }
    return r;
  } catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}

export async function eliteRegistration(payload: RegistrationPayload):
  Promise<EliteError | FullUserDataResponse | "ERROR"> {
  try {
    let r;
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      r = fullUserDataResponseExample_balance0_noCard;
    } else {
      r = await fetchWithWordpressAjax({
        action: "elite_signup",
        ...payload,
      }) as (EliteError | FullUserDataResponse);
    }

    if (r['status'] === "OK") {
      // couldnt find a better way to make typescript understand that this is
      // an Ok response:
      const _r = r as FullUserDataResponseOk;
      onUserIdJustReceived(_r, "signup");
      writeProfile(_r);
    }

    return r;
  } catch (e) {
    ;
  }
  return ERROR_CONST;
}

export async function eliteUpdateUserInfo(payload: RegistrationPayload):
  Promise<EliteError | FullUserDataResponse | "ERROR"> {
  try {
    let r;
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      r = fullUserDataResponseExample_balance0_noCard;
    } else {
      r = await fetchWithWordpressAjax({
        action: "elite_update_user_info",
        ...payload,
      }) as (EliteError | FullUserDataResponse);
    }

    if (r['status'] === "OK") {
      // couldnt find a better way to make typescript understand that this is
      // an Ok response:
      const _r = r as FullUserDataResponseOk;
      onUserIdJustReceived(_r);
      writeProfile(_r);
    }

    return r;
  } catch (e) {
    ;
  }
  return ERROR_CONST;
}

export async function fetchTutorPublicProfile(tutorId: number) {
  try {
    if (useDummyData) {
      await sleep(1500);
      return {
        status: "OK",
        data: loadTutorResponseExample[tutorId % loadTutorResponseExample.length],
      };;
    }
    return await fetchWithWordpressAjax({
      action: "gu_tutor_get_tutor_profile",
      tutor_id: tutorId,
    }) as TutorPublicProfileResponse;
  } catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}

export async function fetchReferralData() {
  try {
    if (useDummyData) {
      await sleep(1500);
      return referralDataResponseExample_no_earnings;
      // return referralDataResponseExample;
    }
    return await fetchWithWordpressAjax({
      action: "gu_tutor_get_referral_data",
    }) as ReferralResponse;
  } catch (e) {
    if (e === LOGIN_ERROR_CONST) {
      return LOGIN_ERROR_CONST;
    }
    console.error(e);
  }
  return ERROR_CONST;
}

const referralRewardsClaimResultExample: ReferralRewardsClaimResult = {
  "status": "OK",
  "reward_in_cents": 3 * 20 * 100, // 60 soles
}

export async function fetchClaimReferralRewards() {
  try {
    if (useDummyData) {
      await sleep(1500);
      return referralRewardsClaimResultExample;
    }
    return await fetchWithWordpressAjax({
      action: "claim_referral_rewards",
    }) as ReferralRewardsClaimResult;
  } catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}


const cache: { [key: number]: TutorReview[] } = {};

export const fetchTutorReviews = async (tutorId: number) => {
  if (cache[tutorId] == null) {
    try {
      if (useDummyData) {
        await sleep(1500);
        if (tutorId === 2290) {
          cache[tutorId] = [];
        } else {
          cache[tutorId] = loadReviewResponseExample;
        }
      } else {
        cache[tutorId] = (await fetchWithWordpressAjax({
          'action': 'load_review',
          'tutor': tutorId,
        })) as TutorReview[];
      }
    } catch (e) {
      console.error(e);
      return ERROR_CONST;
    }
  }
  return cache[tutorId];
};

const paymentsResponseExample: PaymentsResponse = { "status": "OK", "user_id": 1030, "payments": { "2021-12": [{ "change": 20667, "metadata": { "stripe_charge_id": "ch_3KB7IiKzw7apOPJc263ftIdl", "booking_group_id": "1030_1640565775", "tutor_id": "3020", "amount_paid_in_cents": 18600, "coupon": null }, "time": 1640565778, "user": 1030, "coin": "PEN", "booking_group_id": "1030_1640565775", "tutor_name": "Javier Gonzales", "tutor_id": "3020", "booking0_date": "2021-12-31 09:00" }, { "change": 33067, "metadata": { "stripe_charge_id": "ch_3K4FTdKzw7apOPJc0LwVjk20", "booking_group_id": "1030_1638928907", "tutor_id": "3020", "amount_paid_in_cents": 29760, "coupon": null }, "time": 1638928910, "user": 1030, "coin": "PEN", "booking_group_id": "1030_1638928907", "tutor_name": "Javier Gonzales", "tutor_id": "3020", "booking0_date": "2021-12-13 09:00" }], "2021-11": [{ "change": 12000, "metadata": { "stripe_charge_id": "ch_3JypASKzw7apOPJc2GClCXGa", "booking_group_id": "1030_1637636133", "tutor_id": "18584", "amount_paid_in_cents": 12000, "coupon": null }, "time": 1637636138, "user": 1030, "coin": "PEN", "booking_group_id": "1030_1637636133", "tutor_name": "Paul Rodriguez", "tutor_id": "18584", "booking0_date": "2021-11-24 18:30" }] } };

export async function fetchPayments() {
  try {
    if (useDummyData) {
      await sleep(1500);
      return paymentsResponseExample;
    }
    return await fetchWithWordpressAjax({
      'action': 'elite_get_payments',
    }) as PaymentsResponse;
  } catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}

export interface TutorExceptionDates {
  dates: string[]; // yyyy-mm-dd
}

export async function fetchSaveTutorExceptionDates(tutorExceptionDates: TutorExceptionDates) {
  try {
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      return tutorExceptionDates;
    }
    return await fetchWithWordpressAjax({
      action: "save_tutor_exception_dates",
      payload: JSON.stringify(tutorExceptionDates),
    }) as TutorExceptionDates;
  } catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}

export async function fetchSaveTutorProfile(payload: SaveTutorProfilePayload) {
  try {
    if (useDummyData) {
      console.log(payload);
      await sleep(dummyDataLoadTime);
      return saveTutorProfileResponseExample;
    }
    return await fetchWithWordpressAjax({
      action: "gu_tutor_save_tutor_profile",
      payload: JSON.stringify(payload),
    }) as SaveTutorProfileResponse;
  } catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}


export async function fetchUpdateBookings(
  booking_group_id: string,
  materiaId: string, nivelId: string) {
  try {
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      return eliteCommonResponseOk;
    }
    return await fetchWithWordpressAjax({
      action: "update_bookings",
      booking_group_id,
      materiaId,
      nivelId,
    }) as EliteCommonResponse;
  } catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}

export async function fetchReservations() {
  try {
    if (useDummyData) {
      await sleep(dummyDataLoadTime);
      return dataReservations;
    }
    return await fetchWithWordpressAjax({
      'action': 'elite_get_reservations',
    }) as ReservationsResponse;
  } catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}

const CancelResponse: CancelBookingResponse = {
  "result": "OK",
}

export async function fetchCancel(unique_id: string) {
  try {
    if (useDummyData) {
      return (CancelResponse);
    }
    return await fetchWithWordpressAjax({
      'action': 'cancel_booking',
      'unique_id': unique_id,
    }) as CancelBookingResponse;
  }
  catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}



export async function fetchTutorPayments() {
  try {
    if (useDummyData) {
      return paymentsResponseTutorExample;
    }
    return await fetchWithWordpressAjax({
      'action': 'elite_tutor_payments',
    }) as PaymentsResponseTutor;
  } catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}

export async function fetchTutorClasses() {
  try {
    if (useDummyData) {
      return classesResponseTutorExample;
    }
    return await fetchWithWordpressAjax({
      'action': 'elite_classes_tutor',
    }) as ClassesResponseTutor;
  } catch (e) {
    console.error(e);
  }
  return ERROR_CONST;
}
