import React, { useEffect, useState } from 'react'
import { trackClick } from '../util/AnalyticsUtil';
import { LoginPayload } from '../model/LoginPayload';
import { eliteLogin, ERROR_CONST } from '../api/EliteFetch';
import { EliteError } from '../model/EliteError';
import { FullUserDataResponse } from '../model/FullUserProfile';
import AgostoRegistrationOrInfoUpdate from './AgostoRegistrationOrInfoUpdate';
import { Button, Checkbox, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import AgostoAcceptConditions from './AgostoAcceptConditions';

interface State {
  emailOrUsername: string;
  password: string;
  isLoading: boolean;
  loginResponse?: EliteError | FullUserDataResponse | "ERROR";

  show_registration: boolean;
  showPassword: boolean;
}

const AgostoLogin = (props: {
  defaultToLogin: boolean,
  onSuccess: (arg0: FullUserDataResponse) => void,
}) => {

  const initialState: State = {
    emailOrUsername: "",
    password: "",
    showPassword: false,

    isLoading: false,
    show_registration: !props.defaultToLogin,
  }
  const [state, setState] = useState(initialState);

  useEffect(() => {
    // console.log("rerender");
  }, []);

  async function tryToLogin(payload: LoginPayload) {
    setState(ps => ({
      ...ps,
      isLoading: true,
    }));
    const r = await eliteLogin(payload);
    setState(ps => ({
      ...ps,
      isLoading: false,
      loginResponse: r,
    }));

    if (r !== ERROR_CONST
      && ('data' in r)) { // success
      props.onSuccess(r);
    }

  }

  // if (state.isLoading) {
  //   return (
  //     <AgostoLoader></AgostoLoader>
  //   )
  // }

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setState(ps => ({
      ...ps,
      showPassword: !state.showPassword,
    }));
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const myLoginForm = (
    <form onSubmit={(event) => {
      // (event as any).testException();
      event.preventDefault();
      const emailOrUsername = (event.target as any).emailOrUsername.value;
      const m_password = (event.target as any).m_password.value;
      const rememberme = (event.target as any).rememberme.checked;
      const payload = {
        "user_login": emailOrUsername,
        "user_password": m_password,
        "remember": rememberme,
      };
      // alert(JSON.stringify(payload));
      tryToLogin(payload);
    }}>

      {/* Email/username input */}
      <TextField
        // autoFocus
        fullWidth
        variant="outlined"
        className="mb-3"

        label='Nombre de usuario o correo electrónico'
        type='text'
        name="emailOrUsername"
        required
        size="small"
      />

      {/* Password input */}

      <FormControl
        fullWidth
        className="mb-2"
        variant="outlined"
        size="small"
      >
        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          name="m_password"
          required
          type={state.showPassword ? 'text' : 'password'}
          value={state.password}
          onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Contraseña"
        />
      </FormControl>


      {/* 2 column grid layout for inline styling */}
      <div className="row mb-2 d-flex justify-content-between">
        <div className="col">
          {/* Checkbox */}
          <FormControlLabel
            control={<Checkbox size="small" name='rememberme' value='on' color="primary" defaultChecked />}
            label={<span style={{ fontSize: 14 }}>Recuérdame</span>}
          />
        </div>
        <div className="col d-flex justify-content-end align-items-center">
          {/* Simple link */}
          <a href={window.location.origin + "/cuenta/?action=lostpassword"}>¿Olvidaste tu contraseña?</a>
        </div>
      </div>
      {/* Submit button */}
      {/* Al parecer btn-block sirve para que el botón tome todo el ancho disponible (ancho del padre). En MUI es fullWidth */}

      <Button
        fullWidth
        className="mb-3"
        type='submit'
        variant="contained"
        disabled={state.isLoading}>{state.isLoading ? "Iniciando... 😊" : "Iniciar sesión"}</Button>
      <br />
      <br />

      <Divider>Ó continuar con:</Divider>

      <div className="row text-center d-flex align-items-center justify-content-center">
        <div className="col">
          <br />
          <Button
            fullWidth
            variant="contained"
            disabled={state.isLoading}
            onClick={() => {
              trackClick('btn_login_fb');
              window.open(window.location.origin + "/wp-login.php?loginSocial=facebook&redirect=https://eliteclassroom.com/", '_blank')?.focus();
            }}
          >
            <i className="fab fa-facebook-f py-1" />
          </Button>
        </div>
      </div>

      {/* Register buttons */}
      <div className="text-center mt-3 mb-3">
        <p>¿Aún no tienes cuenta? <a href="." onClick={(e) => {
          e.preventDefault();
          setState(ps => ({
            ...ps,
            show_registration: true,
          }))
          return false;
        }}>Registrarse</a></p>
      </div>

      <div className="text-center mt-3 mb-3" style={{
        fontSize: 12,
      }}>
        <AgostoAcceptConditions analyticsName={"signup"}></AgostoAcceptConditions>
      </div>

    </form>
  );

  if (!state.show_registration) { // show login
    return (
      <div>
        {
          state.loginResponse != null
          && state.loginResponse !== ERROR_CONST
          && ('error' in state.loginResponse)
          && (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
              <span dangerouslySetInnerHTML={{ __html: state.loginResponse.error }}></span>
              {/* Se removió porque generar luego de que el usuario lo cierra y una nueva renderización */}
              {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
            </div>)
        }
        <DialogTitle className="text-center mb-1 pt-0 fs-4">
          Inicia sesión
        </DialogTitle>
        <div className="row">
          {myLoginForm}
        </div>
      </div>
    )
  }

  return (
    <AgostoRegistrationOrInfoUpdate
      onSuccess={(arg0) => {
        props.onSuccess(arg0);
      }}
      onLoginClick={() => {
        setState(ps => ({
          ...ps,
          show_registration: false,
        }))
      }}
    ></AgostoRegistrationOrInfoUpdate>
  )
}

export default AgostoLogin
