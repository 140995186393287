import { loadTutorResponseExample } from "../util/DummyData";
import { EliteCommonResponse } from "./EliteError";
import { Tutor, TutorProduct } from "./Tutor";

export interface SaveTutorProfilePayload {
  products: TutorProduct[];
  booking_min_duration: string; // in minutes (backend receives string)
}

export interface SaveTutorProfileResponse extends EliteCommonResponse {
  public_profile?: Tutor | null,
};

export const saveTutorProfileResponseExample: SaveTutorProfileResponse = {
  status: "OK",
  public_profile: loadTutorResponseExample[0],
}
