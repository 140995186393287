import { TutorReview } from "../model/TutorReview";
import { Tutor } from "../model/Tutor";

export const loadReviewResponseExample: TutorReview[] = [{ "rating": "5", "comment": "Estudiante : Gabriel cisneros es muy buena miss con sus ejercisios grasias espero que le balla bien", "tutor_name": "Maritere Rojas", "tutor_intro": "\u00a1Hola! Soy Bachiller en Educaci\u00f3n en la Universidad Nacional Mayor de San Marcos, como educadora profesional reconozco que ense\u00f1ar de manera divertida y a trav\u00e9s del juego es lo mejor. Este m\u00e9todo lo apliqu\u00e9 desde hace cinco a\u00f1os, ahora lo realizo de manera virtual y qu\u00e9 crees; funcion\u00f3. Recuerda que el profesor solo es el gu\u00eda para que el estudiante logre el aprendizaje, s\u00e9 que t\u00fa lo podr\u00e1s lograr. \u00a1Te animo a llevar clases conmigo y empezar esta aventura juntos por el camino de las letras, nos vemos pronto!", "course_name": "", "student_name": "Kathia Fuertes", "booking_date": "18-08-2021 17:50:08", "not": 1 , "avatar_url": "http://1.gravatar.com/avatar/ae68eafe4a83ca663f62c80702877edb?s=96&d=mm&r=g"}];

export const loadTutorResponseExample: Tutor[] = [
  {"id":9213,
  "user_id": 8,
  "intro":"Soy de la Universidad Nacional de Ingenier\u00eda y me encantan las matem\u00e1ticas y las ciencias. Adem\u00e1s; me gusta mucho ense\u00f1ar y con ello sentir la satisfacci\u00f3n de ver a mi alumn@ aprender y responder. Pienso que con mucha paciencia, una actitud asertiva, y mucha motivaci\u00f3n podemos lograr tu objetivo, el cual se convertir\u00e1 tambi\u00e9n en el m\u00edo cuando le des reservar.","name":"Fiorela Jimenez","picture":"https:\/\/eliteclassroom.com\/wp-content\/uploads\/2020\/11\/WhatsApp-Image-2020-11-25-at-4.08.48-PM.jpeg","status":"Active","html":null,"skype_id":"","stars":5,"subjects":[{"name":"Redacci\u00f3n y ortograf\u00eda para primaria"},{"name":"Qu\u00edmica para secundaria"},{"name":"F\u00edsica para secundaria"},{"name":"Matem\u00e1ticas para inicial"},{"name":"Matem\u00e1ticas para primaria"},{"name":"Matem\u00e1ticas para secundaria"}],"tutored_minutes":21465,"verif":"on","students":4 ,
    "price_per_lesson_hour_cents":2500,
    "attendance" : 0.97,
    "education":"2007 — 2009 George Brown College \n" + " 2007 — 2009 Diploma Program",
    "work_experience": "c ",
    "URL_video": "iUBE7bRIaL8",
    "last_finished_booking_timestamp": Date.now(),
    "products" : [{
      "name": "3 Rectángulos ensimismados",
      "price_in_cents" : 100 * 40,
    }],
    "pc_desc": 25,
    "pc_usada": false,
    "next_lesson_price": 100,
    booking_min_duration: 30,
  },
  {"id":3020,
  "user_id": 9,
  "intro":"Estudio en la Universidad Nacional de Ingenier\u00eda (UNI), curso el 8vo ciclo, soy una persona alegre, positiva, de buen car\u00e1cter y dedicado a mi trabajo, llevo 3 a\u00f1os en el mundo de la docencia con excelentes resultados, me gusta capacitarme constantemente en cuanto a m\u00e9todos innovadores de ense\u00f1anza y tecnolog\u00eda. Para mi la docencia es una vocaci\u00f3n por lo cual realizo mi trabajo con mucha pasi\u00f3n y dedicaci\u00f3n. En mis tiempos libres me gustar jugar f\u00fatbol, practicar ajedrez y escuchar rock argentino.",
  "name":"Javier Gonzales",
  "picture":"https:\/\/eliteclassroom.com\/wp-content\/uploads\/2021\/06\/DDDDD.jpg",
  "status":"Active",
  "html":null,
  "skype_id":"",
  "stars":5,
  "subjects":[{"name":"Matem\u00e1ticas para secundaria"},{"name":"Matem\u00e1ticas para primaria"},{"name":"Matem\u00e1ticas para inicial"},{"name":"F\u00edsica para secundaria"},{"name":"Qu\u00edmica para secundaria"},{"name":""}],
  "tutored_minutes":14025,
  "verif":"on",
  "students": 1,
  "price_per_lesson_hour_cents":2500,
  "education":"2007 — 2009 George Brown College \n" + "2007 — 2009 Diploma Program",
  "work_experience": "Colaboración en el proyecto del instituto virtualización de la enseñanza para impartir enseñanza online"+ "\n" +" Creación de material pedagógico online",
  "URL_video": "iUBE7bRIaL8",
  "products": [],
  "pc_desc": 30,
    "pc_usada": false,
    "next_lesson_price": 100,
    booking_min_duration: 45,
  },
  {"id":2290,
  "user_id": 11,
  "intro":"Hola, el mundo de la ense\u00f1anza ha cambiado mi vida, conmigo encontr\u00e1s un profesor f\u00e1cil de entender, ordenado y con la capacidad de resolver las preguntas m\u00e1s dificiles. Soy de la Universidad Nacional de Ingenier\u00eda, me encanta el f\u00fatbol y danzar. Dale reservar y compru\u00e9balo tu mism@.","name":"Gianmarco Barturen","picture":"https:\/\/eliteclassroom.com\/wp-content\/uploads\/2021\/06\/OPCION-1.jpg","status":"Active","html":null,"skype_id":"","stars":5,"subjects":[{"name":"F\u00edsica para secundaria"},{"name":"Qu\u00edmica para secundaria"},{"name":"Matem\u00e1ticas para primaria"},{"name":"Matem\u00e1ticas para secundaria"},{"name":"Ingl\u00e9s para primaria"},{"name":""}],"tutored_minutes":13320,"verif":"on","students": 1,
    "price_per_lesson_hour_cents":2500,
    "education":"2007 — 2009 George Brown College \n" + " 2007 — 2009 Diploma Program",
    "work_experience": "Colaboración en el proyecto del instituto virtualización de la enseñanza para impartir enseñanza online "+ "\n" +"Creación de material pedagógico online",
    "URL_video": "",    
    "products": [],
    "pc_desc": 10,
    "pc_usada": true,
    "next_lesson_price": 100,
    booking_min_duration: 15,
  },
  {"id":2291,
  "user_id": 12,
  "intro":"Hola, el mundo de la ense\u00f1anza ha cambiado mi vida, conmigo encontr\u00e1s un profesor f\u00e1cil de entender, ordenado y con la capacidad de resolver las preguntas m\u00e1s dificiles. Soy de la Universidad Nacional de Ingenier\u00eda, me encanta el f\u00fatbol y danzar. Dale reservar y compru\u00e9balo tu mism@.","name":"Gianmarco Barturen","picture":"https:\/\/eliteclassroom.com\/wp-content\/uploads\/2021\/06\/OPCION-1.jpg","status":"Active","html":null,"skype_id":"","stars":5,"subjects":[{"name":"F\u00edsica para secundaria"},{"name":"Qu\u00edmica para secundaria"},{"name":"Matem\u00e1ticas para primaria"},{"name":"Matem\u00e1ticas para secundaria"},{"name":"Ingl\u00e9s para primaria"},{"name":""}],"tutored_minutes":13320,"verif":"on","students": 5,
    "price_per_lesson_hour_cents":2500,
    "education" : null,
    "work_experience": "Colaboración en el proyecto del instituto virtualización de la enseñanza para impartir enseñanza online "+ "\n" +"Creación de material pedagógico online",
    "URL_video": null,     
    "products": [],
    "pc_desc": 0,
    "pc_usada": false,
    "next_lesson_price": 100,
    booking_min_duration: 60,
  },
  {"id":2292,
  "user_id": 13,
  "intro":"Hola, el mundo de la ense\u00f1anza ha cambiado mi vida, conmigo encontr\u00e1s un profesor f\u00e1cil de entender, ordenado y con la capacidad de resolver las preguntas m\u00e1s dificiles. Soy de la Universidad Nacional de Ingenier\u00eda, me encanta el f\u00fatbol y danzar. Dale reservar y compru\u00e9balo tu mism@.","name":"Gianmarco Barturen","picture":"https:\/\/eliteclassroom.com\/wp-content\/uploads\/2021\/06\/OPCION-1.jpg","status":"Active","html":null,"skype_id":"","stars":5,"subjects":[{"name":"F\u00edsica para secundaria"},{"name":"Qu\u00edmica para secundaria"},{"name":"Matem\u00e1ticas para primaria"},{"name":"Matem\u00e1ticas para secundaria"},{"name":"Ingl\u00e9s para primaria"},{"name":""}],"tutored_minutes":13320,"verif":"on","students": 6,
    "price_per_lesson_hour_cents":2500,
    "education":"2007 — 2009 George Brown College \n" + " 2007 — 2009 Diploma Program",
    "work_experience": null,     
    "URL_video": null,
    "products": [],
    "pc_desc": 10,
    "pc_usada": true,
    "next_lesson_price": 100,
    booking_min_duration: 75,
  },
  {"id":2293,
  "user_id": 14,
  "intro":"Hola, el mundo de la ense\u00f1anza ha cambiado mi vida, conmigo encontr\u00e1s un profesor f\u00e1cil de entender, ordenado y con la capacidad de resolver las preguntas m\u00e1s dificiles. Soy de la Universidad Nacional de Ingenier\u00eda, me encanta el f\u00fatbol y danzar. Dale reservar y compru\u00e9balo tu mism@.","name":"Gianmarco Barturen","picture":"https:\/\/eliteclassroom.com\/wp-content\/uploads\/2021\/06\/OPCION-1.jpg","status":"Active","html":null,"skype_id":"","stars":5,"subjects":[{"name":"F\u00edsica para secundaria"},{"name":"Qu\u00edmica para secundaria"},{"name":"Matem\u00e1ticas para primaria"},{"name":"Matem\u00e1ticas para secundaria"},{"name":"Ingl\u00e9s para primaria"},{"name":""}],"tutored_minutes":13320,"verif":"on","students": 3,
    "price_per_lesson_hour_cents":2500,
    "education":null,
    "work_experience": null,     
    "URL_video": null,
    "products": [],
    "pc_desc": 12,
    "pc_usada": true,
    "next_lesson_price": 100,
    booking_min_duration: 30,
  },
];

export const loadTutorResponseExample_empty: Tutor[] = [];
