import {
  Dialog,
  DialogContent,
  Slide
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import AgostoLogin from '../components/AgostoLogin';
import AgostoRegistrationOrInfoUpdate from '../components/AgostoRegistrationOrInfoUpdate';
import { FullUserDataResponse } from '../model/FullUserProfile';

// for the transition slide effect
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide 
    timeout={1} 
    direction="down" 
    ref={ref} 
    {...props} 
  />;
});

const ModalUpdateUserInfo = ({ isOpen, onClose, onSuccess }: {
  isOpen: boolean,
  onClose: () => void,
  onSuccess: (arg0: FullUserDataResponse) => void,
}) => {
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={isOpen}
        TransitionComponent={Transition}
        onClose={onClose}>
        <DialogContent>
          <div className="px-2 px-sm-4">
            <AgostoRegistrationOrInfoUpdate
              onSuccess={onSuccess}
              onLoginClick={null} // null representa que queremos solo actualizar data
            ></AgostoRegistrationOrInfoUpdate>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ModalUpdateUserInfo