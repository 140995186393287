import { readProfile, writeProfile } from "../data/ProfileProvider";
import { fa } from "./FirebaseInit";
import smartlookClient from 'smartlook-client'
import { FullUserDataResponse, FullUserDataResponseOk } from "../model/FullUserProfile";

export type AnalyticsParamsType = { [key: string]: string | number | null | undefined };

export function trackClick(btnName: string, otherParams?: AnalyticsParamsType) {
  const stuff: AnalyticsParamsType = {
    item_name: btnName,
    ...(otherParams ?? {})
  };
  fa.logEvent('click', stuff);
}

function tUserId(_user_id: number) {
  return (_user_id === 0) ? null : _user_id + ""; // 0 means not logged in
}

export function onLogout() {
  // TODO: maybe also clear the user_id set in analytics
  const fullUserDataResponseExample_notSignedUp: FullUserDataResponseOk = { "status": "OK", "user_id": 0, "data": null };
  // writing to local storage to propagate the changes
  writeProfile(fullUserDataResponseExample_notSignedUp);
}

export function onUserIdJustReceived(r : FullUserDataResponse, eventType : "signup" | "login" = "login") {
  
  const user_id = tUserId(r.user_id);

  if (user_id != null) {
    
    // firebase
    fa.setUserId(user_id); // doesnt accept null

    // smartlook
    smartlookClient.identify(user_id, {
      "name" : r.data?.names ?? "", 
    });
    smartlookClient.properties({
      'user_id' : user_id,
    });

    const pr = readProfile();
  
    if (tUserId(pr.user_id) !== user_id) {
      if (eventType === "login") {
        fa.logEvent('login', {
          method: 'email_flujo_react',
        });
        smartlookClient.track('login', {
          method: 'email_flujo_react',
          'user_id' : user_id,
        })
      } else {
        fa.logEvent('sign_up', {
          method: 'email_flujo_react',
        });
        smartlookClient.track('sign_up', {
          method: 'email_flujo_react',
          'user_id' : user_id,
        })
      }
    }    
  }
}