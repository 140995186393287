export interface PaymentsResponseTutor {
  status: string;
  tutor_id: number;
  paymentsAll: { [key: string]: comDataPay; };
}

export interface comDataPay {
  total: number;
  totalStr: string;
  dataPayment: PaymentsTutorsResp[];
}

export interface PaymentsTutorsResp {
  fecha: string;
  horario: string;
  name_son: string;
  name_parent: string;
  mensaje: string;
  duration: string;
  pago: string;
}

export const paymentsResponseTutorExample: PaymentsResponseTutor = {
  "status": "OK",
  "tutor_id": 11458,
  "paymentsAll": {
    "2021-10": {
      "total": 9750,
      "totalStr": "97.50",
      "dataPayment": [
        {
          "fecha": "Vie 22\/10\/2021",
          "horario": "03:00 AM - 03:45 AM",
          "name_son": "LLLLL",
          "name_parent": "CORREO",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=77",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Vie 22\/10\/2021",
          "horario": "05:00 AM - 05:45 AM",
          "name_son": "LLLLL",
          "name_parent": "CORREO",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=77",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Vie 22\/10\/2021",
          "horario": "06:30 AM - 07:15 AM",
          "name_son": "LLLLL",
          "name_parent": "CORREO",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=77",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Vie 22\/10\/2021",
          "horario": "08:00 AM - 08:45 AM",
          "name_son": "LLLLL",
          "name_parent": "CORREO",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=77",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Vie 22\/10\/2021",
          "horario": "06:30 PM - 07:15 PM",
          "name_son": "LLLLL",
          "name_parent": "CORREO",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=77",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Sab 23\/10\/2021",
          "horario": "00:00 AM - 00:45 AM",
          "name_son": "LLLLL",
          "name_parent": "CORREO",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=77",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Sab 23\/10\/2021",
          "horario": "05:30 AM - 06:45 AM",
          "name_son": "LLLLL",
          "name_parent": "CORREO",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=77",
          "duration": "75",
          "pago": "18.75"
        },
        {
          "fecha": "Sab 23\/10\/2021",
          "horario": "07:45 AM - 08:30 AM",
          "name_son": "LLLLL",
          "name_parent": "CORREO",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=77",
          "duration": "45",
          "pago": "11.25"
        }
      ]
    },
    "2021-11": {
      "total": 6750,
      "totalStr": "67.50",
      "dataPayment": [
        {
          "fecha": "Vie 05\/11\/2021",
          "horario": "08:00 PM - 08:45 PM",
          "name_son": "lucas",
          "name_parent": "Mishell",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=12",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Sab 06\/11\/2021",
          "horario": "09:15 PM - 10:00 PM",
          "name_son": "lucas",
          "name_parent": "Mishell",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=12",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Dom 07\/11\/2021",
          "horario": "04:00 AM - 04:45 AM",
          "name_son": "lucas",
          "name_parent": "Mishell",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=12",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Vie 19\/11\/2021",
          "horario": "03:00 AM - 03:45 AM",
          "name_son": "lucas",
          "name_parent": "Mishell",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=12",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Sab 20\/11\/2021",
          "horario": "09:00 PM - 09:45 PM",
          "name_son": "lucas",
          "name_parent": "Mishell",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=12",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Lun 22\/11\/2021",
          "horario": "05:00 AM - 05:45 AM",
          "name_son": "lucas",
          "name_parent": "Mishell",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=12",
          "duration": "45",
          "pago": "11.25"
        }
      ]
    },
    "2021-12": {
      "total": 2250,
      "totalStr": "22.50",
      "dataPayment": [
        {
          "fecha": "Jue 09\/12\/2021",
          "horario": "09:15 PM - 10:00 PM",
          "name_son": "lucas",
          "name_parent": "Mishell",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=12",
          "duration": "45",
          "pago": "11.25"
        },
        {
          "fecha": "Vie 10\/12\/2021",
          "horario": "02:15 AM - 03:00 AM",
          "name_son": "lucas",
          "name_parent": "Mishell",
          "mensaje": "http:\/\/34.94.50.254\/mensajes?fepaction=newmessage&fep_to=12",
          "duration": "45",
          "pago": "11.25"
        }
      ]
    }
  }
}
